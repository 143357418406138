import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import moment from "moment";
import Select from "react-select";

class updateProfile extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      cancelledChequeFile: null,
      cancelledChequePreviewURL: null,
      aadharPhotoFiles: [],
      aadharPhotoPreviewURLs: [],
      aadharPhotoNames: [],
      srviceForMenWomen: "",
      aboutMe: "",
      experience: "",
      course: "",
      course_name: "",
      facebook_url: "",
      instagram_url: "",
      fullName: "",
      email: "",
      homeVisit: "",
      gstNumber: "",
      mobileNumber: "",
      bankAccountNumber: "",
      cancelledCheque: "",
      aadharNumber: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.fetchUserData(userid);
  }

  fetchUserData = async (userid) => {
    this.setState({ loading: true });
    const { token } = this.state;

    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, userid: userid };
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, user } = response.data;

      if (status === 200 && user) {
        this.setState({
          loading: false,
          _id: userid,
          srviceForMenWomen: user.srviceForMenWomen || "",
          aboutMe: user.user_information?.[0]?.aboutMe || "",
          experience: user.user_information?.[0]?.experience || "",
          course: user.user_information?.[0]?.course || "",
          course_name: user.user_information?.[0]?.course_name || "",
          facebook_url: user.user_information?.[0]?.facebook_url || "",
          instagram_url: user.user_information?.[0]?.instagram_url || "",
          gstNumber: user.gstNumber || "",
          bankAccountNumber: user.bankAccountNumber || "",
          aadharNumber: user.aadharNumber || "",
        });
      } else {
        console.error("Failed to fetch user data");
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  validateForm = () => {
    const { srviceForMenWomen, aadharNumber } = this.state;
    const newErrors = {};

    if (!srviceForMenWomen) {
      newErrors.srviceForMenWomen = "Service  is required.";
    }

    if (!aadharNumber) {
      newErrors.aadharNumber = "Aadhar Number is required.";
    } else if (!/^\d{12}$/.test(aadharNumber)) {
      newErrors.aadharNumber = "Aadhar Number should be exactly 12 digits.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    this.setState({ loading: true });

    if (Object.keys(newErrors).length > 0) {
      this.setState(
        { errorMsg: "", successMsg: "", errors: newErrors, loading: false },
        () => {
          window.setTimeout(() => {
            this.setState({ errors: {} });
          }, 5000);
        }
      );
      return;
    } else {
      this.setState({ errors: {} });

      const {
        _id,
        token,
        aboutMe,
        course,
        course_name,
        facebook_url,
        instagram_url,
        gstNumber,
        aadharNumber,
        aadharPhotoFiles,
        cancelledChequeFile,
        experience,
        srviceForMenWomen,
      } = this.state;

      this.setState({ loading: true });

      const { OnboardingUpdateProInformation } = adminendPoints;
      const formData = new FormData();

      const userInformation = JSON.stringify({
        aboutMe,
        experience,
        course,
        course_name,
        facebook_url,
        instagram_url,
      });

      formData.append("id", _id);
      formData.append("srviceForMenWomen", srviceForMenWomen);
      formData.append("gstNumber", gstNumber);
      formData.append("aadharNumber", aadharNumber);
      formData.append("user_information", userInformation);
      Array.from(aadharPhotoFiles).forEach((file) => {
        formData.append("aadharPhoto", file);
      });
      formData.append("cancelledCheque", cancelledChequeFile);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          OnboardingUpdateProInformation,
          method,
          body,
          {},
          ""
        );
        const { status, data } = response;

        if (data && status === 200) {
          this.setState(
            {
              successMsg: "Profile updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successMsg: "",
                  redirect: true,
                  loading: false,
                });
              }, 5000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create profile. Please try again.",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.error("Error making data call:", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the service. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleAadharPhotoSelect = (e) => {
    const files = e.target.files;
    const previewURLs = [];
    const fileNames = []; // To store file names

    Array.from(files).forEach((file) => {
      fileNames.push(file.name); // Save file name
      const reader = new FileReader();
      reader.onloadend = () => {
        previewURLs.push(reader.result);
        if (previewURLs.length === files.length) {
          this.setState({
            aadharPhotoFiles: files,
            aadharPhotoPreviewURLs: previewURLs,
            aadharPhotoNames: fileNames, // Update state with file names
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };

  handleCancelledChequeSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        cancelledChequeFile: file,
        cancelledChequePreviewURL: reader.result,
        cancelledChequeFileName: file.name,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleFileRemove = (fileType, index) => {
    if (fileType === "cancelledCheque") {
      this.setState({
        cancelledChequeFile: null,
        cancelledChequePreviewURL: null,
        cancelledChequeFileName: "",
      });

      document.querySelector('input[name="cancelledCheque"]').value = "";
    } else if (fileType === "aadharPhoto") {
      const newFiles = Array.from(this.state.aadharPhotoFiles);
      const newPreviews = Array.from(this.state.aadharPhotoPreviewURLs);
      const newNames = Array.from(this.state.aadharPhotoNames);

      newFiles.splice(index, 1);
      newPreviews.splice(index, 1);
      newNames.splice(index, 1);

      this.setState({
        aadharPhotoFiles: newFiles,
        aadharPhotoPreviewURLs: newPreviews,
        aadharPhotoNames: newNames,
      });

      if (newFiles.length === 0) {
        document.querySelector('input[name="aadharPhoto"]').value = "";
      }
    }
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  render() {
    const {
      srviceForMenWomen,
      gstNumber,
      aadharPhotoPreviewURLs,
      aadharPhotoNames,
      errors,
      redirect,
      successMsg,
      errorMsg,
      loading,
      aadharNumber,
      aboutMe,
      course,
      course_name,
      facebook_url,
      instagram_url,
      token,
      _id,
    } = this.state;

    if (redirect) {
      return <Navigate to={`/EditExpert/${_id}`} />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Update Profile</h4>
                </div>
                <div className="ms-auto">
                  <Link to={`/EditExpert/${_id}`} className="btn-light1 ms-2">
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                {errorMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errorMsg}
                  </div>
                )}

                {successMsg && (
                  <div className="alert alert-success" role="alert">
                    {successMsg}
                  </div>
                )}

                {loading && (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Services provided to*</label>
                    <select
                      name="srviceForMenWomen"
                      value={srviceForMenWomen}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.srviceForMenWomen ? "is-invalid" : ""
                      }`}
                    >
                      <option value="">Please select</option>
                      <option value="Men">Men</option>
                      <option value="Women">Women</option>
                      <option value="Both">Both</option>
                    </select>
                    {errors.srviceForMenWomen && (
                      <div className="invalid-feedback">
                        {errors.srviceForMenWomen}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>GST Number </label>
                    <input
                      type="text"
                      name="gstNumber"
                      value={gstNumber}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.gstNumber ? "is-invalid" : ""
                      }`}
                      placeholder="Enter here..."
                    />
                    {errors.gstNumber && (
                      <div className="invalid-feedback">{errors.gstNumber}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Aadhar Number*</label>
                    <input
                      type="text"
                      name="aadharNumber"
                      value={aadharNumber}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.aadharNumber ? "is-invalid" : ""
                      }`}
                      placeholder="Enter here..."
                    />
                    {errors.aadharNumber && (
                      <div className="invalid-feedback">
                        {errors.aadharNumber}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Aadhar Photo</label>
                    {aadharPhotoPreviewURLs.length > 0 &&
                      aadharPhotoPreviewURLs.map((url, index) => (
                        <div key={index}>
                          <span className="file-name">
                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                              onClick={() =>
                                this.handleFileRemove("aadharPhoto", index)
                              }
                            ></i>
                            {aadharPhotoNames[index]}
                          </span>
                        </div>
                      ))}
                    <div className="file-upload-wrapper">
                      <input
                        type="file"
                        className="file-upload"
                        id="aadharPhoto"
                        placeholder="Absence through"
                        name="aadharPhoto"
                        accept="image/*"
                        multiple
                        onChange={this.handleAadharPhotoSelect}
                      />
                      <label
                        htmlFor="aadharPhoto"
                        className="file-upload-label"
                      >
                        Take Photo or Upload here..
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Any Course done </label>
                    <select
                      name="course"
                      value={course}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.course ? "is-invalid" : ""
                      }`}
                    >
                      <option value="">Please select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {errors.course && (
                      <div className="invalid-feedback">{errors.course}</div>
                    )}
                  </div>
                </div>
                {course === "Yes" && (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Course Name</label>
                      <input
                        type="text"
                        name="course_name"
                        value={course_name}
                        onChange={this.handleInputChange}
                        className={`form-control ${
                          errors.course_name ? "is-invalid" : ""
                        }`}
                        placeholder="Enter course name..."
                      />
                      {errors.course_name && (
                        <div className="invalid-feedback">
                          {errors.course_name}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Facebook URL</label>
                    <input
                      type="url"
                      name="facebook_url"
                      value={facebook_url}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.facebook_url ? "is-invalid" : ""
                      }`}
                      placeholder="Enter Facebook URL..."
                    />
                    {errors.facebook_url && (
                      <div className="invalid-feedback">
                        {errors.facebook_url}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Instagram URL</label>
                    <input
                      type="url"
                      name="instagram_url"
                      value={instagram_url}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.instagram_url ? "is-invalid" : ""
                      }`}
                      placeholder="Enter Instagram URL..."
                    />
                    {errors.instagram_url && (
                      <div className="invalid-feedback">
                        {errors.instagram_url}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>About Me</label>
                    <textarea
                      name="aboutMe"
                      value={aboutMe}
                      onChange={this.handleInputChange}
                      className={`t-height form-control ${
                        errors.aboutMe ? "is-invalid" : ""
                      }`}
                      placeholder="Tell us about yourself..."
                    />
                    {errors.aboutMe && (
                      <div className="invalid-feedback">{errors.aboutMe}</div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default updateProfile;

import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";
import ViewCity from "./viewCity";
import AddCity from "./addCity";
import UpdateCity from "./editCity";
import { usePermissions } from "../../utils/hooks/PermisionsHook";

const City = () => {
  // Initialize state with useState hooks
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const retrievedObjectPermission = getLocalStorageItem("myStylistAdminPermission");
  const { hasPermission } = usePermissions();
  const [id] = useState(retrievedObject ? retrievedObject.id : "");
  const [token] = useState(retrievedObject ? retrievedObject.token : "");
  const [results, setResults] = useState([]);
  const [districtResults, setDistrictResults] = useState([]);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [activeTab, setActiveTab] = useState("All");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [isVisibleView, setIsVisibleView] = useState(false);
  const [isVisibleAdd, setIsVisibleAdd] = useState(false);
  const [permissions] = useState(retrievedObjectPermission);
  const [loading, setLoading] = useState(true);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // Convert class methods to regular functions
  const getDistrictName = (districtId) => {
    const district = districtResults.find((item) => item._id === districtId);
    return district ? district.district_name : "N/A";
  };

  const getDistrictResults = () => {
    const { AdminDistrictName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminDistrictName}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;
        if (status === 200) {
          setDistrictResults(data);
        } else {
          setEmailErrorMsg("Invalid Request");
          setTimeout(() => setEmailErrorMsg(""), 10000);
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  const getCityResults = () => {
    setLoading(true);
    const { AdminCityList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;
    if (activeTab !== "All") {
      const status = activeTab === "Active" ? "Active" : "In-Active";
      queryParams += `&status=${status}`;
    }

    const url = `${AdminCityList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, citys, totalItems: total } = response.data;
        if (status === 200) {
          setResults(citys);
          setTotalItems(total);
          setLoading(false);
        } else {
          setEmailErrorMsg("Invalid Request");
          setLoading(false);
          setTimeout(() => setEmailErrorMsg(""), 10000);
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
        setLoading(false);
      });
  };

  // Convert lifecycle method to useEffect
  useEffect(() => {
    getDistrictResults();
    getCityResults();
  }, [currentPage, activeTab]); // Dependencies for useEffect

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTabClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setCurrentPage(1);
    }
  };

  const handleEditCity = (city) => {
    setIsVisibleUpdate(true);
    setSelectedCity(city);
  };

  const handleToggleCheckBox = (cityId, currentStatus) => {
    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";
    const { AdminUpdateCityStatus } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";
    const data = {
      id: cityId,
      status: newStatus
    };

    APICaller(AdminUpdateCityStatus, method, data, contentType, token)
      .then((response) => {
        if (response.data.status === 200) {
          getCityResults();
        } else {
          setErrorMsg("Failed to update status");
          setTimeout(() => setErrorMsg(""), 3000);
        }
      })
      .catch((error) => {
        console.log("error updating status --> ", error);
        setErrorMsg("Error updating status");
        setTimeout(() => setErrorMsg(""), 3000);
      });
  };

  const handleCloseViewContent = () => {
    setIsVisibleView(false);
  };

  const viewCategorytoggle = () => {
    setIsVisibleView(!isVisibleView);
  };

  const handleCloseAddContent = () => {
    setIsVisibleAdd(false);
  };

  const addCategorytoggle = () => {
    setIsVisibleAdd(!isVisibleAdd);
  };

  const handleCloseUpdateContent = () => {
    setIsVisibleUpdate(false);
    setSelectedCity(null);
  };

  const refreshCityList = () => {
    getCityResults();
  };

  if (token === "") {
    return <Navigate to="/" />;
  }

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Manage City</li>
                  </ol>
                  <h4 className="card-title mb-0">Manage City</h4>
                </div>
                <div className="ms-auto">
                  {hasPermission("city", "add") && (
                    <button
                      type="button"
                      className="btn-blue ms-2"
                      onClick={addCategorytoggle}
                    >
                      <i className="fa-solid fa-plus me-2"></i>Add New
                    </button>
                  )}
                </div>
              </div>
              <div className="tabs1 d-flex">
                <Link
                  onClick={() => handleTabClick("All")}
                  className={activeTab === "All" ? "active" : ""}
                >
                  All
                </Link>
                <Link
                  onClick={() => handleTabClick("Active")}
                  className={activeTab === "Active" ? "active" : ""}
                >
                  Active
                </Link>
                <Link
                  onClick={() => handleTabClick("In-Active")}
                  className={activeTab === "In-Active" ? "active" : ""}
                >
                  In-Active
                </Link>
              </div>
            </div>
          </div>

          {loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {successMsg && (
            <div className="alert alert-success" role="alert">
              {successMsg}
            </div>
          )}

          {errorMsg && (
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
          )}

          <div className="card mb-4">
            <div className="card-body">
              {/* <div className="row align-items-center mb-3">
                <div className="col-lg-5">
                  <div className="form-control-group-icon search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="form-control-group-icon ms-3">
                      <select
                        className="form-select w-auto"
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>District Name</th>
                    <th>City Name</th>
                    <th>Created Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {results.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No users available for the selected categories.
                      </td>
                    </tr>
                  ) : (
                    results.map((item, index) => {
                      const serialNumber =
                        (currentPage - 1) * itemsPerPage + index + 1;
                      return (
                        <tr key={index}>
                          <td>{serialNumber}.</td>
                          <td>{getDistrictName(item.district_id)}</td>
                          <td>{item.city_name}</td>
                          <td>{formatDate(item.createdAt)}</td>
                        
                            <td className="text-center">
                              {item.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    disabled={!hasPermission("city", "status")}
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={item.status === "Active"}
                                    onChange={() =>
                                      handleToggleCheckBox(
                                        item._id,
                                        item.status
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <a 
                                href="#" 
                                className="icon-btn"
                                onClick={() => {
                                  if (hasPermission("city", "edit")) {
                                    handleEditCity(item);
                                  }
                                }}
                              >
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt="Edit"
                                  title="Edit"
                                />
                              </a>
                            </td>
                         
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="d-flex align-items-center justify-content-end p-3">
                {currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(currentPage - 1)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}

                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={(e) => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>

                {currentPage < totalPages && (
                  <button
                    className="pagination-next"
                    onClick={(e) => handlePageChange(currentPage + 1)}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ViewCity
        isVisibleView={isVisibleView}
        handleCloseViewContent={handleCloseViewContent}
      />

      <AddCity
        isVisibleAdd={isVisibleAdd}
        handleCloseAddContent={handleCloseAddContent}
      />

      <UpdateCity
        isVisibleUpdate={isVisibleUpdate}
        handleCloseUpdateContent={handleCloseUpdateContent}
        selectedCity={selectedCity}
        refreshCityList={refreshCityList}
      />
    </div>
  );
};

export default City;

import React, { useState, useEffect, useRef } from "react";
import Header from "../Header/index";
import { isEqual } from "lodash";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Select from "react-select";
import { formatDate } from "../../utils/commonFunction";
import { RxCross1 } from "react-icons/rx";
import { BsCardImage } from "react-icons/bs";
const ImageUrl = process.env.REACT_APP_PROD_BUCKET_URL;
const EditCampaign = (props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([{ category_id: "", category_name: "", price: "" }]);
  const [campaignCode, setCampaignCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(""); 
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [token, setToken] = useState(getLocalStorageItem("myStylistAdmin") ? getLocalStorageItem("myStylistAdmin").token : "");
  const [errors, setErrors] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [id, setId] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [acceptanceDate, setAcceptanceDate] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedSubServiceId, setSelectedSubServiceId] = useState(null);
  const [subServicesWithUsersNumber, setSubServicesWithUsersNumber] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectedCitiesIds, setSelectedCitiesIds] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [usersResults, setUsersResults] = useState([]);
  const [Gender, setGender] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedImge, setSelectedImge] = useState(null);
  const [useLimit, setUserLimit] = useState(0);
  const [campaign, setCampaign] = useState({
    service_id: '',
    sub_service_id: '',
  });
  const [campaignType, setCampaignType] = useState("");
  const [campaignPrice, setCampaignPrice] = useState("");

  // Define the ref at component level
  const hasInitialized = useRef(false);

  useEffect(() => {
    const selectedCities = cities.filter(city => selectedCitiesIds.includes(city.value.toString()));

    let selectedCity = []
    selectedCities.map(city => {
      selectedCity.push({
        value: city.value,
        label: city.label
      })
    })
    setSelectedCity(selectedCity);
  }, [cities, selectedCitiesIds]);

  const getAllServices = async (gender) => {
    const { AdminServicesListMobile } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const stateUrl = `${AdminServicesListMobile}?Gender=${gender}`;

    try {
      const servicesResponse = await APICaller(
        stateUrl,
        method,
        "",
        contentType,
        token
      );
      const { services, status } = servicesResponse.data;
      if (status === 200) {
        setServices(services);
        const options = services.map(service => ({
          label: service.service_name,
          value: service._id,
        }));
        setServiceOptions(options);
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  const handleUsersNumberChange = (subServiceId, usersNumber) => {
    const updatedSubServicesWithUsersNumber = subServicesWithUsersNumber.map(
      (subService) => ({
        ...subService,
        usersNumber: usersNumber,
      })
    );
    setSubServicesWithUsersNumber(updatedSubServicesWithUsersNumber);
  };

  const toggleSubService = (subServiceId) => {
    const updatedSubServicesWithUsersNumber = subServicesWithUsersNumber.map(
      (subService) => ({
        ...subService,
        selected: subService._id === subServiceId,
      })
    );
    setSubServicesWithUsersNumber(updatedSubServicesWithUsersNumber);
  };

  const handleCityChange = (selectedOptions) => {
    setSelectedCity(selectedOptions);

    if (errors.selectedCity) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedCity: null,
      }));
      setErrorMsg("");
    }

    if (selectedOptions && selectedOptions.length > 0) {
      const selectedServiceIds = campaign.service_id || selectedService;
      const selectedSubServiceIds = subServicesWithUsersNumber
        .filter((subService) => subService.selected)
        .map((subService) => subService._id);

      const cityIds = selectedOptions.map(option => option.value);
      getUserList(
        selectedServiceIds,
        selectedSubServiceIds,
        cityIds
      );
    } else {
      // Clear users list if no cities are selected
      setUsersResults([]);
    }
  };

  const getUserList = async (
    selectedServiceIds,
    selectedSubServiceIds,
    selectedCityIds
  ) => {
    const { AdminGetUsersByServiceAndSubService } = adminendPoints;
    const method = "POST";

    const body = {
      serviceId: Array.isArray(selectedServiceIds)
        ? selectedServiceIds
        : [selectedServiceIds],
      subServiceId: selectedSubServiceIds,
      city: selectedCityIds,
    };
    const contentType = "application/json";

    try {
      const userResponse = await APICaller(
        AdminGetUsersByServiceAndSubService,
        method,
        body,
        contentType,
        token
      );

      const { users, status } = userResponse.data;
      if (status === 200) {
        setUsersResults(users.map((user) => ({ ...user, selected: true })));
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsersResults([]);
    }
  };

  const handleCampaignTypeChange = (e) => {
    setCampaignType(e.target.value);

    if (errors.campaignType) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        campaignType: null,
      }));
      setErrorMsg("");
    }
  };

  const handleUserClick = (userId) => {
    const updatedUsers = usersResults.map((user) =>
      user._id === userId ? { ...user, selected: !user.selected } : user
    );

    setUsersResults(updatedUsers);
  };

  useEffect(() => {
    const campaignid = window.location.pathname.split("/").pop();
    getCampaignResults(campaignid);
    getAllCategories();
    fetchCity();
  }, []);

  const getCampaignResults = async (id) => {
    const { AdminCampaignDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminCampaignDetails}/${id}`;

    try {
      const response = await APICaller(url, method, "", contentType, token);
      const { status, data } = response.data;

      if (status === 200) {
        const formattedStartDate = data.startDate ? data.startDate.substring(0, 10) : "";
        const formattedEndDate = data.endDate ? data.endDate.substring(0, 10) : "";
        const formattedAcceptanceDate = data.acceptanceDate ? data.acceptanceDate.substring(0, 10) : "";

        setId(data._id);
        setTitle(data.title);
        setDescription(data.description);
        setCategories(data.categories);
        setCampaignCode(data.campaignCode);
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setAcceptanceDate(formattedAcceptanceDate);
        setCampaignType(data.campaignType || "");
        setCampaignPrice(data.campaignPrice || "");
        setGender(data.gender || "");
        setSelectedService(data.services || "");
        setCampaignPrice(data.campaignDiscountPrice)
        getAllServices(data.gender);
        setSelectedImge(data.fileName);
        setCampaignType(data.campaignType);
        setUserLimit(data.limitPerUser);
        setTermsAndConditions(data.termsAndConditions);
        // Ensure data.services is an array and has at least one element
        if (Array.isArray(data.services) && data.services.length > 0) {
          setSelectedService(data.services[0] || "");
        } else {
          setSelectedService("");
        }
        setSelectedSubServiceId(data.sub_services[0] || "");
        // Set campaign service data
        setCampaign(prev => ({
          ...prev,
          service_id: data.service_id || '',
          sub_service_id: data.sub_service_id || '',
        }));

        setSelectedCitiesIds(data.cities || []);
        // Set selected cities
        // if (data.cities && data.cities.length > 0) {
        //   const selectedCities = data.cities.map(city => ({
        //     value: city.city_id,
        //     label: city.city_name
        //   }));
        //   setSelectedCity(selectedCities);
        // }

        // Load services based on gender
        if (data.Gender) {
          await getAllServices(data.Gender);
        }

        // Set preview image if exists
        if (data.bannerImage) {
          setPreviewURL(data.bannerImage);
        }

        // Load sub-services if service_id exists
        if (data.services[0]) {
          await fetchSubServices(data.services, data.gender);
        }

        // After setting all the campaign data, fetch users based on selected cities and services
        if (data.cities && data.cities.length > 0 && data.services && data.services.length > 0) {
          const selectedSubServices = data.sub_services || [];
          getUserList(
            data.services,
            selectedSubServices,
            data.cities
          );
        }
      } else {
        setErrorMsg("Invalid Request");
        setTimeout(() => setErrorMsg(""), 10000);
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      setErrorMsg("Error loading campaign details");
    }
  };

  const fetchCity = async () => {
    const { AdminCityListNew } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token };
      const response = await APICaller(
        AdminCityListNew,
        method,
        JSON.stringify(body),
        contentType,
        token
      );

      const { data, status } = response.data;

      if (status === 200) {
        const citiesData = data.map((city) => ({
          value: city.city_id,
          label: city.city_name,
        }));

        setCities(citiesData);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleSelectChange = (name, selectedOptions, Gender) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    const commaSeparatedValues = selectedValues.join(",");
    setCampaign((prevState) => ({
      ...prevState,
      [name]: commaSeparatedValues,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
      setErrorMsg("");
    }
    if (name === "service_id" && selectedValues.length > 0) {
      fetchSubServices(selectedValues, Gender);
    }
  };
  const getAllCategories = async () => {
    const { AdminGetAllCategories } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminGetAllCategories}`;

    try {
      const response = await APICaller(url, method, "", contentType, token);
      const { status, data } = response.data;

      if (status === 200) {
        setCategoryData(data);
      } else {
        setErrorMsg("Invalid Request");
        setTimeout(() => setErrorMsg(""), 10000);
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!title) newErrors.title = "Campaign Title is required.";
    if (!description) newErrors.description = "Description is required.";
    if (!campaignCode) newErrors.campaignCode = "Campaign Code is required.";
    if (!startDate) newErrors.startDate = "Start Date is required.";
    if (!endDate) newErrors.endDate = "End Date is required.";
    if (!selectedService) newErrors.service_id = "Service Name is required.";
    if (!selectedCity || selectedCity.length === 0) newErrors.selectedCity = "Select City is required.";
    if (!campaignType) newErrors.campaignType = "Campaign Type is required.";
    if (!campaignPrice) newErrors.campaignPrice = "Campaign Price is required.";

    const selectedSubServices = subServicesWithUsersNumber.filter(
      (subService) => subService.selected
    );
    if (selectedSubServices.length === 0) {
      newErrors.sub_service_id = "At least one Sub-Service with price is required.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setErrorMsg("Please fill in all required fields.");
      return;
    }

    const formData = new FormData();
    const { AdminEditCampaign } = adminendPoints;

    // Add selected sub-services and their prices
    const selectedSubServices = subServicesWithUsersNumber.filter(
      (subService) => subService.selected
    );

    selectedSubServices.forEach((subService, index) => {
      formData.append(`sub_services[${index}]`, subService._id);
      formData.append(`evailCapacity`, subService.usersNumber);
    });

    // Add all form fields
    const cityIds = selectedCity.map(city => city.value);
    formData.append("id", id);
    formData.append("cities", cityIds);
    formData.append("services[]", selectedService);
    formData.append("fileName", selectedFile);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("campaignCode", campaignCode);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("acceptanceDate", acceptanceDate);
    formData.append("campaignType", campaignType);
    formData.append("campaignPrice", campaignPrice);
    formData.append("Gender", Gender);
    formData.append('termsAndConditions',termsAndConditions)

    try {
      const response = await APICaller(
        AdminEditCampaign,
        "POST",
        formData,
        {},
        token
      );

      const { status } = response.data;

      if (status === 200) {
        setSuccessMsg("Campaign updated successfully.");
        setErrorMsg("");
        setTimeout(() => setRedirect(true), 5000);
      } else if (status === 409) {
        setErrorMsg("This Campaign Code already exists");
        setSuccessMsg("");
      } else {
        setErrorMsg("Failed to update campaign. Please try again.");
        setSuccessMsg("");
      }
    } catch (error) {
      console.error("Error updating campaign:", error);
      setErrorMsg("An error occurred while updating the campaign.");
      setSuccessMsg("");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "campaignCode":
        setCampaignCode(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      case "termsAndConditions":
        setTermsAndConditions(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Only run this effect when selectedSubServiceId is first set and hasn't been initialized
    if (selectedSubServiceId && subServicesWithUsersNumber.length > 0 && !hasInitialized.current) {
      const initialSubServices = subServicesWithUsersNumber.map(subService => ({
        ...subService,
        selected: subService._id === selectedSubServiceId,
        usersNumber: subService._id === selectedSubServiceId ? useLimit : 0,
      }));
      setSubServicesWithUsersNumber(initialSubServices);
      hasInitialized.current = true; // Mark as initialized
    }
  }, [selectedSubServiceId, subServicesWithUsersNumber, useLimit]);

  const handleCategoryChange = (e, index) => {
    const { name, value } = e.target;
    let updatedCategories = [...categories];

    if (name === "category_id") {
      const selectedCategory = categoryData.find(category => category._id === value);
      updatedCategories[index] = {
        ...updatedCategories[index],
        category_id: value,
        category_name: selectedCategory ? selectedCategory.name : "",
      };
    } else {
      updatedCategories[index] = { ...updatedCategories[index], [name]: value };
    }

    setCategories(updatedCategories);
  };

  const removeCategory = (index) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(index, 1);
    setCategories(updatedCategories);
  };

  const addCategory = () => {
    setCategories(prevCategories => [...prevCategories, { category_id: "", price: "" }]);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewURL(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileRemove = () => {
    setFileName("");
    setSelectedFile(null);
    setPreviewURL(null);
  };

  const fetchSubServices = async (serviceIds, gender) => {
    console.log(serviceIds, "serviceIds");
    const { AdminSubServicesListMobile } = adminendPoints;
    const method = "POST";
    const serviceIdsString = serviceIds.join(",");

    const body = {
      serviceIds: serviceIdsString,
      Gender: gender
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminSubServicesListMobile,
        method,
        body,
        contentType,
        token
      );
      const { status, subServices } = response.data;
      if (status === 200) {
        const subServicesWithUsersNumber = subServices.map((subService) => ({
          ...subService,
          selected: selectedSubServiceId === subService._id,
          usersNumber: 0, // You might want to load this from the campaign data
        }));
        console.log(subServicesWithUsersNumber, "subServicesWithUsersNumber");
        setSubServicesWithUsersNumber(subServicesWithUsersNumber);
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  if (token === "") {
    return <Navigate to="/" />;
  }

  if (redirect) {
    return <Navigate to="/ManageCampaigns" />;
  }
  console.log(campaign, "campaign");

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="row">
            <div className="col-lg-12">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <h4 className="card-title mb-0">Update Campaign</h4>
                    <div className="ms-auto">
                      <Link to="/ManageCampaigns" className="btn-light1 ms-2">
                        <i className="fa-solid fa-arrow-left-long me-2"></i>
                        <span>Back</span>
                      </Link>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="">
                      {errorMsg && (
                        <div className="alert alert-danger" role="alert">
                          {errorMsg}
                        </div>
                      )}
                      {successMsg && (
                        <div className="alert alert-success" role="alert">
                          {successMsg}
                        </div>
                      )}
                      {loading && (
                        <div className="d-flex justify-content-center align-items-center loader">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}

                      <div className="form-area1 row g-4">
                        {/* Gender Selection */}
                        <div className="col-lg-6">
                          <label htmlFor="service_id" className="form-label">
                            Select Gender
                          </label>
                          <Select
                            id="Gender"
                            name="Gender"
                            options={[
                              { label: "Boys", value: "Male" },
                              { label: "Girls", value: "Female" }
                            ]}
                            value={{
                              label: Gender === "Male" ? "Boys" : "Girls",
                              value: Gender
                            }}
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setGender(selectedOption.value);
                              getAllServices(selectedOption.value);
                              setCampaign((prevState) => ({
                                sub_service_id: '',
                                service_id: '',
                              }));
                            }}
                          />
                        </div>

                        {/* Category Selection */}
                        <div className="col-lg-6">
                          <label htmlFor="service_id" className="form-label">
                            Category Name
                          </label>
                          <Select
                            id="service_id"
                            name="service_id"
                            options={serviceOptions}
                            isMulti={false}
                            value={serviceOptions.find(option =>
                              option.value === selectedService
                            )}
                            onChange={(selectedOption) => {
                              setSelectedService(selectedOption.value);
                              handleSelectChange("service_id", [selectedOption], Gender);
                              setSubServicesWithUsersNumber([]);
                            }}
                          />
                          {errors.service_id && (
                            <p className="error">{errors.service_id}</p>
                          )}
                        </div>
                        <div className="col-lg-12">
                          {subServicesWithUsersNumber.length > 0 ? (
                            <>
                              <label htmlFor="sub_service_id" className="form-label">
                                Sub-Category Name
                              </label>
                              <div className="sub-services-container">
                                <div className="row g-3">
                                  {subServicesWithUsersNumber?.map((subService) => (
                                    <div className="col-md-4 col-lg-3" key={subService._id}>
                                      <div className={`sub-service-card ${subService.selected ? 'selected' : ''}`}>
                                        <div onClick={() => toggleSubService(subService._id)} className="d-flex align-items-center mb-2">
                                          <input
                                            type="radio"
                                            className="form-check-input me-2"
                                            checked={subService.selected}
                                            onChange={() => toggleSubService(subService._id)}
                                            id={`service-${subService._id}`}
                                          />
                                          <label className="form-check-label mt-1" htmlFor={`service-${subService._id}`}>
                                            {subService.sub_service_name}
                                          </label>
                                        </div>
                                        <div className="price-input-wrapper">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={subService.selected ? subService.usersNumber : 0}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              handleUsersNumberChange(subService._id, value);
                                            }}
                                            placeholder="Enter users number"
                                            disabled={!subService.selected}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {selectedService && (
                                <p className="sub-service-card">No sub-services found</p>
                              )}
                            </>
                          )}
                          {selectedService && errors.sub_service_id && (
                            <p className="error">{errors.sub_service_id}</p>
                          )}
                        </div>

                        {/* Campaign Details */}
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="title">Campaign Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              name="title"
                              value={title}
                              onChange={handleInputChange}
                              placeholder="Campaign Title"
                            />
                            {errors.title && <p className="error">{errors.title}</p>}
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-group">
                            <label htmlFor="campaignCode">Campaign Code</label>
                            <input
                              type="text"
                              className="form-control"
                              id="campaignCode"
                              name="campaignCode"
                              value={campaignCode}
                              onChange={handleInputChange}
                              placeholder="Campaign Code"
                            />
                            {errors.campaignCode && <p className="error">{errors.campaignCode}</p>}
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-group">
                            <label htmlFor="campaignPrice">Campaign Discount Price (₹)</label>
                            <input
                              type="number"
                              className="form-control"
                              id="campaignDiscountPrice"
                              name="campaignDiscountPrice"
                              value={campaignPrice}
                              onChange={(e) => {
                                setCampaignPrice(e.target.value);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  campaignPrice: null,
                                }));
                              }}
                              placeholder="Enter Price"
                            />
                            {errors.campaignPrice && <p className="error">{errors.campaignPrice}</p>}
                          </div>
                        </div>

                        {/* Cities Selection */}
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="city" className="form-label">Select Cities</label>
                            <Select
                              id="city"
                              isMulti
                              value={selectedCity}
                              onChange={handleCityChange}
                              options={cities}
                              className={errors.selectedCity ? "is-invalid" : ""}
                            />
                            {errors.selectedCity && (
                              <div className="invalid-feedback">{errors.selectedCity}</div>
                            )}
                          </div>
                        </div>

                        {/* Dates */}
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="startDate">Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              id="startDate"
                              name="startDate"
                              value={startDate}
                              onChange={handleInputChange}
                            />
                            {errors.startDate && <p className="error">{errors.startDate}</p>}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="endDate">End Date</label>
                            <input
                              type="date"
                              className="form-control"
                              id="endDate"
                              name="endDate"
                              value={endDate}
                              onChange={handleInputChange}
                            />
                            {errors.endDate && <p className="error">{errors.endDate}</p>}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="acceptanceDate">Acceptance Period</label>
                            <input
                              type="date"
                              className="form-control"
                              id="acceptanceDate"
                              name="acceptanceDate"
                              value={acceptanceDate}
                              onChange={(e) => setAcceptanceDate(e.target.value)}
                            />
                            {errors.acceptanceDate && <p className="error">{errors.acceptanceDate}</p>}
                          </div>
                        </div>

                        {/* Image Upload */}
                        <div className="col-lg-6">
                          <p>Upload Banner Image</p>
                          <div style={{ cursor: "pointer", height: "200px" }} className="upload-img-box">
                            <i className="fa-solid fa-file-arrow-up"></i>
                            <p className="mb-0">Upload Image</p>
                            <input
                              type="file"
                              className="form-control"
                              id="fileName"
                              name="fileName"
                              accept="image/*"
                              onChange={handleFileSelect}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <p>Banner Image Preview</p>
                          <div className="image-show-box">
                            {previewURL ? (
                              <div>
                                <img src={previewURL} alt="Campaign Preview" />
                                <button type="button" onClick={handleFileRemove}>
                                  <RxCross1 />
                                </button>
                              </div>
                            ) : (
                              <div>
                                <img src={`${selectedImge}`} alt="Campaign Preview" />
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Description */}
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea
                              className="form-control"
                              id="description"
                              name="description"
                              value={description}
                              onChange={handleInputChange}
                              placeholder="Description"
                            />
                            {errors.description && <p className="error">{errors.description}</p>}
                          </div>
                        </div>
                        

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="termsAndConditions">Terms and Conditions</label>
                            <textarea
                              className="form-control"
                              id="termsAndConditions"
                              name="termsAndConditions"
                              value={termsAndConditions}
                              onChange={handleInputChange}
                              placeholder="Terms and Conditions"
                            />
                            {errors.termsAndConditions && <p className="error">{errors.termsAndConditions}</p>}
                          </div>
                        </div>

                        {/* Campaign Type */}
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="campaignType">Campaign Type</label>
                            <select
                              className="form-control"
                              id="campaignType"
                              name="campaignType"
                              value={campaignType}
                              onChange={handleCampaignTypeChange}
                            >
                              <option value="">Select Campaign Type</option>
                              <option value="Marketing">Marketing</option>
                              <option value="Promotion">Promotion</option>
                              <option value="Event">Event</option>
                              <option value="Discount">Discount</option>
                              <option value="Other">Other</option>
                            </select>
                            {errors.campaignType && <p className="error">{errors.campaignType}</p>}
                          </div>
                        </div>

                        {/* User List */}
                        {usersResults.length > 0 && (
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label>User List</label>
                              <div className="user-grid-outer">
                                <div className="row">
                                  {usersResults.map((user) => (
                                    <div
                                      className="col-3"
                                      key={user._id}
                                      onClick={() => handleUserClick(user._id)}
                                    >
                                      <div className={`user-grid-item text-center mb-3 ${user.selected ? "active" : ""}`}>
                                        <figure className="mx-auto mb-2 user-img user-img-100 online">
                                          <img
                                            className="w-100"
                                            src="https://admin.mystylist.in/assets/img/dummy-user.png"
                                            alt=""
                                            title=""
                                          />
                                        </figure>
                                        <p className="fs-18 mb-0">
                                          <strong className="tc-6">{user.name}</strong>
                                        </p>
                                        <p className="fs-14 tc-7 mb-0">
                                          Customer Since: {formatDate(user.createdAt)}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Submit Button */}
                        <div className="col-12">
                          <button type="submit" className="btn-blue">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(EditCampaign);

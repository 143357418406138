import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class ViewCampaign extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: "",
      emailErrorMsg: "",
    };
  }

  componentDidMount() {
    const cmsid = window.location.pathname.split("/").pop();
    this.getCMSResults(cmsid);
  }

  getCMSResults = (id) => {
    const { token } = this.state;

    const { AdminCMSDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminCMSDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          this.setState({
            results: data,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  render() {
    const { results, token } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    return (
      <div className="wrapper">
        <Header />
        <SideBar />

        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>View CMS</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">CMS</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">CMS Page List</h3>
                </div>

                <div className="card-body p-0">
                  <table className="table table-striped projects">
                    <thead>
                      <tr>
                        <th>Page Title</th>
                        <th>Slug</th>
                        <th>Created Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.length === 0 ? (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No records found.
                          </td>
                        </tr>
                      ) : (
                        <tr key={results.id}>
                          <td>{results.title}</td>
                          <td>{results.slug}</td>
                          <td>{results.createdAt}</td>
                          <td className="project-state">
                            {results.status === "Active" ? (
                              <span className="badge badge-success">
                                {results.status}
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                {results.status}
                              </span>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(ViewCampaign);

import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";
import ViewTimeEntry from "./ViewTimeEntry";
import AddTimeEntry from "./AddTimeEntry";
import EditTimeEntry from "./EditTimeEntry";
import DeleteConfirmation from "./DeleteConfirmation";

class TimeManagement extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      search: "",
      isVisibleView: false,
      isVisibleAdd: false,
      selectedEntry: null,
      loading: true,
      errorMsg: "",
      successMsg: "",
      isVisibleEdit: false,
      selectedEditEntry: null,
      isVisibleDelete: false,
      selectedDeleteEntry: null,
      deleteLoading: false
    };
  }

  componentDidMount() {
    this.getTimeEntries();
  }

  getTimeEntries = async () => {
    this.setState({ loading: true });
    const { token } = this.state;

    try {
      const response = await APICaller(
        adminendPoints.AdminTimeManageget,
        "GET",
        null,
        "application/json",
        token
      );

      if (response.data.success) {
        this.setState({
          results: response.data.data,
          totalItems: response.data.count,
          loading: false
        });
      } else {
        this.setState({
          errorMsg: "Failed to fetch time entries",
          loading: false
        });
      }
    } catch (error) {
      console.error("Error fetching time entries:", error);
      this.setState({
        loading: false,
        errorMsg: "Error fetching time entries"
      });
    }
  };

  handleViewEntry = (entry) => {
    this.setState({
      selectedEntry: entry,
      isVisibleView: true
    });
  };

  handleCloseView = () => {
    this.setState({
      isVisibleView: false,
      selectedEntry: null
    });
  };

  handleAddToggle = () => {
    this.setState(prevState => ({
      isVisibleAdd: !prevState.isVisibleAdd
    }));
  };

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleEditEntry = (entry) => {
    this.setState({
      selectedEditEntry: entry,
      isVisibleEdit: true
    });
  };

  handleCloseEdit = () => {
    this.setState({
      isVisibleEdit: false,
      selectedEditEntry: null
    });
  };

  handleDeleteClick = (entry) => {
    this.setState({
      selectedDeleteEntry: entry,
      isVisibleDelete: true
    });
  };

  handleCloseDelete = () => {
    this.setState({
      isVisibleDelete: false,
      selectedDeleteEntry: null,
      deleteLoading: false
    });
  };

  handleConfirmDelete = async () => {
    const { selectedDeleteEntry, token } = this.state;
    
    this.setState({ deleteLoading: true });

    try {
      const response = await APICaller(
        `${adminendPoints.AdminTimeManageDelete}/${selectedDeleteEntry._id}`,
        "GET",
        null,
        "application/json",
        token
      );

      if (response.data.success) {
        this.setState({
          successMsg: "Time entry deleted successfully",
          isVisibleDelete: false,
          selectedDeleteEntry: null,
          deleteLoading: false
        });
        this.getTimeEntries();
      } else {
        throw new Error(response.data.message || "Failed to delete");
      }
    } catch (error) {
      console.error("Error deleting time entry:", error);
      this.setState({
        errorMsg: "Failed to delete time entry",
        deleteLoading: false
      });
    }
  };

  render() {
    const {
      results,
      loading,
      isVisibleView,
      isVisibleAdd,
      selectedEntry,
      token,
      errorMsg,
      successMsg,
      isVisibleEdit,
      selectedEditEntry,
      isVisibleDelete,
      selectedDeleteEntry,
      deleteLoading
    } = this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Time Management</li>
                    </ol>
                    <h4 className="card-title mb-0">Manage Time</h4>
                  </div>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-blue ms-2"
                      onClick={this.handleAddToggle}
                    >
                      <i className="fa-solid fa-plus me-2"></i>Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Error/Success Messages */}
            {errorMsg && (
              <div className="alert alert-danger">{errorMsg}</div>
            )}
            {successMsg && (
              <div className="alert alert-success">{successMsg}</div>
            )}

            {/* Loading Spinner */}
            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {/* Time Entries Table */}
            <div className="card">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Key</th>
                      <th>Duration</th>
                      <th>Description</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.map((entry, index) => (
                      <tr key={entry._id}>
                        <td>{entry.name}</td>
                        <td>{entry.slug}</td>

                        <td>{`${entry.hours}h ${entry.minutes}m`}</td>
                        <td>{entry.description}</td>
                        <td>{formatDate(entry.createdAt)}</td>
                        <td>
                          <div className="d-flex gap-2">
                            <button
                              className="btn-blue"
                              onClick={() => this.handleEditEntry(entry)}
                            >
                              <i className="fa-solid fa-edit me-1"></i>
                              Edit
                            </button>
                            <button
                              className="btn-red"
                              onClick={() => this.handleDeleteClick(entry)}
                            >
                              <i className="fa-solid fa-trash-can me-1"></i>
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* View Modal */}
        <ViewTimeEntry
          isVisible={isVisibleView}
          handleClose={this.handleCloseView}
          entry={selectedEntry}
        />

        {/* Add Modal */}
        <AddTimeEntry
          isVisible={isVisibleAdd}
          handleClose={this.handleAddToggle}
          onSuccess={() => {
            this.getTimeEntries();
            this.setState({
              successMsg: "Time entry added successfully",
              isVisibleAdd: false
            });
          }}
        />

        {/* Edit Modal */}
        <EditTimeEntry
          isVisible={isVisibleEdit}
          handleClose={this.handleCloseEdit}
          entry={selectedEditEntry}
          onSuccess={() => {
            this.getTimeEntries();
            this.setState({
              successMsg: "Time entry updated successfully",
              isVisibleEdit: false,
              selectedEditEntry: null
            });
          }}
        />

        {/* Add Delete Confirmation Modal */}
        <DeleteConfirmation
          isVisible={isVisibleDelete}
          handleClose={this.handleCloseDelete}
          handleConfirm={this.handleConfirmDelete}
          loading={deleteLoading}
        />
      </div>
    );
  }
}

export default connect()(TimeManagement);

import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import slugify from "slugify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class EditCMSPage extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      id: "",
      status: "",
      title: "",
      content: "",
      slug: "",
      editorHtml: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      results: "",
    };
  }

  componentDidMount() {
    const cmsid = window.location.pathname.split("/").pop();
    this.getCMSResults(cmsid);
  }

  getCMSResults = (id) => {
    const { token } = this.state;

    const { AdminCMSDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminCMSDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            results: data,
            id: data._id,
            status: data.status,
            title: data.title,
            content: data.content,
            slug: data.slug,
            editorHtml: data.content,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { title, content, slug } = this.state;
    const newErrors = {};

    if (!title) {
      newErrors.title = "Page title is required.";
    }

    if (!content) {
      newErrors.content = "Content is required.";
    }

    if (!slug) {
      newErrors.slug = "Slug is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });

      const { id, status, title, content, slug, token } = this.state;

      const { AdminEditCMS } = adminendPoints;
      const method = "POST";
      const body = {
        id,
        title,
        content,
        slug,
        status,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminEditCMS,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "CMS updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This CMS name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create CMS. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the District. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleEditorChange = (html) => {
    this.setState({ editorHtml: html, content: html }); // Set content state
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      const slug = slugify(value, { lower: true }); // Convert to lowercase
      this.setState({ [name]: value, slug }); // Update both title and slug
    } else {
      this.setState({ [name]: value });
    }
  };

  render() {
    const {
      status,
      title,
      content,
      slug,
      editorHtml,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/CMS" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Manage CMS</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>

                      <li className="breadcrumb-item active">CMS </li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>

            <div className="container-fluid">
              <div className="">
                <div className="col-md-12">
                  <div className="card card-primary">


                    <form onSubmit={this.handleSubmit}>
                      <div className="card-body">
                        {errorMsg && (
                          <div className="alert alert-danger alert-dismissible">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            <h5>
                              <i className="icon fas fa-ban"></i> Alert!
                            </h5>
                            {errorMsg}
                          </div>
                        )}
                        {successMsg && (
                          <div className="alert alert-success alert-dismissible">
                           
                            <h5>
                              <i className="icon fas fa-check"></i> Alert!
                            </h5>
                            {successMsg}
                          </div>
                        )}

                        {errors && (
                          <div className="callout callout-danger">
                            <h5>All * fields are required</h5>
                            {errors.title && (
                              <p className="error">{errors.title}</p>
                            )}
                            {errors.slug && (
                              <p className="error">{errors.slug}</p>
                            )}
                            {errors.content && (
                              <p className="error">{errors.content}</p>
                            )}
                          </div>
                        )}

                        <div className="row">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <label htmlFor="title">Page Title</label>
                              <input
                                type="text"
                                className="form-control"
                                id="title"
                                placeholder="Page Name"
                                name="title"
                                value={title}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-5">
                            <div className="form-group">
                              <label htmlFor="title">Slug</label>
                              <input
                                type="text"
                                className="form-control"
                                id="slug"
                                placeholder="Page Name"
                                disabled
                                name="slug"
                                value={slug}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-2 mt-3  ">
                          <div className="form-group d-flex align-items-center justify-content-start gap-2">
                            <label htmlFor="status">Status</label>
                            <select
                              name="status"
                              className="form-control" 
                              id="status"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Status</option>
                              <option
                                value="Active"
                                selected={status === "Active"}
                              >
                                Active
                              </option>
                              <option
                                value="In-Active"
                                selected={status === "In-Active"}
                              >
                                In-Active
                              </option>
                            </select>
                          </div>
                        </div>

                          <div className="col-lg-12 mt-3 ">
                            <div className="form-group">
                              <label htmlFor="title">Content</label>
                              <ReactQuill
                                name="content"
                                value={editorHtml}
                                onChange={this.handleEditorChange}
                                className="quill-editor"
                                style={{ minHeight: "200px" }}
                              />
                            </div>
                          </div>


                        </div>
                      </div>

                      <div className="card-footer">
                        <button type="submit" className="btn-blue">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(EditCMSPage);

import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";
import { usePermissions } from "../../utils/hooks/PermisionsHook";
const CampaignListing = () => {
  // Initialize state with useState hooks
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const retrievedObjectPermission = getLocalStorageItem("myStylistAdminPermission");
  const { hasPermission } = usePermissions();
  const [state, setState] = useState({
    id: retrievedObject ? retrievedObject.id : "",
    token: retrievedObject ? retrievedObject.token : "",
    results: [],
    emailErrorMsg: "",
    search: "",
    sort: "desc",
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    categoryData: [],
    category: "",
    activeTab: "All",
    isVisible: false,
    successMsg: "",
    errorMsg: "",
    permissions: retrievedObjectPermission,
    selectedCampaign: null,
    loading: false
  });

  // Convert class methods to regular functions
  const getAllCategories = () => {
    const { token } = state;
    const { AdminServicesList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminServicesList}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, categories } = response.data;

        if (status === 200) {
          setState(prev => ({
            ...prev,
            categoryData: categories,
          }));
        } else {
          setState(prev => ({
            ...prev,
            emailErrorMsg: "Invalid Request",
          }));
          setTimeout(() => {
            setState(prev => ({
              ...prev,
              emailErrorMsg: "",
            }));
          }, 10000);
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  const getCampaignResults = () => {
    const { search, token, currentPage, itemsPerPage, sort, activeTab } = state;
    const { AdminCampaignList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    let queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
      ...(search && { search: search.trim() }),
      ...(sort && { sort }),
    });

    if (activeTab !== "All") {
      queryParams.append("status", activeTab);
    }

    const url = `${AdminCampaignList}?${queryParams}`;
    setState(prev => ({ ...prev, loading: true }));

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, campaigns, totalItems } = response.data;

        if (status === 200) {
          setState(prev => ({
            ...prev,
            results: campaigns,
            totalItems: totalItems,
            loading: false,
          }));
        } else {
          setState(prev => ({
            ...prev,
            emailErrorMsg: "Invalid Request",
            loading: false,
          }));
          setTimeout(() => {
            setState(prev => ({
              ...prev,
              emailErrorMsg: "",
            }));
          }, 10000);
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
        setState(prev => ({
          ...prev,
          loading: false,
        }));
      });
  };

  // Convert lifecycle methods to useEffect
  useEffect(() => {
    getAllCategories();
    getCampaignResults();
  }, []);

  // Convert other class methods to regular functions
  const handleTabClick = (tab) => {
    setState(prev => ({ 
      ...prev,
      activeTab: tab, 
      currentPage: 1,
      search: ""
    }));
    getCampaignResults();
  };

  const handleSearchChange = (e) => {
    let value = e.target.value.replace(/\s+/g, " ");
    setState(prev => ({ ...prev, search: value }));

    if (window.searchDebounce) clearTimeout(window.searchDebounce);

    if (value.length >= 3 || value.length === 0) {
      window.searchDebounce = setTimeout(() => {
        setState(prev => ({ ...prev, currentPage: 1 }), getCampaignResults);
      }, 300);
    }
  };

  const handlePageChange = (pageNumber) => {
    setState(prev => ({ ...prev, currentPage: pageNumber }), getCampaignResults);
  };

  const toggleVisibility = (campaign) => {
    setState(prev => ({
      ...prev,
      isVisible: !prev.isVisible,
      selectedCampaign: campaign
    }));
  };

  const handleCloseContent = () => {
    setState(prev => ({ ...prev, isVisible: false }));
  };

  const handleToggleCheckBox = async (campaignId, currentStatus) => {
    const { token } = state;
    const { AdminCampaignStatusUpdate } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      campaignId,
      status: newStatus,
    };

    try {
      const response = await APICaller(
        AdminCampaignStatusUpdate,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        setState(prev => ({
          ...prev,
          results: prev.results.map((campaign) =>
            campaign._id === campaignId
              ? { ...campaign, status: newStatus }
              : campaign
          ),
          successMsg: `Campaign Status is ${newStatus}`,
          errorMsg: "",
        }));

        setTimeout(() => {
          setState(prev => ({ ...prev, successMsg: "" }));
        }, 2000);
      } else {
        console.error("Failed to update offer status");
        setState(prev => ({
          ...prev,
          errorMsg: message,
          successMsg: "", // Clear any previous success messages
        }));
      }
    } catch (error) {
      console.error("Error making data call:", error);
      setState(prev => ({
        ...prev,
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "", // Clear any previous success messages
      }));
    }
  };

  const handleSort = (sortField) => {
    setState(prev => ({ ...prev, sort: sortField, currentPage: 1 }), getCampaignResults);
  };

  // Render section
  if (state.token === "") {
    return <Navigate to="/" />;
  }

  const totalPages = Math.ceil(state.totalItems / state.itemsPerPage);
  console.log(state.selectedCampaign, "selectedCampaign");
  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Campaign List</li>
                  </ol>
                  <h4 className="card-title mb-0">Campaign List</h4>
                </div>
                {hasPermission("campaign", "add") && (
                  <div className="ms-auto">
                    <Link to="/AddCampaign" className="btn-blue ms-2">
                      <i className="fa-solid fa-plus me-2"></i>Genrate
                      Campaign
                    </Link>
                  </div>
                )}
              </div>
              <div className="tabs1 d-flex">
                <Link
                  onClick={() => handleTabClick("All")}
                  className={state.activeTab === "All" ? "active" : ""}
                >
                  All
                </Link>
                <Link
                  onClick={() => handleTabClick("Active")}
                  className={state.activeTab === "Active" ? "active" : ""}
                >
                  Active
                </Link>
                <Link
                  onClick={() => handleTabClick("Inactive")}
                  className={state.activeTab === "Inactive" ? "active" : ""}
                > 
                  Inactive
                </Link>
              </div>
            </div>
          </div>

          {state.loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {state.successMsg && (
            <div className="alert alert-success" role="alert">
              {state.successMsg}
            </div>
          )}

          {state.errorMsg && (
            <div className="alert alert-danger" role="alert">
              {state.errorMsg}
            </div>
          )}

          <div className="card mb-4">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-lg-4">
                  <div className="form-control-group-icon search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={state.search}
                      onChange={handleSearchChange}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="">
                      <select
                        className="form-select"
                        name="category"
                        value={state.category}
                        onChange={(e) => {
                          setState(prev => ({ ...prev, category: e.target.value }));
                          getCampaignResults();
                        }}
                        onBlur={getCampaignResults}
                      >
                        <option value="">All Service</option>
                        {state.categoryData.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.service_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-control-group-icon ms-3">
                      <select
                        className="form-select w-auto"
                        value={state.sort}
                        onChange={(e) => handleSort(e.target.value)}
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Campaign</th>
                    <th>User Name</th>
                    <th>Service</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {state.results.length === 0 ? (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No campaigns available for the selected categories.
                      </td>
                    </tr>
                  ) : (
                    state.results.map((item, index) => {
                      const uniqueUserNames = new Set();
                      const uniqueServices = new Set();
                      const uniqueSubServices = new Set();
                      const uniquePrices = new Set();

                      item.campaignAssignments.forEach((assignment) => {
                        if (assignment.user && assignment.user.name) {
                          uniqueUserNames.add(assignment.user.name);
                        }

                        assignment.services.forEach((service) => {
                          uniqueServices.add(service.service_name);
                        });

                        assignment.sub_services.forEach((subService) => {
                          uniqueSubServices.add(
                            subService.subService.sub_service_name
                          );
                          uniquePrices.add(subService.price);
                        });
                      });

                      const serialNumber =
                        (state.currentPage - 1) * state.itemsPerPage + index + 1;

                      return (
                        <tr key={index}>
                          <td>{serialNumber}.</td>
                          <td>{item.title}</td>
                          <td>{Array.from(uniqueUserNames).join(", ")}</td>
                          <td>
                            {Array.from(uniqueServices).map(
                              (service, index) => (
                                <div key={index}>
                                  {service} -{" "}
                                  {Array.from(uniqueSubServices).map(
                                    (subService, index) => (
                                      <span key={index}>
                                        {subService} (Price:{" "}
                                        {Array.from(uniquePrices).join(", ")})
                                        {index < uniqueSubServices.size - 1 &&
                                          ", "}
                                      </span>
                                    )
                                  )}
                                </div>
                              )
                            )}
                          </td>
                          <td>{formatDate(item.startDate)}</td>
                          <td>{formatDate(item.endDate)}</td>
                         
                            <td className="text-center">
                              {item.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    disabled={!hasPermission("campaign", "status")}
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={item.status === "Active"}
                                    onChange={() =>
                                      handleToggleCheckBox(
                                        item._id,
                                        item.status
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                         
                          <td className="text-center d-flex  gap-2 ">
                          
                              <Link to={hasPermission("campaign", "edit") ? `/EditCampaign/${item._id}` : "#"} className="icon-btn">
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                              <Link
                                to="#"
                                className="icon-btn"
                                onClick={() =>  {
                                  if (hasPermission("campaign", "view")) {
                                    toggleVisibility(item);
                                  }
                                }}
                              >
                                <img
                                  src="assets/img/icon-view.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                           
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="d-flex align-items-center justify-content-end p-3">
                {state.currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(state.currentPage - 1, e)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}
                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        state.currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={(e) => handlePageChange(index + 1, e)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
                {state.currentPage < totalPages && (
                  <button
                    className="pagination-next"
                    onClick={(e) => handlePageChange(state.currentPage + 1, e)}
                  >
                    <i className="fa-solid fa-angle-right"></i> 
                  </button>
                )}
              </div>
            </div>
            ;
          </div>
        </div>
      </div>

      <div
        className={`right-side-popup ${
          state.isVisible ? "" : "right-side-popup-hide"
        }`}
      >
        <div
          className="right-side-popup-close"
          onClick={handleCloseContent}
        >
          <i className="fa-solid fa-angles-right"></i>
        </div>
        {state.selectedCampaign && (
          <div className="right-side-popup-inner">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">#{state.selectedCampaign.campaignCode}</h4>
                  <p className="mb-0 tc-5">
                    Last Updated: {formatDate(state.selectedCampaign.updatedAt)}
                  </p>
                </div>
                <div className="ms-auto">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="">
                      <select className="form-select">
                        <option selected>{state.selectedCampaign.status}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <h5 className="mb-3 tc-8">Campaign Information</h5>
              <h5 className="mb-2 tc-6">{state.selectedCampaign.title}</h5>
              <p className="mb-2 tc-5">
                Purchase Limit: <span className="tc-6">{state.selectedCampaign.limitPerUser}</span>
              </p>
              <p className="mb-2 tc-5">
                Start Date: <span className="tc-6">{formatDate(state.selectedCampaign.startDate)}</span>
              </p>
              <p className="mb-4 tc-5">
                End Date: <span className="tc-6">{formatDate(state.selectedCampaign.endDate)}</span>
              </p>

              <h5 className="mb-2 tc-8">Selected Services</h5>
              <div className="d-flex mb-3">
                {state.selectedCampaign.campaignAssignments[0]?.services.map((service, index) => (
                  <div key={index} className="btn-tag me-2 mb-2">
                    {service.service_name}
                  </div>
                ))}
              </div>

              <h4 className="mb-3">Stylist Detail</h4>
              {state.selectedCampaign.campaignAssignments.length === 0 ? (
                <p className="text-muted">No stylists assigned to this campaign</p>
              ) : (
                state.selectedCampaign.campaignAssignments.map((assignment, index) => (
                  assignment.user && (
                    <div key={index} className="row align-items-center mb-3">
                      <div className="col-lg-8">
                        <div className="d-flex align-items-center">
                          <figure className="flex-shrink-0 me-3 mb-0 user-img-40">
                            <img src="assets/img/dummy-user.png" alt="" title="" />
                          </figure>
                          <div className="">
                            <p className="mb-0">
                              <strong>{assignment.user.name}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ))
              )}

              <h4 className="fw-medium">Campaign Details</h4>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Campaign Type</span>
                <span className="tc-6 fw-medium ms-auto">{state.selectedCampaign.campaignType}</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Discount</span>
                <span className="tc-6 fw-medium ms-auto">₹{state.selectedCampaign.campaignDiscountPrice}</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Booking Limit</span>
                <span className="tc-6 fw-medium ms-auto">{state.selectedCampaign.campaignBooking}</span>
              </p>
              <hr className="my-3" />
              <p className="h5 d-flex align-items-center mb-2 tc-6">
                <span className="fw-medium">Terms & Conditions</span>
              </p>
              <p className="tc-5">{state.selectedCampaign.termsAndConditions}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignListing;

import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";
import ViewBulkNotification from "./viewBulkNotification";
import AddBulkNotification from "./addBulkNotification";
import { usePermissions } from "../../utils/hooks/PermisionsHook";

const StylistBulkNotificationListing = ({ adminDetails }) => {
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const { hasPermission } = usePermissions();

  const [state, setState] = useState({
    token: retrievedObject ? retrievedObject.token : "",
    results: [],
    emailErrorMsg: "",
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    activeTab: "All",
    search: "",
    sort: "",
    isVisibleView: false,
    isVisibleAdd: false,
    selectedNotification: null,
    showDeleteConfirmation: false,
    notificationToDelete: null,
  });

  useEffect(() => {
    getBulkNotification();
  }, []);

  const getBulkNotification = () => {
    const { token, currentPage, itemsPerPage } = state;
    const { AdminGetAllStylistBulkNotifications } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const body = {
      page: currentPage,
      limit: itemsPerPage,
      for: "user",
    };

    APICaller(
      AdminGetAllStylistBulkNotifications,
      method,
      JSON.stringify(body),
      contentType,
      token
    )
      .then((response) => {
        const { status, notifications, totalItems } = response.data;

        if (status === 200) {
          setState(prev => ({
            ...prev,
            results: notifications,
            totalItems: totalItems,
          }));
        } else {
          setState(prev => ({
            ...prev,
            emailErrorMsg: "Invalid Request"
          }));
          setTimeout(() => {
            setState(prev => ({
              ...prev,
              emailErrorMsg: ""
            }));
          }, 10000);
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  const handlePageChange = (pageNumber) => {
    setState(prev => ({
      ...prev,
      currentPage: pageNumber
    }), () => {
      getBulkNotification();
    });
  };

  const handleSearchChange = (e) => {
    setState(prev => ({
      ...prev,
      search: e.target.value
    }));
  };

  const handleCloseViewContent = () => {
    setState(prev => ({
      ...prev,
      isVisibleView: false
    }));
  };

  const viewCategorytoggle = () => {
    setState(prev => ({
      ...prev,
      isVisibleView: !prev.isVisibleView
    }));
  };

  const handleCloseAddContent = () => {
    setState(prev => ({
      ...prev,
      isVisibleAdd: false
    }));
  };

  const addCategorytoggle = () => {
    setState(prev => ({
      ...prev,
      isVisibleAdd: !prev.isVisibleAdd
    }));
  };

  const handleTabClick = (tab) => {
    if (state.activeTab !== tab) {
      setState(prev => ({
        ...prev,
        activeTab: tab,
        currentPage: 1
      }), () => {
        getBulkNotification();
      });
    }
  };

  const handleDelete = (id) => {
    const { AdminDeleteStylistBulkNotification } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    APICaller(
      AdminDeleteStylistBulkNotification,
      method,
      JSON.stringify({ id }),
      contentType,
      {}
    )
      .then(() => {
        getBulkNotification();
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  const handleView = (notification) => {
    setState(prev => ({
      ...prev,
      isVisibleView: true,
      selectedNotification: notification
    }));
  };

  const handleDeleteClick = (notification) => {
    setState(prev => ({
      ...prev,
      showDeleteConfirmation: true,
      notificationToDelete: notification
    }));
  };

  const handleCancelDelete = () => {
    setState(prev => ({
      ...prev,
      showDeleteConfirmation: false,
      notificationToDelete: null
    }));
  };

  const handleConfirmDelete = () => {
    if (state.notificationToDelete) {
      handleDelete(state.notificationToDelete._id);
      setState(prev => ({
        ...prev,
        showDeleteConfirmation: false,
        notificationToDelete: null
      }));
    }
  };

  const {
    search,
    results,
    token,
    currentPage,
    itemsPerPage,
    activeTab,
    sort,
    isVisibleView,
    isVisibleAdd,
    showDeleteConfirmation,
    notificationToDelete,
  } = state;

  if (token === "") {
    return <Navigate to="/" />;
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredAndSortedItems = Array.isArray(results)
    ? [...results]
        .filter((user) => {
          const matchesSearch =
            user.title.toLowerCase().includes(search.toLowerCase()) ||
            user.slug.toLowerCase().includes(search.toLowerCase());
          return matchesSearch;
        })
        .sort((a, b) => {
          return sort === "asc"
            ? a._id.localeCompare(b._id)
            : b._id.localeCompare(a._id);
        })
    : [];

  const currentItems = filteredAndSortedItems.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Manage Notification to Users
                    </li>
                  </ol>
                  <h4 className="card-title mb-0">
                    Manage Notification to Users
                  </h4>
                </div>
                <div className="ms-auto">
                  {hasPermission("userPushNotification", "add") && (
                    <button
                      type="button"
                    className="btn-blue ms-2"
                    onClick={addCategorytoggle}
                  >
                    <i className="fa-solid fa-plus me-2"></i>Push New Notification
                  </button>
                  )}
                  {/* <button
                    type="button"
                    className="btn-light1 ms-2"
                    onClick={this.viewCategorytoggle}
                  >
                    <i className="fa-solid fa-file-export me-2"></i>View CMS
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Message</th>
                    <th>Type</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No notifications available for the selected
                        categories.
                      </td>
                    </tr>
                  ) : (
                    currentItems.map((item, index) => (
                      <tr key={index}>
                        <td>{item.title}</td>
                        <td
                          dangerouslySetInnerHTML={{ __html: item.message }}
                        />
                        <td>{item.deliveryMethod}</td>
                        <td>
                          <td>{formatDate(item.createdAt)}</td>
                        </td>
                        <td>
                         <div className="d-flex gap-2">
                         <button 
                         disabled={!hasPermission("userPushNotification", "view")}
                           className="btn-blue" 
                           onClick={() => {
                            if (hasPermission("userPushNotification", "view")) {
                              handleView(item);
                            }
                           }}
                         >
                           View
                         </button>
                         <button 
                         disabled={!hasPermission("userPushNotification", "delete")}
                           className="btn-red" 
                           onClick={() => {
                            if (hasPermission("userPushNotification", "delete")) {
                              handleDeleteClick(item);
                            }
                           }}
                         >
                           Delete
                         </button>
                         </div>
                        </td>
                      </tr>

                    ))
                  )}
                </tbody>
              </table>
              <div className="d-flex align-items-center justify-content-end p-3">
                {currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(currentPage - 1, e)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}

                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={(e) => handlePageChange(index + 1, e)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>

                {currentPage < totalPages && (
                  <button
                    className="pagination-next"
                    onClick={(e) => handlePageChange(currentPage + 1, e)}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ViewBulkNotification
        isVisibleView={isVisibleView}
        handleCloseViewContent={handleCloseViewContent}
        notificationData={state.selectedNotification}
      />

      <AddBulkNotification
        isVisibleAdd={isVisibleAdd}
        handleCloseAddContent={handleCloseAddContent}
        onAddSuccess={getBulkNotification}
      />

      {showDeleteConfirmation && (
        <div className="modal show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Delete</h5>
                <button type="button" className="btn-close" onClick={handleCancelDelete}></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete notification "{notificationToDelete?.title}"?</p>
                <p className="text-muted small">This action cannot be undone.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStatetoProps)(StylistBulkNotificationListing);

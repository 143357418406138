import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";

class AddBulkNotification extends Component {
  constructor(props) {
    super(props);

    const retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      userIds: [],
      title: "",
      message: "",
      status: "Active",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      loading: false,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };
  }

  componentDidMount() {
    this.getAllExperts();
  }

  getAllExperts = () => {
    const { token } = this.state;
    const { AdminGetAllExperts } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    APICaller(
      AdminGetAllExperts,
      method,
      JSON.stringify({}),
      contentType,
      token
    )
      .then((response) => {
        const { success, users } = response.data;

        if (success) {
          const userIds = users.map((user) => user._id);

          this.setState({
            userIds,
          });
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { title, message } = this.state;
    const newErrors = {};

    if (!title) {
      newErrors.title = "Title is required.";
    }

    if (!message) {
      newErrors.message = "Message is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
    } else {
      this.setState({ loading: true, errors: {} });

      const { userIds, title, message, status, token } = this.state;

      const { AdminSendStylistBulkNotifications } = adminendPoints;
      const method = "POST";
      const body = {
        userIds,
        title,
        message,
        status,
        userRole: "user",
        userStatus: "Active",
        adminId: "64d25bf44a62e3dbdddc6f74",
        deliveryMethod: "Promotional",
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminSendStylistBulkNotifications,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState({
            successMsg: "Bulk notification created successfully.",
            errorMsg: "",
            loading: false,
          });

          setTimeout(() => {
            this.setState({ successMsg: "" });
            this.props.onAddSuccess();
            this.props.handleCloseAddContent();
          }, 3000);
        } else {
          this.setState({
            errorMsg: "Failed to create bulk notification. Please try again.",
            successMsg: "",
            loading: false,
          });
        }
      } catch (error) {
        console.error("Error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the notification. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleEditorChange = (value) => {
    this.setState({ message: value });
  };

  render() {
    const { title, message, errors, redirect, successMsg, errorMsg, loading } =
      this.state;

    const { isVisibleAdd, handleCloseAddContent } = this.props;

    return (
      <div>
        <div
          className={`right-side-popup right-side-popup-w75 ${
            isVisibleAdd ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseAddContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <h4 className="card-title mb-0">Push Notification to Users</h4>
                </div>

                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={this.handleSubmit}
                >
                  {errorMsg && (
                    <div className="alert alert-danger">{errorMsg}</div>
                  )}
                  {successMsg && (
                    <div className="alert alert-success">{successMsg}</div>
                  )}

                  {loading && (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col-lg-12">
                      <label htmlFor="title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.title ? "is-invalid" : ""
                        }`}
                        id="title"
                        name="title"
                        value={title}
                        onChange={this.handleInputChange}
                        placeholder="Enter the title here..."
                      />
                      {errors.title && (
                        <div className="invalid-feedback">{errors.title}</div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <div
                        className={`form-group ${
                          errors.message ? "is-invalid" : ""
                        }`}
                      >
                        <textarea
                          value={message}
                          onChange={this.handleInputChange}
                          name="message"
                          className="form-control"
                          placeholder="Write your message here..."
                        />
                      </div>
                      {errors.message && (
                        <p className="invalid-feedback">{errors.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="mt-4 ">
                    <button type="submit" className="btn-blue">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`right-side-popup-backdrop ${
            isVisibleAdd ? "" : "d-none"
          }`}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(AddBulkNotification);

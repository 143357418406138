import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class EditSetting extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      loading: true,
      platform_fees: "",
      visit_charges: "",
      taxes: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      id: "",
    };
  }

  componentDidMount() {
    const settingId = window.location.pathname.split("/").pop();
    this.getSettingDetails(settingId);
  }

  getSettingDetails = async (id) => {
    this.setState({ loading: true });
    const { token } = this.state;
    const { AdminSettingDetails } = adminendPoints;
    const method = "POST";

    const body = {
      id: id,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminSettingDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, data } = response.data;

      if (status === 200 && data?.settings) {
        const { platform_fees, visit_charges, taxes } = data.settings;
        this.setState({
          platform_fees,
          visit_charges,
          taxes,
          id: data._id,
          loading: false,
        });
      } else {
        this.setState({ errorMsg: "Failed to fetch settings." });
      }
    } catch (error) {
      this.setState({
        errorMsg:
          "An error occurred while fetching settings. Please try again.",
        successMsg: "",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  validateForm = () => {
    const { platform_fees, visit_charges, taxes } = this.state;
    const newErrors = {};

    if (!platform_fees) newErrors.platform_fees = "Platform fees are required.";
    if (!visit_charges) newErrors.visit_charges = "Visit charges are required.";
    if (!taxes) newErrors.taxes = "Taxes are required.";

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
      return;
    }

    const { platform_fees, visit_charges, taxes, token, id } = this.state;

    const { AdminEditSetting } = adminendPoints;
    const method = "POST";
    const body = {
      settings: { platform_fees, visit_charges, taxes },
      id,
    };

    try {
      const response = await APICaller(
        AdminEditSetting,
        method,
        body,
        "application/json",
        token
      );

      if (response.data.status === 200) {
        this.setState(
          {
            successMsg: "Settings updated successfully.",
            errorMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "", redirect: true });
            }, 5000);
          }
        );
      } else {
        this.setState(
          {
            errorMsg: "Failed to update Settings",
            successMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "", redirect: false });
            }, 3000);
          }
        );
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while creating the Banner Name. Please try again.",
        successMsg: "",
      });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      platform_fees,
      visit_charges,
      taxes,
      successMsg,
      errorMsg,
      errors,
      redirect,
      token,
      loading,
    } = this.state;

    if (token === "") return <Navigate to="/" />;
    if (redirect) return <Navigate to="/ManageSettings" />;

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Edit Setting</h4>
                </div>
                <div className="ms-auto">
                  <Link to="/ManageSettings" className="link">
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              {loading && (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {errorMsg && (
                <div className="alert alert-danger" role="alert">
                  {errorMsg}
                </div>
              )}

              {successMsg && (
                <div className="alert alert-success" role="alert">
                  {successMsg}
                </div>
              )}
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                <div className="col-lg-6">
                  <label htmlFor="country_id">Platform Fees</label>
                  <input
                    type="number"
                    className={`form-control ${
                      errors.platform_fees ? "is-invalid" : ""
                    }`}
                    id="platform_fees"
                    name="platform_fees"
                    value={platform_fees}
                    onChange={this.handleInputChange}
                  />
                  {errors.platform_fees && (
                    <div className="invalid-feedback">
                      {errors.platform_fees}
                    </div>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="visit_charges">Visit Charges</label>
                  <input
                    type="number"
                    className={`form-control ${
                      errors.visit_charges ? "is-invalid" : ""
                    }`}
                    id="visit_charges"
                    name="visit_charges"
                    value={visit_charges}
                    onChange={this.handleInputChange}
                  />
                  {errors.visit_charges && (
                    <div className="invalid-feedback">
                      {errors.visit_charges}
                    </div>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="taxes">Taxes</label>
                  <input
                    type="number"
                    className={`form-control ${
                      errors.taxes ? "is-invalid" : ""
                    }`}
                    id="taxes"
                    name="taxes"
                    value={taxes}
                    onChange={this.handleInputChange}
                  />
                  {errors.taxes && (
                    <div className="invalid-feedback">{errors.taxes}</div>
                  )}
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Update Setting
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(EditSetting);

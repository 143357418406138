import React, { Component } from "react";
import { formatDate } from "../../utils/commonFunction";

class ViewBulkNotification extends Component {
  render() {
    const { isVisibleView, handleCloseViewContent, notificationData } = this.props;
    
    return (
      <div>
        <div className={`right-side-popup ${isVisibleView ? "" : "right-side-popup-hide"}`}>
          <div className="right-side-popup-close" onClick={handleCloseViewContent}>
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">{notificationData?.title}</h4>
                  <p className="mb-0 tc-5">
                    Sent on: {formatDate(notificationData?.createdAt)}
                  </p>
                </div>
              </div>

              {/* Notification Details */}
              <div className="mb-4">
                <h5 className="mb-3 tc-8">Notification Details</h5>
                <p className="mb-2 tc-5">
                  <strong>Message:</strong> {notificationData?.message}
                </p>
                <p className="mb-2 tc-5">
                  <strong>Type:</strong> {notificationData?.deliveryMethod}
                </p>
              </div>
              
              <div className="">
                <h5 className="mb-3 tc-8">Cities ({notificationData?.cities?.length || 0})</h5>
                <div  className="cities-list d-flex gap-2  flex-wrap" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {notificationData?.cities?.map((city, index) => (
                    <p style={{fontSize: '14px', padding: '5px 10px', borderRadius: '5px', backgroundColor: '#f0f0f0'}} key={index} className="mb-2 tc-5">{city.city_name}</p>
                  ))}
                </div>
              </div>

              {/* Recipients List */}
              <div className="mb-4">
                <h5 className="mb-3 tc-8">Recipients ({notificationData?.users?.length || 0})</h5>
                <div className="recipients-list" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {notificationData?.users?.map((user, index) => (
                    <div key={index} className="d-flex align-items-center mb-3 p-2 border rounded">
                      <div className="user-avatar me-3">
                        {user.user_profile_images?.[0]?.image ? (
                          <img 
                            src={`${process.env.REACT_APP_PROD_BUCKET_URL}/${user.user_profile_images[0].image}`} 
                            alt="User" 
                            className="rounded-circle"
                            style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                          />
                        ) : (
                          <div 
                            className="rounded-circle bg-secondary d-flex align-items-center justify-content-center"
                            style={{ width: '40px', height: '40px' }}
                          >
                            <i className="fa-solid fa-user text-white"></i>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="mb-0 fw-bold">{user.name || 'Anonymous User'}</p>
                        <p className="mb-0 small text-muted">{user?.role || 'N/A'}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`right-side-popup-backdrop ${isVisibleView ? "" : "d-none"}`}></div>
      </div>
    );
  }
}

export default ViewBulkNotification;

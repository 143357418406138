import React, { Component } from "react";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class AddCity extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      city_name: "",
      district_id: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      districtResults: [],
      token: retrievedObject ? retrievedObject.token : "",
    };
  }

  componentDidMount() {
    this.getDistrictResults();
  }

  getDistrictResults = () => {
    const { token } = this.state;

    const { AdminDistrictName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminDistrictName}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            districtResults: data,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { district_id, city_name } = this.state;
    const newErrors = {};

    if (!district_id) {
      newErrors.district_id = "District name is required.";
    }

    if (!city_name) {
      newErrors.city_name = "City name is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { city_name, district_id, token } = this.state;

      const { AdminAddCity } = adminendPoints;
      const method = "POST";
      const body = {
        district_id,
        city_name,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminAddCity,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "City created successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This City name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create City. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the District. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      districtResults,
      district_id,
      city_name,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
    } = this.state;
    const { isVisibleAdd, handleCloseAddContent } = this.props;

    return (
      <div>
        <div
          className={`right-side-popup right-side-popup-w75 ${
            isVisibleAdd ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseAddContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Add City Information</h4>
                    <p className="mb-0 tc-5">Add City</p>
                  </div>
                  <div className="ms-auto"> </div>
                </div>

                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={this.handleSubmit}
                >
                  {errorMsg && (
                    <div className="alert alert-danger alert-dismissible">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                      <h5>
                        <i className="icon fas fa-ban"></i> Alert!
                      </h5>
                      {errorMsg}
                    </div>
                  )}
                  {successMsg && (
                    <div className="alert alert-success alert-dismissible">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-hidden="true"
                      >
                        ×
                      </button>
                      <h5>
                        <i className="icon fas fa-check"></i> Alert!
                      </h5>
                      {successMsg}
                    </div>
                  )}

                  {errors && (
                    <div className="callout callout-danger">
                      <h5>All * fields are required</h5>
                      {errors.FaqTitle && (
                        <p className="error">{errors.FaqTitle}</p>
                      )}
                      {errors.FaqAnswer && (
                        <p className="error">{errors.FaqAnswer}</p>
                      )}
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col-lg-6">
                      <label htmlFor="district_id" className="form-label">
                        District Name
                      </label>
                      <select
                        name="district_id"
                        value={district_id}
                        className="form-select"
                        onChange={this.handleInputChange} // Change this to handleInputChange
                        id="district_id"
                      >
                        <option value="">Select District Name</option>
                        {districtResults.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.district_name}
                          </option>
                        ))}
                      </select>
                      {errors.district_id && (
                        <p className="error">{errors.district_id}</p>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="city_name" className="form-label">
                        City Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city_name"
                        placeholder="City Name"
                        name="city_name"
                        value={city_name}
                        onChange={this.handleInputChange}
                      />
                      {errors.city_name && (
                        <p className="error">{errors.city_name}</p>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <button type="submit" className="btn-blue">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`right-side-popup-backdrop ${
            isVisibleAdd ? "" : "d-none"
          }`}
        ></div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(AddCity);

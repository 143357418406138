import React, { Component } from "react";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { formatDate } from "../../utils/commonFunction";
import { Link } from "react-router-dom";

class AddCategory extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      searchTerm: "",
      selectedRows: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      category_name: "",
      fileName: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      userData: "",
      token: retrievedObject ? retrievedObject.token : "",
      selectedFile: null,
      previewURL: null,
      sortOrder: "",
      selectedStatus: "Active",
    };
  }

  handleSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  toggleCheckbox = (userId) => {
    const { selectedRows } = this.state;
    if (selectedRows.includes(userId)) {
      this.setState({
        selectedRows: selectedRows.filter((id) => id !== userId),
      });
    } else {
      this.setState({
        selectedRows: [...selectedRows, userId],
      });
    }
  };

  componentDidMount() {
    this.getAllExpertList();
  }

  getAllExpertList = async () => {
    try {
      const { AdminGetAllUsers } = adminendPoints;
      const { token } = getLocalStorageItem("myStylistAdmin");

      const response = await APICaller(
        AdminGetAllUsers,
        "GET",
        "",
        "application/json",
        token
      );

      const { status, users } = response.data;

      if (status === 200) {
        const userDataArray = Array.isArray(users) ? users : [];
        this.setState({ userData: userDataArray });
      } else {
        this.setState(
          {
            emailErrorMsg: "Invalid Request",
          },
          () => {
            setTimeout(() => {
              this.setState({ emailErrorMsg: "" });
            }, 10000);
          }
        );
      }
    } catch (error) {
      console.error("Error making data call -->", error);
    }
  };

  validateForm = () => {
    const { category_name, selectedFile } = this.state;
    const newErrors = {};

    if (!category_name) {
      newErrors.category_name = "Category Name is required.";
    }

    if (!selectedFile) {
      newErrors.fileName = "File is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { category_name, selectedFile, token } = this.state;

      const { AdminAddCategory } = adminendPoints;
      const formData = new FormData();
      formData.append("category_name", category_name);
      formData.append("fileName", selectedFile);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminAddCategory,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Category created successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
                if (this.props.onCategoryInsert) {
                  this.props.onCategoryInsert();
                }
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This category name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create category. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name, // Set the fileName state to the actual file name
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  render() {
    const {
      category_name,
      errors,
      successMsg,
      errorMsg,
      previewURL,
      userData,
      currentPage,
      itemsPerPage,
      selectedRows,
      searchTerm,
      sortOrder,
      selectedStatus,
    } = this.state;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredAndSortedItems = Array.isArray(userData)
      ? [...userData]
          .filter((user) => {
            const matchesSearch =
              user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              user.phone.toLowerCase().includes(searchTerm.toLowerCase());

            const matchesStatus = user.status === selectedStatus;

            return matchesSearch && matchesStatus;
          })
          .sort((a, b) => {
            // Sorting by user name
            return sortOrder === "asc"
              ? a.name.localeCompare(b.name)
              : b.name.localeCompare(a.name);
          })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Add Category Information</h4>
                  <p className="mb-0 tc-5">1349 User Added</p>
                </div>
                <div className="ms-auto">
                  <Link to="/ManageCategory" className="link">
                    <img
                      className="me-2"
                      src="assets/img/icon-edit1.svg"
                      alt=""
                      title=""
                    />
                    <span>Back</span>
                  </Link>
                </div>
              </div>

              <form className="">
                {errorMsg && (
                  <div className="alert alert-danger alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                    <h5>
                      <i className="icon fas fa-ban"></i> Alert!
                    </h5>
                    {errorMsg}
                  </div>
                )}
                {successMsg && (
                  <div className="alert alert-success alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h5>
                      <i className="icon fas fa-check"></i> Alert!
                    </h5>
                    {successMsg}
                  </div>
                )}

                {errors && (
                  <div className="callout callout-danger">
                    <h5>All * fields are required</h5>
                    {errors.category_name && (
                      <p className="error">{errors.category_name}</p>
                    )}
                    {errors.fileName && (
                      <p className="error">{errors.fileName}</p>
                    )}
                  </div>
                )}
                <div className="form-area1 row g-4">
                  <div className="col">
                    <div className="upload-img-box">
                      <i className="fa-solid fa-file-arrow-up"></i>
                      <p className="mb-0">Upload Image</p>
                      <input
                        type="file"
                        className="form-control"
                        id="fileName"
                        placeholder="Absence through"
                        name="fileName"
                        accept="image/*"
                        onChange={this.handleFileSelect}
                      />
                      {previewURL && (
                        <div>
                          <img
                            src={previewURL}
                            alt="Category Preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                          <button type="button" onClick={this.handleFileRemove}>
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <label for="" className="form-label">
                      Category Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="category_name"
                      placeholder="Category Name"
                      name="category_name"
                      value={category_name}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col">
                    <label for="" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value="Active" selected>
                        Active
                      </option>
                      <option value="In-active">Inactive</option>
                    </select>
                  </div>
                </div>

                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <h4 className="card-title mb-0">Stylist List</h4>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon search">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here..."
                          value={this.state.searchTerm}
                          onChange={this.handleSearchChange}
                        />
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sortOrder}
                          onChange={(e) =>
                            this.setState({ sortOrder: e.target.value })
                          }
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={selectedStatus}
                          onChange={(e) =>
                            this.setState({ selectedStatus: e.target.value })
                          }
                        >
                          <option value="Active">Active</option>
                          <option value="In-active">Inactive</option>
                        </select>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div className="ms-3">
                        <button type="button" className="btn-blue">
                          <i className="fa-solid fa-plus me-2"></i>Add New
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body p-0">
                  <table className="table align-middle mb-0">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <input
                            type="checkbox"
                            onChange={() => {
                              const allUserIds = userData.map(
                                (user) => user._id
                              );
                              const allSelected = allUserIds.every((id) =>
                                selectedRows.includes(id)
                              );

                              if (allSelected) {
                                this.setState({ selectedRows: [] });
                              } else {
                                this.setState({ selectedRows: allUserIds });
                              }
                            }}
                            checked={
                              selectedRows.length === userData.length &&
                              userData.length > 0
                            }
                          />
                        </th>

                        <th>SN.</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Gender</th>
                        <th>Status</th>
                        <th>Joining Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.length === 0 ? (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No users available for the selected categories.
                          </td>
                        </tr>
                      ) : (
                        currentItems.map((user, index) => (
                          <tr key={user._id}>
                            <td>
                              <input
                                type="checkbox"
                                onChange={() => this.toggleCheckbox(user._id)}
                                checked={selectedRows.includes(user._id)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>
                              <figure className="flex-shrink-0 me-3 mb-0 user-img-40">
                                <img
                                  src={`https://admin.mystylist.in/assets/img/user2-160x160.jpg`}
                                  width={150}
                                  className="img-thumbnail rounded-circle"
                                  alt="User"
                                />
                              </figure>
                            </td>
                            <td>{user.name}</td>
                            <td>{user.phone}</td>
                            <td>{user.gender}</td>
                            <td>{user.status}</td>
                            <td>{formatDate(user.createdAt)}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex align-items-center justify-content-end p-3">
                    {currentPage > 1 && (
                      <button
                        className="pagination-prev me-2"
                        onClick={(e) =>
                          this.handlePageChange(currentPage - 1, e)
                        }
                      >
                        <i className="fa-solid fa-angle-left"></i>
                      </button>
                    )}

                    <ul className="pagination m-0">
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={(e) => this.handlePageChange(index + 1, e)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>

                    {currentPage < totalPages && (
                      <button
                        className="pagination-next"
                        onClick={(e) =>
                          this.handlePageChange(currentPage + 1, e)
                        }
                      >
                        <i className="fa-solid fa-angle-right"></i>
                      </button>
                    )}
                  </div>
                </div>
                <div className="">
                  <button type="submit" className="btn-blue">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddCategory;

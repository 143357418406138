import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditBanner = () => {
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const initialState = {
    loading: false,
    banner_name: "",
    position: "",
    description: "",
    fileName: "",
    selectedFile: null,
    previewURL: null,
    successMsg: "",
    errorMsg: "",
    redirect: false,
    errors: {},
    token: retrievedObject ? retrievedObject.token : "",
    status: "In-Active",
    banner_type: "",
    cityResults: [],
    selectedCity: "",
    stylistResults: [],
    loadingStylist: false,
    selectedStylist: "",
    startDateTime: null,
    endDateTime: null,
    selectedStylistHours: null,
    id: "",
    youtube_link: "",
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const init = async () => {
      const svcid = window.location.pathname.split("/").pop();
      await loadCities();
      await getBannerDetails(svcid);
    };
    init();
  }, []);

  const loadCities = async () => {
    const { AdminCityActiveList } = adminendPoints;
    const response = await APICaller(AdminCityActiveList, "GET", {}, {}, state.token);
    setState(prev => ({
      ...prev,
      cityResults: response.data.citys,
    }));
  };

  const loadStylistByCity = async (city_id) => {
    const { AdminGetAllExpertsByCity } = adminendPoints;
    const response = await APICaller(
      AdminGetAllExpertsByCity + `?id=${city_id}`,
      "GET",
      {},
      {},
      state.token
    );
    setState(prev => ({
      ...prev,
      stylistResults: response.data.users,
    }));
  };

  const getBannerDetails = async (id) => {
    const { AdminBannerDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminBannerDetails}/${id}`;

    try {
      const response = await APICaller(url, method, "", contentType, state.token);
      const { status, banner } = response.data;

      if (status === 200) {
        if (banner.city) {
          await loadStylistByCity(banner.city);
        }

        setState(prev => ({
          ...prev,
          banner_name: banner.banner_name,
          id: banner._id,
          status: banner.status,
          position: banner.position,
          startDateTime: banner.startDate ? new Date(banner.startDate) : null,
          endDateTime: banner.endDate ? new Date(banner.endDate) : null,
          description: banner.description,
          banner_type: banner.banner_type?.toString(),
          selectedCity: banner.city || "",
          selectedStylist: banner.stylist || "",
          previewURL: banner.imageUrl || null,
          youtube_link: banner.youtube_link || "",
        }));
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  const validateForm = () => {
    const { 
      banner_name, 
      position, 
      banner_type,
      description,
      startDateTime,
      endDateTime,
      selectedCity,
      selectedStylist,
      youtube_link
    } = state;
    
    const newErrors = {};

    if (!banner_name?.trim()) newErrors.banner_name = "Banner Name is required.";
    if (!position) newErrors.position = "Position is required.";
    if (!banner_type) newErrors.banner_type = "Banner type is required.";
    if (!description?.trim()) newErrors.description = "Description is required.";
    if (!startDateTime) newErrors.startDateTime = "Start date and time is required.";
    if (!endDateTime) newErrors.endDateTime = "End date and time is required.";

    if (startDateTime && endDateTime && endDateTime <= startDateTime) {
      newErrors.endDateTime = "End date must be after start date.";
    }

    if (banner_type === "1") {
      if (!selectedCity) newErrors.city = "City selection is required for stylist referral.";
      if (!selectedStylist) newErrors.stylist = "Stylist selection is required for stylist referral.";
    }

    if (banner_type === "2") {
      if (!youtube_link?.trim()) {
        newErrors.youtube_link = "YouTube video link is required.";
      } else {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
        if (!youtubeRegex.test(youtube_link)) {
          newErrors.youtube_link = "Please enter a valid YouTube video link.";
        }
      }
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    setState(prev => ({ ...prev, loading: true }));

    if (Object.keys(newErrors).length > 0) {
      setState(prev => ({
        ...prev,
        errorMsg: "",
        successMsg: "",
        errors: newErrors,
        loading: false
      }));
      setTimeout(() => {
        setState(prev => ({ ...prev, errors: {} }));
      }, 5000);
      return;
    }

    const formData = new FormData();
    formData.append("id", state.id);
    formData.append("banner_name", state.banner_name);
    if (state.selectedFile) {
      formData.append("fileName", state.selectedFile);
    }
    formData.append("banner_for", 1);
    formData.append("description", state.description);
    formData.append("startDateTime", state.startDateTime ? state.startDateTime.toISOString() : null);
    formData.append("endDateTime", state.endDateTime ? state.endDateTime.toISOString() : null);
    formData.append("position", state.position);
    formData.append("status", state.status);
    formData.append("banner_type", state.banner_type);

    if (state.banner_type === "1") {
      formData.append("city", state.selectedCity);
      formData.append("stylist", state.selectedStylist);
    }

    if (state.banner_type === "2") {
      formData.append("youtube_link", state.youtube_link);
    }

    try {
      const response = await APICaller(
        adminendPoints.AdminEditBanner,
        "POST",
        formData,
        {},
        state.token
      );

      if (response.data.status === 200) {
        setState(prev => ({
          ...prev,
          successMsg: "Banner updated successfully.",
          errorMsg: "",
          loading: false,
        }));
        setTimeout(() => {
          setState(prev => ({ ...prev, successMsg: "", redirect: true }));
        }, 5000);
      } else {
        setState(prev => ({
          ...prev,
          errorMsg: response.data.message || "Failed to update banner.",
          successMsg: "",
          loading: false,
        }));
      }
    } catch (error) {
      console.error("Error updating banner:", error);
      setState(prev => ({
        ...prev,
        errorMsg: "An error occurred while updating the banner.",
        successMsg: "",
        loading: false,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setState(prev => ({
        ...prev,
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleFileRemove = () => {
    document.getElementById("fileName").value = "";
    setState(prev => ({
      ...prev,
      selectedFile: null,
      previewURL: null,
      fileName: "",
    }));
  };

  const handlePositionChange = (e) => {
    setState(prev => ({ ...prev, position: e.target.value }));
  };

  const handleBannerTypeChange = (e) => {
    setState(prev => ({ ...prev, banner_type: e.target.value }));
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setState(prev => ({ ...prev, selectedCity: cityId }));
    if (cityId) {
      loadStylistByCity(cityId);
    } else {
      setState(prev => ({ ...prev, stylistResults: [] }));
    }
  };

  if (state.token === "") {
    return <Navigate to="/" />;
  }

  if (state.redirect) {
    return <Navigate to="/ManageHomeBanners" />;
  }

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="card mb-4">
          <div className="card-body">
            <div className="d-flex align-items-center mb-4">
              <div className="bs-r-6 ps-3">
                <h4 className="card-title mb-0">Edit Banner Information</h4>
                <p className="mb-0 tc-5">Edit Banner</p>
              </div>
              <div className="ms-auto"> </div>
            </div>

            <form className="form-area row align-items-center mb-5" onSubmit={handleSubmit}>
              {state.errorMsg && (
                <div className="alert alert-danger" role="alert">
                  {state.errorMsg}
                </div>
              )}

              {state.successMsg && (
                <div className="alert alert-success" role="alert">
                  {state.successMsg}
                </div>
              )}

              {state.loading && (
                <div className="d-flex justify-content-center align-items-center loader">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              <div className="form-area1 row g-4">
                <div className="col">
                  <div className="upload-img-box">
                    {state.previewURL ? (
                      <div className="image-preview-container" style={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        padding: '10px',
                        marginBottom: '15px',
                        position: 'relative'
                      }}>
                        <div style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          zIndex: '1'
                        }}>
                          <button
                            type="button"
                            onClick={handleFileRemove}
                            className="btn btn-light btn-sm"
                            style={{
                              padding: '4px 8px',
                              fontSize: '12px',
                              backgroundColor: 'white',
                              border: '1px solid #dee2e6',
                              borderRadius: '4px',
                              boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                            }}
                          >
                            <i className="fa-solid fa-trash text-danger" style={{ fontSize: '12px' }}></i>
                          </button>
                        </div>
                        <div style={{
                          fontSize: '14px',
                          color: '#666',
                          marginBottom: '8px',
                          paddingLeft: '5px'
                        }}>
                          Current Banner Image
                        </div>
                        <div style={{
                          width: '100%',
                          height: '150px',
                          borderRadius: '4px',
                          overflow: 'hidden',
                          backgroundColor: '#f8f9fa',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          <img
                            src={state.previewURL}
                            alt="Banner Preview"
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain'
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div style={{
                        border: '2px dashed #dee2e6',
                        borderRadius: '8px',
                        padding: '20px',
                        textAlign: 'center',
                        backgroundColor: '#f8f9fa'
                      }}>
                        <i className="fa-solid fa-file-arrow-up mb-2" style={{ fontSize: '24px', color: '#adb5bd' }}></i>
                        <p className="mb-0" style={{ fontSize: '14px', color: '#666' }}>Upload Image *</p>
                      </div>
                    )}
                    <input
                      type="file"
                      className={`form-control mt-2 ${state.errors.fileName ? 'is-invalid' : ''}`}
                      id="fileName"
                      name="fileName"
                      accept="image/*"
                      onChange={handleFileSelect}
                    />
                    {state.errors.fileName && (
                      <div className="invalid-feedback">{state.errors.fileName}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-7">
                  <label htmlFor="banner_name" className="form-label">
                    Banner Name *
                  </label>
                  <input
                    type="text"
                    className={`form-control ${state.errors.banner_name ? 'is-invalid' : ''}`}
                    name="banner_name"
                    value={state.banner_name}
                    onChange={handleInputChange}
                  />
                  {state.errors.banner_name && (
                    <div className="invalid-feedback">{state.errors.banner_name}</div>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="position" className="form-label">
                    Position *
                  </label>
                  <select
                    className={`form-select ${state.errors.position ? 'is-invalid' : ''}`}
                    value={state.position}
                    onChange={handlePositionChange}
                  >
                    <option value="">Select Position</option>
                    <option value="Top">Top</option>
                    <option value="Middle">Middle</option>
                    <option value="Bottom">Bottom</option>
                  </select>
                  {state.errors.position && (
                    <div className="invalid-feedback">{state.errors.position}</div>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="banner_type" className="form-label">
                    Banner Type *
                  </label>
                  <select
                    className={`form-select ${state.errors.banner_type ? 'is-invalid' : ''}`}
                    value={state.banner_type}
                    onChange={handleBannerTypeChange}
                  >
                    <option value="">Select Banner Type</option>
                    <option value="0">Show Only</option>
                    <option value="1">Refer to Stylist</option>
                    <option value="2">Show YouTube Video</option>
                  </select>
                  {state.errors.banner_type && (
                    <div className="invalid-feedback">{state.errors.banner_type}</div>
                  )}
                </div>

                {(state.banner_type === "1" || state.banner_type === 1) && (
                  <>
                    <div className="col-lg-6 d-flex flex-column mb-3">
                      <label htmlFor="city" className="form-label">
                        City *
                      </label>
                      <select
                        className={`form-select ${state.errors.city ? 'is-invalid' : ''}`}
                        value={state.selectedCity}
                        onChange={handleCityChange}
                      >
                        <option value="">Select City</option>
                        {state.cityResults.map((city) => (
                          <option key={city._id} value={city._id}>
                            {city.city_name}
                          </option>
                        ))}
                      </select>
                      {state.errors.city && (
                        <div className="invalid-feedback">{state.errors.city}</div>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="stylist" className="form-label">
                        Stylist *
                      </label>
                      <select
                        className={`form-select ${state.errors.stylist ? 'is-invalid' : ''}`}
                        value={state.selectedStylist}
                        onChange={(e) => setState(prev => ({ ...prev, selectedStylist: e.target.value }))}
                      >
                        <option value="">Select Stylist</option>
                        {state.stylistResults.map((stylist) => (
                          <option key={stylist._id} value={stylist._id}>
                            {stylist.name}
                          </option>
                        ))}
                      </select>
                      {state.errors.stylist && (
                        <div className="invalid-feedback">{state.errors.stylist}</div>
                      )}
                    </div>
                  </>
                )}

                {state.banner_type === "2" && (
                  <div className="col-lg-6">
                    <label htmlFor="youtube_link" className="form-label">
                      Youtube Video Link *
                    </label>
                    <input
                      type="text"
                      className={`form-control ${state.errors.youtube_link ? 'is-invalid' : ''}`}
                      name="youtube_link"
                      value={state.youtube_link}
                      onChange={handleInputChange}
                      placeholder="Enter YouTube video link"
                    />
                    {state.errors.youtube_link && (
                      <div className="invalid-feedback">{state.errors.youtube_link}</div>
                    )}
                  </div>
                )}

                <div className="col-lg-6 d-flex flex-column mb-3">
                  <label htmlFor="startDateTime" className="form-label">
                    Start Date & Time *
                  </label>
                  <DatePicker
                    selected={state.startDateTime}
                    onChange={(date) => setState(prev => ({ ...prev, startDateTime: date }))}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className={`form-control ${state.errors.startDateTime ? 'is-invalid' : ''}`}
                    minDate={new Date()}
                  />
                  {state.errors.startDateTime && (
                    <div className="invalid-feedback">{state.errors.startDateTime}</div>
                  )}
                </div>

                <div className="col-lg-6 d-flex flex-column mb-3">
                  <label htmlFor="endDateTime" className="form-label">
                    End Date & Time *
                  </label>
                  <DatePicker
                    selected={state.endDateTime}
                    onChange={(date) => setState(prev => ({ ...prev, endDateTime: date }))}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className={`form-control ${state.errors.endDateTime ? 'is-invalid' : ''}`}
                    minDate={state.startDateTime || new Date()}
                  />
                  {state.errors.endDateTime && (
                    <div className="invalid-feedback">{state.errors.endDateTime}</div>
                  )}
                </div>

                <div className="col-lg-12">
                  <label htmlFor="description" className="form-label">
                    Description *
                  </label>
                  <textarea
                    className={`form-control ${state.errors.description ? 'is-invalid' : ''}`}
                    name="description"
                    value={state.description}
                    onChange={handleInputChange}
                  />
                  {state.errors.description && (
                    <div className="invalid-feedback">{state.errors.description}</div>
                  )}
                </div>

                <div className="col-lg-12">
                  <button type="submit" className="btn-blue" disabled={state.loading}>
                    {state.loading ? 'Updating...' : 'Update Banner'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStatetoProps)(EditBanner);

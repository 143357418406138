import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class viewExpert extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: "",
      emailErrorMsg: "",
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.getUserResults(userid);
  }

  getUserResults = async (userid) => {
    const { token } = this.state;

    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const body = {
      userid,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, user } = response.data;

      if (status === 200) {
        this.setState({
          results: user,
        });
      } else {
        this.setState({
          errorMsg: "Failed to create City. Please try again.",
          successMsg: "",
        });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while creating the District. Please try again.",
        successMsg: "",
      });
    }
  };

  render() {
    const { results, redirect, token } = this.state;
    console.log(results.user_information);

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageExperts" />;
    }

    return (
      <div className="wrapper">
        <Header />
        <SideBar />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Manage Expert</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>

                    <li className="breadcrumb-item active">
                      View Expert Details
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid">
            <div className="">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">View Expert Details </h3>

                    <div className="card-tools">
                      <Link
                        to="/ManageExperts"
                        className="btn btn-sm btn-success"
                      >
                        Manage Experts
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <tbody>
                <tr className="odd">
                  <th>ID</th>
                  <td>{results._id}</td>
                </tr>
                <tr className="even">
                  <th>Name</th>
                  <td>{results.name}</td>
                </tr>
                <tr className="odd">
                  <th>Email</th>
                  <td>{results.email}</td>
                </tr>
                <tr className="even">
                  <th>phone</th>
                  <td>{results.phone}</td>
                </tr>
                <tr className="odd">
                  <th>Role</th>
                  <td>{results.role}</td>
                </tr>
                <tr className="even">
                  <th>Username</th>
                  <td>{results.username}</td>
                </tr>
                <tr className="odd">
                  <th>Status</th>
                  <td>{results.status}</td>
                </tr>
                <tr className="even">
                  <th>Business Type</th>
                  <td>{results.business_type}</td>
                </tr>
                <tr className="odd">
                  <th>Gender</th>
                  <td>{results.gender}</td>
                </tr>
                <tr className="even">
                  <th>Birthday</th>
                  <td>{results.birthday}</td>
                </tr>
                <tr className="odd">
                  <th>State</th>
                  {results.state && results.state.length > 0 ? (
                    results.state.map((item) => (
                      <td key={item.id}>{item.state_name}</td>
                    ))
                  ) : (
                    <td>--</td>
                  )}
                </tr>
                <tr className="even">
                  <th>District</th>
                  {results.district && results.district.length > 0 ? (
                    results.district.map((item) => (
                      <td key={item.id}>{item.district_name}</td>
                    ))
                  ) : (
                    <td>--</td>
                  )}
                </tr>
                <tr className="odd">
                  <th>City</th>
                  {results.city && results.city.length > 0 ? (
                    results.city.map((item) => (
                      <td key={item.id}>{item.city_name}</td>
                    ))
                  ) : (
                    <td>--</td>
                  )}
                </tr>
                <tr className="even">
                  <th>Addresses</th>
                  <td>
                    {results.address ? (
                      <table className="inner-table">
                        <tbody>
                          {results.address.map((address, index) => (
                            <tr key={index}>
                              {Object.keys(address).map((key) => (
                                <td key={key}>
                                  <strong>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                    :
                                  </strong>{" "}
                                  {key === "location" ? (
                                    <span>
                                      Type: {address[key].type}, Coordinates:{" "}
                                      {address[key].coordinates.join(", ")}
                                    </span>
                                  ) : (
                                    address[key]
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No addresses available.</p>
                    )}
                  </td>
                </tr>

                <tr className="odd">
                  <th>Aawl</th>
                  <td>
                    {results.aawl ? (
                      <table className="inner-table">
                        <tbody>
                          {results.aawl.map((aawl, index) => (
                            <tr key={index}>
                              {Object.keys(aawl).map((key) => (
                                <td key={key}>
                                  <strong>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                    :
                                  </strong>{" "}
                                  {key === "location" ? (
                                    <span>
                                      Type: {aawl[key].type}, Coordinates:{" "}
                                      {aawl[key].coordinates.join(", ")}
                                    </span>
                                  ) : (
                                    aawl[key]
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No aawl available.</p>
                    )}
                  </td>
                </tr>

                <tr className="even">
                  <th>Amenities</th>
                  <td>
                    {results.amenities ? (
                      <table className="inner-table inner-table-data">
                        <tbody>
                          {results.amenities.map((amenity, index) => (
                            <tr key={index}>
                              {Object.keys(amenity).map((key) => (
                                <td key={key}>
                                  <strong>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                    :
                                  </strong>{" "}
                                  {amenity[key]}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No amenities available.</p>
                    )}
                  </td>
                </tr>

                <tr className="odd">
                  <th>Working Hours</th>
                  <td>
                    <div>
                      {results.working_hours ? (
                        results.working_hours.map((hours, index) => {
                          const { _id, ...restHours } = hours; // Exclude _id from hours
                          return (
                            <div className="shopopen" key={index}>
                              {Object.keys(restHours).map((day) => (
                                <div key={day}>
                                  <p>
                                    <strong>
                                      {day.charAt(0).toUpperCase() +
                                        day.slice(1)}
                                    </strong>
                                  </p>
                                  <p>
                                    {restHours[day].open ? "Open" : "Closed"}
                                  </p>
                                  <p>From: {restHours[day].from}</p>
                                  <p>To: {restHours[day].to}</p>
                                </div>
                              ))}
                            </div>
                          );
                        })
                      ) : (
                        <p>No working hours available.</p>
                      )}
                    </div>
                  </td>
                </tr>

                <tr className="odd">
                  <th>User Information</th>
                  <td>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Experience</th>
                          <th>Course</th>
                          <th>Course Name</th>
                          <th>Facebook URL</th>
                          <th>Instagram URL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results.user_information &&
                        results.user_information.length > 0 ? (
                          results.user_information.map((info, index) => (
                            <tr key={index}>
                              <td>{info.experience}</td>
                              <td>{info.course}</td>
                              <td>{info.course_name}</td>
                              <td>{info.facebook_url}</td>
                              <td>{info.instagram_url}</td>
                            </tr>
                          ))
                        ) : (
                          <td>--</td>
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(viewExpert);

import React, { Component, useState } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx';

export default class Home extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: "",
      stylistResults: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      loading: true,
      selectedDate: null,
      selectedFilter: 'all',
      selectedCities: [],
      isDatePickerOpen: false,
      stylistStatus: 'all',
      show: false
    };
  }

  componentDidMount() {
    this.getCityWiseMaleFeMale();
    this.getStylistsStat();
  }

  getStylistsStat = () => {
    this.setState({ loading: true });
    const {
      token,
      currentPage,
      itemsPerPage,
      selectedDate,
      selectedFilter,
      selectedCities,
      stylistStatus,
    } = this.state;
    const { AdminGetStylistsStats } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    let queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage
    });

    // Add date filter if selected
    if (selectedDate) {
      queryParams.append('date', selectedDate.toISOString().split('T')[0]);
    };

    if (stylistStatus !== 'all') {
      queryParams.append('status', stylistStatus);
    };

    // Add role filter if not 'all'
    if (selectedFilter !== 'all') {
      queryParams.append('role', selectedFilter);
    }

    // Add selected cities
    if (selectedCities.length > 0) {
      // Join city IDs with comma
      const CitiesArray = selectedCities.map(city => city.id);
      const cityString = JSON.stringify(CitiesArray);
      queryParams.append('cities', cityString);
    }

    const url = `${AdminGetStylistsStats}?${queryParams.toString()}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, totalItems, stylistStats } = response.data;

        if (status === 200) {
          this.setState({
            totalItems: totalItems,
            stylistResults: stylistStats,
            loading: false,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
              loading: false,
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
        this.setState({
          loading: false,
        });
      });
  };
  exportToExcel = () => {
    const { stylistResults } = this.state;

    // Format the data for export
    const dataToExport = stylistResults.map((stylist, index) => ({
      'Sr. No.': index + 1,
      'Stylist Name': stylist.stylistName || 'N/A',
      'Gender': stylist.gender || 'N/A',
      'Status': stylist.status || 'N/A',
      'Joining Date': stylist.joiningDate ? new Date(stylist.joiningDate).toLocaleDateString() : 'N/A',
      'At Salon Booking': stylist.atSalonBooking || 0,
      'Star Rating': stylist.starRating || 0,
      'No. Of Review': stylist.numberOfReviews || 0,
      'Offer Created': stylist.offerCreated || 0,
      'Offer Booked': stylist.offerBooked || 0,
      'Package Created': stylist.packageCreated || 0,
      'Package Booked': stylist.packageBooked || 0,
      'Campaign Created': stylist.campaignCreated || 0,
      'Campaign Booked': stylist.campaignBooked || 0
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(dataToExport);

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Stylists Data');

    // Generate file name with current date
    const fileName = `Stylists_Report_${new Date().toLocaleDateString().replace(/\//g, '-')}.xlsx`;

    // Save file
    XLSX.writeFile(wb, fileName);
  };

  getCityWiseMaleFeMale = async () => {
    const { token, selectedFilter, } = this.state;
    const { AdminGetCityStylistSummary } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      let body = {};
      if (selectedFilter !== 'all') {
        body = {
          role: selectedFilter
        };
      }
      const response = await APICaller(
        AdminGetCityStylistSummary,
        method,
        body,
        contentType,
        token
      );

      const { status, data } = response.data;

      if (status === 200) {
        this.setState({
          results: data,
        });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while fetching users by category. Please try again.",
        successMsg: "",
      });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getStylistsStat();
    });
  };

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
      isDatePickerOpen: false
    });
  };

  handleFilterChange = (filter) => {
    this.setState({
      selectedFilter: filter
    });
  };


  handleStatusChange = (filter) => {
    this.setState({
      stylistStatus: filter
    });
  };

  handleCitySelection = (cityId, cityName) => {
    this.setState(prevState => {
      const cities = [...prevState.selectedCities];
      const index = cities.findIndex(city => city.id === cityId);
      if (index > -1) {
        cities.splice(index, 1);
      } else {
        cities.push({ id: cityId, name: cityName });
      }
      return { selectedCities: cities };
    });
  };

  applyFilters = () => {
    const { selectedDate, selectedFilter, selectedCities } = this.state;
    // Update the query parameters
    this.setState({
      currentPage: 1
    }, () => {
      this.getStylistsStat();
      this.getCityWiseMaleFeMale();
    });
  };

  toggleDatePicker = () => {
    this.setState(prevState => ({
      isDatePickerOpen: !prevState.isDatePickerOpen
    }));
  };

  handleDropdownToggle = (isOpen) => {
    this.setState({ show: isOpen });
  };

  render() {
    const {
      token,
      stylistResults,
      currentPage,
      itemsPerPage,
      totalItems,
      loading,
      results,
      selectedDate,
      selectedFilter,
      selectedCities,
      isDatePickerOpen,
      stylistStatus,
      show
    } = this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = stylistResults.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages =
      totalItems > 0 ? Math.ceil(totalItems / itemsPerPage) : 1;

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner ">
            <div className=" mb-4">
              <div className="card-body dashboard-card-header">
                <div className="d-flex align-items-center">
                  <h4 className=" mb-0">Dashboard</h4>
                  <div className="ms-auto">
                    <div className="dropdown d-inline-block me-2">
                      <button
                        className="btn btn-light dropdown-toggle"
                        type="button"
                        onClick={this.toggleDatePicker}
                      >
                        <img
                          className="me-2"
                          src="assets/img/icon-calendar.svg"
                          alt=""
                        />
                        {selectedDate ? selectedDate.toLocaleDateString() : 'Select Date'}
                      </button>
                      {isDatePickerOpen && (
                        <div
                          className="dropdown-menu show p-2"
                          style={{
                            position: 'absolute',
                            willChange: 'transform',
                            top: '100%',
                            left: '0',
                            transform: 'translate3d(0px, 0px, 0px)'
                          }}
                        >
                          <DatePicker
                            selected={selectedDate}
                            onChange={this.handleDateChange}
                            inline
                            className="border-0"
                            onClickOutside={() => this.setState({ isDatePickerOpen: false })}
                            onMonthChange={() => { }}
                            onYearChange={() => { }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="dropdown d-inline-block">
                      <button
                        className="btn btn-light dropdown-toggle"
                        type="button"
                        id="filterDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ textTransform: 'capitalize' }}
                      >
                        <img
                          className="me-2"
                          src="assets/img/icon-filter.svg"
                          alt=""
                        />
                        {selectedFilter ? selectedFilter : "Filter"}
                      </button>
                      <div className="dropdown-menu p-3" aria-labelledby="filterDropdown">
                        <h6 className="dropdown-header">Stylist Filter</h6>
                        <div className="mb-3">
                          <select
                            className="form-select"
                            value={selectedFilter}
                            onChange={(e) => this.handleFilterChange(e.target.value)}
                          >
                            <option value="all">All Stylists</option>
                            <option value="expert">Experts</option>
                            <option value="onboarding">Onboarding</option>
                          </select>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="text-center">
                          <button
                            className="apply-btn-small "
                            onClick={() => {
                              this.applyFilters();
                              // Close dropdown
                              document.getElementById('filterDropdown').click();
                            }}
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center pb-20">
                  <h4 className="card-title mb-0">City Statistics</h4>
                  <div className="ms-auto">
                    <button onClick={this.getStylistsStat} type="button" className="apply-btn">
                      Apply
                    </button>
                  </div>
                </div>

                {loading && (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <div className="d-flex all-div">
                  {results.length > 0 ? (
                    results.map((cityStat, index) => (
                      <div className="cities" key={index}>
                        <table className="w-100">
                          <tbody  >
                            <tr>
                              <td>
                                <h2>{cityStat.city}</h2>
                              </td>
                              <td>
                                <label className="custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={selectedCities.some(city => city.id === cityStat.city_id)}
                                    onChange={() => this.handleCitySelection(cityStat.city_id, cityStat.city)}
                                  />
                                  <span></span>
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Male Stylist</p>
                              </td>
                              <td>
                                <p>{cityStat.maleStylistCount || 0}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Female Stylist</p>
                              </td>
                              <td>
                                <p>{cityStat.femaleStylistCount || 0}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Total Stylist</p>
                              </td>
                              <td>
                                <p>{cityStat.totalStylistCount || 0}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))
                  ) : (
                    <p>No data available</p>
                  )}
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Top Stylist</h4>
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      <div className="legend mt-2">
                        <span className="legend-item">
                          <span className="status-dot active"></span> Active
                        </span>
                        <span className="legend-item ms-3">
                          <span className="status-dot inactive"></span> Inactive
                        </span>
                      </div>

                      <div className="legend mt-2">
                        <span className="legend-item">
                          <span style={{ fontSize: "12px", fontWeight: "bold" }} className="">( M )</span> - Male
                        </span>
                        <span className="legend-item ms-3">
                          <span style={{ fontSize: "12px", fontWeight: "bold" }} className="">( F )</span> - Female
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="dropdown d-inline-block">
                    <button
                      className="btn btn-light dropdown-toggle"
                      type="button"
                      id="filterDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        className="me-2"
                        src="assets/img/icon-filter.svg"
                        alt=""
                      />
                      {stylistStatus ? stylistStatus : "Filter"}
                    </button> <button
                      className="apply-btn"
                      onClick={this.exportToExcel}
                      disabled={!stylistResults.length}
                    >
                      <i className="fas fa-file-excel me-2"></i>
                      Export to Excel
                    </button>
                    <div className="dropdown-menu p-3" aria-labelledby="filterDropdown">
                      <h6 className="dropdown-header">Stylist Filter</h6>
                      <div className="mb-3">
                        <select
                          className="form-select"
                          value={stylistStatus}
                          onChange={(e) => this.handleStatusChange(e.target.value)}
                        >
                          <option value="all">All</option>
                          <option value="Active"> Active </option>
                          <option value="In-Active">In-Active</option>
                        </select>
                      </div>

                      <div className="dropdown-divider"></div>
                      <div className="text-center">
                        <button
                          className="apply-btn-small "
                          onClick={() => {
                            this.applyFilters();
                            // Close dropdown
                            document.getElementById('filterDropdown').click();
                          }}
                        >
                          Apply Filters
                        </button>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="card-body p-0">
                <div className="table-responsive" style={{ overflowX: "auto" }}>
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Stylist ID</th>
                        <th>Stylist Name</th>
                        <th>Joining Date</th>
                        <th>At Salon Booking</th>
                        <th>Star Rating</th>
                        <th>No. Of Review</th>
                        <th>Offer Created</th>
                        <th>Offer Booked</th>
                        <th>Package Created</th>
                        <th>Package Booked</th>
                        <th>Campaign Created</th>
                        <th>Campaign Booked</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stylistResults.length === 0 ? (
                        <tr>
                          <td colSpan="14" className="text-center">
                            No stylist data available
                          </td>
                        </tr>
                      ) : (
                        stylistResults.map((stylist, index) => (
                          <tr
                            key={index}
                          >
                            <td>{index + 1}</td>
                            <td>{stylist?.stylistId ? stylist?.stylistId : "N/A"}</td>
                            <td className={stylist.status === "Active" ? "active-row" : "inactive-row"} >{stylist.stylistName || "N/A"} <span style={{ fontSize: "9px", color: "#6c757d" }}>({stylist.gender === "Male" ? "M" : "F"})</span> </td>
                            <td>
                              {stylist.joiningDate
                                ? new Date(stylist.joiningDate).toLocaleDateString()
                                : "N/A"}
                            </td>

                            <td>{stylist.atSalonBooking}</td>
                            <td>{stylist.starRating}</td>
                            <td>{stylist.numberOfReviews}</td>
                            <td>{stylist.offerCreated}</td>
                            <td>{stylist.offerBooked}</td>
                            <td>{stylist.packageCreated}</td>
                            <td>{stylist.packageBooked}</td>
                            <td>{stylist.campaignCreated}</td>
                            <td>{stylist.campaignBooked}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>

                  <div className="d-flex align-items-center justify-content-end p-3">
                    {currentPage > 1 && (
                      <button
                        className="pagination-prev me-2"
                        onClick={(e) =>
                          this.handlePageChange(currentPage - 1, e)
                        }
                      >
                        <i className="fa-solid fa-angle-left"></i>
                      </button>
                    )}

                    <ul className="pagination m-0">
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${currentPage === index + 1 ? "active" : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={(e) => this.handlePageChange(index + 1, e)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>

                    {currentPage < totalPages && (
                      <button
                        className="pagination-next"
                        onClick={(e) =>
                          this.handlePageChange(currentPage + 1, e)
                        }
                      >
                        <i className="fa-solid fa-angle-right"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

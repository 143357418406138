import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { appConfig } from "../../config";

const { mainDomain } = appConfig;


class Availability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      loading: false,
      timeSlots: [],
      working_hours: {
        sunday: { open: "yes", from: "09:00 AM", to: "10:00 PM", timeSlots: [] },
        monday: { open: "yes", from: "09:00 AM", to: "10:00 PM", timeSlots: [] },
        tuesday: { open: "no", from: "", to: "", timeSlots: [] },
        wednesday: { open: "yes", from: "09:00 AM", to: "10:00 PM", timeSlots: [] },
        thursday: { open: "yes", from: "09:00 AM", to: "10:00 PM", timeSlots: [] },
        friday: { open: "yes", from: "09:00 AM", to: "10:00 PM", timeSlots: [] },
        saturday: { open: "yes", from: "09:00 AM", to: "10:00 PM", timeSlots: [] },
      },
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.setState({ _id: userid }); // Set ID immediately
    Promise.all([
      this.fetchUserData(userid),
      this.fetchTimeSlots()
    ]);
  }
  
  fetchTimeSlots = async () => {
    const token = getLocalStorageItem("token");
    const { GetAllTimeSlots } = adminendPoints;
    try {
      const response = await fetch(`${mainDomain}/${GetAllTimeSlots}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      const data = await response.json();
      this.setState({ timeSlots: data }, () => {
        // After setting timeSlots, update working hours with default slots
        this.updateWorkingHoursWithSlots();
      });
    } catch (error) {
      console.error("Error fetching time slots:", error);
    }
  }

  fetchUserData = async (userid) => {
    this.setState({ loading: true });
    const token = getLocalStorageItem("token");
    const { AdminExpertUserDetails } = adminendPoints;

    try {
      const response = await APICaller(
        AdminExpertUserDetails,
        "POST",
        { token, userid },
        "application/json"
      );
      const { status, user } = response.data;

      if (status === 200 && user) {
        let formattedWorkingHours = { ...this.state.working_hours };
        
        if (user.working_hours && user.working_hours.length > 0) {
          const workingHoursData = user.working_hours[0];
          
          Object.keys(workingHoursData).forEach(day => {
            if (formattedWorkingHours[day]) {
              formattedWorkingHours[day] = {
                open: workingHoursData[day].open.toLowerCase(),
                from: workingHoursData[day].from,
                to: workingHoursData[day].to,
                timeSlots: formattedWorkingHours[day].timeSlots // Preserve existing timeSlots
              };
            }
          });
        }
        
        this.setState({
          loading: false,
          token,
          working_hours: formattedWorkingHours
        });
      } else {
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  handleWorkingHoursChange = (day, field, value) => {
    this.setState((prevState) => {
      const updatedDay = {
        ...prevState.working_hours[day],
        [field]: value,
      };

      if ((field === 'from' || field === 'to') && updatedDay.open === 'yes') {
        const fromTime = field === 'from' ? value : updatedDay.from;
        const toTime = field === 'to' ? value : updatedDay.to;

        if (fromTime && toTime) {
          const from24h = this.convertTo24Hour(fromTime);
          const to24h = this.convertTo24Hour(toTime);
          
          if (from24h >= to24h) {
            if (field === 'to') return prevState;
            if (field === 'from') {
              updatedDay.to = '';
              updatedDay.timeSlots = [];
            }
          } else {
            // Update time slots when times are valid
            const daySlots = this.state.timeSlots.filter(slot => {
              return slot.time >= from24h && slot.time <= to24h;
            });
            updatedDay.timeSlots = daySlots.map(slot => slot._id);
          }
        }
      }

      // Clear time slots if closed
      if (field === 'open' && value === 'no') {
        updatedDay.timeSlots = [];
      }

      return {
        working_hours: {
          ...prevState.working_hours,
          [day]: updatedDay,
        },
      };
    });
  };

  // Move time conversion functions to class methods
  convertTo24Hour = (time12h) => {
    if (!time12h) return '';
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    
    if (hours === '12') {
      hours = '00';
    }
    
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    
    return `${hours}:${minutes}`;
  };

  convertTo12Hour = (time24h) => {
    if (!time24h) return '';
    let [hours, minutes] = time24h.split(':');
    hours = parseInt(hours);
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    
    return `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const { token, working_hours, _id } = this.state;
    const { AdminUpdateShopInformation } = adminendPoints;

    // Prepare working hours data with time slots
    const workingHoursData = Object.keys(working_hours).reduce((acc, day) => {
      acc[day] = {
        ...working_hours[day],
        timeSlots: working_hours[day].timeSlots || []
      };
      return acc;
    }, {});

    try {
      const response = await APICaller(
        AdminUpdateShopInformation,
        "POST",
        { id: _id, token, working_hours: workingHoursData },
        "application/json"
      );

      const { status } = response;

      if (status === 200) {
        this.setState({
          successMsg: "Working hours updated successfully.",
          errorMsg: "",
        });
        setTimeout(
          () =>
            this.setState({ successMsg: "", redirect: true, loading: false }),
          5000
        );
      } else {
        this.setState({
          errorMsg: "Error saving working hours.",
          loading: false,
        });
        setTimeout(() => this.setState({ errorMsg: "" }), 3000);
      }
    } catch (error) {
      console.log("Error making data call:", error);
      this.setState({ errorMsg: "Error making data call.", loading: false });
      setTimeout(() => this.setState({ errorMsg: "" }), 3000);
    }
  };

  // Generate time slots in 15-minute intervals in 24-hour format
  generateTimeSlots = () => {
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
        const time24h = `${formattedHour}:${formattedMinute}`;
        slots.push(time24h);
      }
    }
    return slots;
  };

  // Convert 12-hour format to 24-hour for dropdown selection
  getSelectedTime24h = (time12h) => {
    if (!time12h) return '';
    return this.convertTo24Hour(time12h);
  };

  // Convert 24-hour format to 12-hour for state storage
  handleTimeSelection = (day, field, time24h) => {
    const time12h = this.convertTo12Hour(time24h);
    this.handleWorkingHoursChange(day, field, time12h);
  };

  // New method to update working hours with time slots
  updateWorkingHoursWithSlots = () => {
    const { timeSlots, working_hours } = this.state;
    const updatedWorkingHours = { ...working_hours };

    Object.keys(updatedWorkingHours).forEach(day => {
      if (updatedWorkingHours[day].open === "yes") {
        const fromTime = this.convertTo24Hour(updatedWorkingHours[day].from);
        const toTime = this.convertTo24Hour(updatedWorkingHours[day].to);
        
        // Filter time slots that fall within the working hours
        const daySlots = timeSlots.filter(slot => {
          return slot.time >= fromTime && slot.time <= toTime;
        });

        // Store the slot IDs
        updatedWorkingHours[day].timeSlots = daySlots.map(slot => slot._id);
      }
    });

    this.setState({ working_hours: updatedWorkingHours });
  }

  renderWorkingHours = () => {
    const { working_hours } = this.state;
    const daysOfWeek = Object.keys(working_hours);
    const timeSlots = this.generateTimeSlots();
    
    const styles = {
      dayContainer: {
        background: '#ffffff',
        borderRadius: '10px',
        padding: '20px',
        margin: '10px 0',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease'
      },
      dayHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        padding: '5px 0',
        borderBottom: '2px solid #f0f0f0'
      },
      dayLabel: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#333',
        marginLeft: '10px'
      },
      checkbox: {
        width: '18px',
        height: '18px',
        cursor: 'pointer'
      },
      timeContainer: {
        display: 'flex',
        gap: '15px',
        marginTop: '10px'
      },
      timeGroup: {
        flex: 1
      },
      timeLabel: {
        display: 'block',
        fontSize: '14px',
        color: '#666',
        marginBottom: '5px'
      },
      selectInput: {
        width: '100%',
        padding: '8px 12px',
        border: '1px solid #ddd',
        borderRadius: '6px',
        fontSize: '14px',
        color: '#333',
        outline: 'none',
        transition: 'border-color 0.2s ease',
        appearance: 'menulist',
        backgroundColor: '#fff',
      },
      closedText: {
        color: '#999',
        fontStyle: 'italic',
        marginTop: '10px'
      }
    };

    return daysOfWeek.map((day) => {
      const { open, from, to } = working_hours[day];
      const isInvalidTimeRange = open === 'yes' && from && to && 
        this.convertTo24Hour(from) >= this.convertTo24Hour(to);

      return (
        <div key={day} className="col-lg-6">
          <div style={styles.dayContainer}>
            <div style={styles.dayHeader}>
              <input
                type="checkbox"
                id={`open-${day}`}
                checked={open === "yes"}
                onChange={(e) =>
                  this.handleWorkingHoursChange(
                    day,
                    "open",
                    e.target.checked ? "yes" : "no"
                  )
                }
                style={styles.checkbox}
              />
              <label 
                htmlFor={`open-${day}`}
                style={styles.dayLabel}
              >
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </label>
            </div>

            {open === "yes" ? (
              <div style={styles.timeContainer}>
                <div style={styles.timeGroup}>
                  <label 
                    htmlFor={`from-${day}`}
                    style={styles.timeLabel}
                  >
                    From
                  </label>
                  <select
                    id={`from-${day}`}
                    value={this.getSelectedTime24h(from)}
                    onChange={(e) =>
                      this.handleTimeSelection(day, "from", e.target.value)
                    }
                    style={{
                      ...styles.selectInput,
                      borderColor: from === "" ? '#dc3545' : '#ddd'
                    }}
                  >
                    <option value="">Select start time</option>
                    {timeSlots.map(slot => (
                      <option key={`from-${day}-${slot}`} value={slot}>
                        {slot}
                      </option>
                    ))}
                  </select>
                  {from === "" && (
                    <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '5px' }}>
                      Please select a start time.
                    </div>
                  )}
                </div>

                <div style={styles.timeGroup}>
                  <label 
                    htmlFor={`to-${day}`}
                    style={styles.timeLabel}
                  >
                    To
                  </label>
                  <select
                    id={`to-${day}`}
                    value={this.getSelectedTime24h(to)}
                    onChange={(e) =>
                      this.handleTimeSelection(day, "to", e.target.value)
                    }
                    style={{
                      ...styles.selectInput,
                      borderColor: (to === "" || isInvalidTimeRange) ? '#dc3545' : '#ddd'
                    }}
                  >
                    <option value="">Select end time</option>
                    {timeSlots.map(slot => (
                      <option key={`to-${day}-${slot}`} value={slot}>
                        {slot}
                      </option>
                    ))}
                  </select>
                  {(to === "" || isInvalidTimeRange) && (
                    <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '5px' }}>
                      {isInvalidTimeRange ? 
                        "End time must be later than start time." : 
                        "Please select an end time."}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div style={styles.closedText}>
                Closed
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  render() {
    const { redirect, successMsg, errorMsg, loading, _id } = this.state;

    const styles = {
      container: {
        padding: '30px',
        background: '#f8f9fa'
      },
      title: {
        color: '#2c3e50',
        marginBottom: '20px'
      },
      submitButton: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '12px 24px',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '500',
        transition: 'background-color 0.3s ease',
        marginTop: '20px'
      },
      alert: {
        padding: '12px 20px',
        borderRadius: '6px',
        marginBottom: '20px'
      },
      successAlert: {
        backgroundColor: '#d4edda',
        color: '#155724',
        border: '1px solid #c3e6cb'
      },
      errorAlert: {
        backgroundColor: '#f8d7da',
        color: '#721c24',
        border: '1px solid #f5c6cb'
      }
    };

    // if (redirect) {
    //   return <Navigate to={`/EditExpert/${_id}`} />;
    // }
    
    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4" style={styles.container}>
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0" style={styles.title}>Update Working Hours</h4>
                </div>
                <div className="ms-auto">
                  <Link 
                    to={`/EditExpert/${_id}`} 
                    className="btn-light1 ms-2"
                    style={{
                      textDecoration: 'none',
                      padding: '8px 16px',
                      borderRadius: '4px'
                    }}
                  >
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form
                className="form-area1 row g-4"
                onSubmit={this.handleFormSubmit}
              >
                {errorMsg && (
                  <div style={{...styles.alert, ...styles.errorAlert}} role="alert">
                    {errorMsg}
                  </div>
                )}

                {successMsg && (
                  <div style={{...styles.alert, ...styles.successAlert}} role="alert">
                    {successMsg}
                  </div>
                )}

                {loading && (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                {this.renderWorkingHours()}

                <div className="col-12">
                  <button
                    type="submit"
                    style={{
                      ...styles.submitButton,
                      opacity: loading ? 0.7 : 1,
                      cursor: loading ? 'not-allowed' : 'pointer'
                    }}
                    disabled={loading}
                  >
                    Update Working Hours
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Availability;

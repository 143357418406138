import React, { useState, useEffect } from 'react';
import APICaller from "../../utils/apicaller";
import { adminendPoints } from "../../config";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { useNavigate, useSearchParams } from 'react-router-dom';

const EditBusinessImage = () => {
    const [services, setServices] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [removedImages, setRemovedImages] = useState({});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    useEffect(() => {
        // Get services from localStorage
        const servicesData = JSON.parse(localStorage.getItem('servicesAssigns') || '[]');
        setServices(servicesData);
    }, []);

    const handleFileChange = (serviceId, files) => {
        setSelectedFiles(prev => ({
            ...prev,
            [serviceId]: Array.from(files)
        }));
    };

    const removeSelectedImage = (serviceId, indexToRemove) => {
        setSelectedFiles(prev => ({
            ...prev,
            [serviceId]: prev[serviceId].filter((_, index) => index !== indexToRemove)
        }));
    };

    const removeExistingImage = (serviceId, fileName) => {
        setRemovedImages(prev => ({
            ...prev,
            [serviceId]: [...(prev[serviceId] || []), fileName]
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const formData = new FormData();

            // Add files for each service
            Object.entries(selectedFiles).forEach(([serviceId, files]) => {
                files.forEach(file => {
                    formData.append(`files_${serviceId}`, file);
                });
            });

            // Add removed images information
            const removedImagesData = Object.entries(removedImages).map(([serviceId, fileNames]) => ({
                serviceId,
                fileNames
            }));
            formData.append('removedImages', JSON.stringify(removedImagesData));

            const token = getLocalStorageItem("myStylistAdmin")?.token;

            const response = await APICaller(
                adminendPoints.AdminUpdateBusinessImages,
                "POST",
                formData,
                "multipart/form-data",
                {}
            ).then((res)=>{
                setSuccess(true);
                navigate(`/EditExpert/${id}`);
            }).catch((err)=>{
                setError(true);
            });

           
        } catch (error) {
            console.error('Error updating images:', error);
            alert('Failed to update images');
        } finally {
            setLoading(false);
        }
    };

    // Check if there are any changes to submit
    const hasChanges = Object.keys(selectedFiles).length > 0 || 
                      Object.keys(removedImages).length > 0;

    return (
        <div className="main d-flex custom-cat-list">
            <SideBar />
            <div className="main-right-content flex-fill">
                <Header />
                <div className="main-right-content-inner">
                    <div className="card mb-4">
                        <div className="card-body pb-0">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="left-head">
                                    <h1>Edit Business Images</h1>
                                </div>
                                {hasChanges && (
                                    <button
                                        className="btn-blue ms-2"
                                        onClick={handleSubmit}
                                        disabled={loading}
                                    >
                                        {loading ? 'Updating All Services...' : 'Update All Services'}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {error && (
                  <div className="alert alert-danger" role="alert">
                    Failed to update images
                  </div>
                )}
                {success && (
                  <div className="alert alert-success" role="alert">
                    Images updated successfully
                  </div>
                )}

                    {loading && (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    {services.map((service) => (
                                        <div key={service._id} className="mb-4 border-bottom pb-4">
                                            <h5 className="card-title">{service.service_name} - {service.sub_service_name}</h5>

                                            {/* Display existing images */}
                                            <div className="mb-3">
                                                <h6>Current Images:</h6>
                                                <div className="images-bus">
                                                    {service.fileName && service.fileName.length > 0 ? (
                                                        service.fileName.map((file, index) => (
                                                            !removedImages[service._id]?.includes(file) && (
                                                                <div style={{width:"300px",height:"120px",borderRadius:"10px"}} key={index} className="position-relative d-inline-block me-2">
                                                                    <img
                                                                        src={`${process.env.REACT_APP_PROD_BUCKET_URL}/${file.image}`}
                                                                        alt={`Service ${index + 1}`}
                                                                        className=" mb-3"
                                                                        style={{width:"100%",height:"100%",objectFit:"cover",borderRadius:"10px"}}
                                                                    />
                                                                    <button
                                                                        className="btn btn-danger btn-sm position-absolute top-0 end-0"
                                                                        onClick={() => removeExistingImage(service._id, file)}
                                                                        style={{ borderRadius: '50%', padding: '0.25rem 0.65rem' }}
                                                                    >
                                                                        ×
                                                                    </button>
                                                                </div>
                                                            )
                                                        ))
                                                    ) : (
                                                        <p>No existing images</p>
                                                    )}
                                                </div>
                                            </div>

                                            {/* Upload new images */}
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Upload New Images:</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    multiple
                                                    accept="image/*"
                                                    onChange={(e) => handleFileChange(service._id, e.target.files)}
                                                />
                                            </div>

                                            {/* Preview new selected images */}
                                            {selectedFiles[service._id] && (
                                                <div className="mb-3">
                                                    <h6>Selected New Images:</h6>
                                                    <div className="images-bus">
                                                        {Array.from(selectedFiles[service._id]).map((file, index) => (
                                                            <div style={{ height: "fit-content", width: "300px", borderRadius: "10px",position:"relative" }} key={index} className="  ">
                                                                <img
                                                                    src={URL.createObjectURL(file)}
                                                                    alt={`Preview ${index + 1}`}
                                                                    className=" "
                                                                    style={{ height: "250px", width: "100%", borderRadius: "10px", objectFit: "cover" }}
                                                                />
                                                                <button
                                                                    className="btn btn-danger btn-sm position-absolute top-0 end-0"
                                                                    onClick={() => removeSelectedImage(service._id, index)}
                                                                    style={{ borderRadius: '50%', padding: '0.25rem 0.6rem' }}
                                                                >
                                                                    ×
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditBusinessImage;

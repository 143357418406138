import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";

class Settings extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    var retrievedObjectPermission = getLocalStorageItem(
      "myStylistAdminPermission"
    );

    this.state = {
      id: retrievedObject ? retrievedObject.id : "",
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      emailErrorMsg: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      sort: "",
      loading: true,
      search: "",
      permissions: retrievedObjectPermission,
    };
  }

  componentDidMount() {
    // const { id, token } = this.state;
    // if (id) {
    //   const fetchPermissionsData = async () => {
    //     try {
    //       const permissions = await fetchPermissions(token, id);
    //       this.setState({ permissions });
    //     } catch (error) {
    //       console.error("Error fetching permissions:", error);
    //     }
    //   };
    //   fetchPermissionsData();
    // }
    this.getSettingsResults();
  }

  getSettingsResults = () => {
    const { searchState, token, currentPage, itemsPerPage } = this.state;
    const { AdminSettingList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    if (searchState) {
      queryParams += `&state_name=${searchState}`;
    }

    const url = `${AdminSettingList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, settings, totalItems } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            results: settings,
            totalItems: totalItems,
            loading: false,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
              loading: false,
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
        this.setState({
          loading: false,
        });
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getSettingsResults();
    });
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  render() {
    const {
      search,
      results,
      token,
      currentPage,
      itemsPerPage,
      sort,
      loading,
      permissions,
    } = this.state;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredAndSortedItems = Array.isArray(results)
      ? [...results]
          .filter((user) => {
            const matchesSearch =
              user.settings.platform_fees
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              user.settings.visit_charges
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              user.settings.taxes
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase());

            return matchesSearch;
          })
          .sort((a, b) => {
            return sort === "asc"
              ? a._id.localeCompare(b._id)
              : b._id.localeCompare(a._id);
          })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage Settings
                      </li>
                    </ol>
                    <h4 className="card-title mb-0">Manage Settings</h4>
                  </div>
                </div>
              </div>
            </div>
            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sort}
                          onChange={(e) =>
                            this.setState({ sort: e.target.value })
                          }
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <table className="table align-middle mb-4">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Platform Fees</th>
                      <th>Visit Charges</th>
                      <th>Taxes</th>
                      <th>Updated Date</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length === 0 ? (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No settings available for the selected categories.
                        </td>
                      </tr>
                    ) : (
                      currentItems.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}.</td>
                            <td>₹{item.settings.platform_fees}</td>
                            <td>₹{item.settings.visit_charges}</td>
                            <td>{item.settings.taxes}%</td>
                            <td>{formatDate(item.updatedAt)}</td>
                            {permissions?.adminSettings?.edit && (
                              <td className="text-center">
                                <a
                                  href={`EditSetting/${item._id}`}
                                  className="icon-btn"
                                >
                                  <img
                                    src="assets/img/icon-edit.svg"
                                    alt=""
                                    title=""
                                  />
                                </a>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}

                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={(e) => this.handlePageChange(index + 1, e)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>

                  {currentPage < totalPages && (
                    <button
                      className="pagination-next"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;

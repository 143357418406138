import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { getLocalStorageItem } from "../../store/localStorage";
import { fetchPermissions } from "../../utils/commonFunction";
import { setLocalStorageItem } from "../../store/localStorage";

class SideBar extends Component {
  constructor(props) {
    super(props);

    const retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      id: retrievedObject ? retrievedObject.id : "",
      token: retrievedObject ? retrievedObject.token : "",
      permissions: [],
      loading: false,
      errorMsg: "",
    };
  }

  async componentDidMount() {
    const { id, token } = this.state;
    if (id) {
      try {
        const permissions = await fetchPermissions(token, id);
        console.log(permissions, "permissions");
        this.setState({ permissions });
        setLocalStorageItem("myStylistAdminPermission", permissions);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    }
  }

  render() {
    const { permissions } = this.state;

    const splitLocation = window.location.pathname.split("/");

    const isDashboardVisible = permissions?.dashboard?.isMenuVisible || permissions?.dashboard?.view;
    const isMenuManagementVisible = permissions?.menuManagement?.isMenuVisible || permissions?.menuManagement?.view;
    const isStylistManagementVisible = permissions?.stylistsManagement?.isMenuVisible || permissions?.stylistsManagement?.view;
    const isOffersVisible = permissions?.offer?.isMenuVisible || permissions?.offer?.view;
    const isPackagesVisible = permissions?.package?.isMenuVisible || permissions?.package?.view;
    const isCampaignVisible = permissions?.campaign?.isMenuVisible || permissions?.campaign?.view;
    const isUserNotificationVisible = permissions?.userPushNotification?.isMenuVisible || permissions?.userPushNotification?.view;
    const isBulkNotificationVisible = permissions?.stylistPushNotification?.isMenuVisible || permissions?.stylistPushNotification?.view;
    const isCmsVisible = permissions?.cms?.isMenuVisible || permissions?.cms?.view;
    const isFaqVisible = permissions?.faqs?.isMenuVisible || permissions?.faqs?.view;
    const isBannersVisible = (permissions?.customerBanners?.isMenuVisible || permissions?.customerBanners?.view) ||
                            (permissions?.stylistAppBanners?.isMenuVisible || permissions?.stylistAppBanners?.view);
    const isCityVisible = permissions?.city?.isMenuVisible || permissions?.city?.view;
    const isAdminUserManagementVisible = permissions?.adminManagement?.isMenuVisible || permissions?.adminManagement?.view;
    const isAdminOnboardingUsersVisible = permissions?.onboardingUsers?.isMenuVisible || permissions?.onboardingUsers?.view;
    const isAdminSettingVisible = permissions?.managePayment?.isMenuVisible || permissions?.managePayment?.view;
    const isUserManagementVisible = permissions?.userManagement?.isMenuVisible || permissions?.userManagement?.view;
    const isServiceManagementVisible = permissions?.paymentManagement?.isMenuVisible || permissions?.paymentManagement?.view;

    return (
      <aside className="main-left-sidebar">
        <div className="logo mb-2">
          <Link to="#">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo.svg"}
              alt="Logo"
              title=""
            />
          </Link>
        </div>
        <div className="left-menu-main flex-fill">
          <h6 className="menu-title">Main</h6>
          <ul className="left-menu">
            {isDashboardVisible && (
              <NavItem
                to="/Home"
                icon="assets/img/icon-dashboard.svg"
                text="Dashboard"
                isActive={
                  splitLocation[1] === "" || splitLocation[1] === "Home"
                }
              />
            )}
            {isAdminUserManagementVisible && (
              <NavItem
                to="/ManageAdminUsers"
                icon="assets/img/icon-services-management.svg"
                text="Admin Users"
                isActive={
                  splitLocation[1] === "ManageAdminUsers" ||
                  splitLocation[1] === "EditAdminUser" ||
                  splitLocation[1] === "ViewPermission"
                }
              />
            )}
            {isAdminSettingVisible && (
              <NavItem
                to="/ManageSettings"
                icon="assets/img/icon-services-management.svg"
                text="Manage Payment"
                isActive={splitLocation[1] === "ManageSettings"}
              />
            )}
            {isAdminOnboardingUsersVisible && (
              <NavItem
                to="/Onboarding"
                icon="assets/img/icon-services-management.svg"
                text="Onboarding Users"
                isActive={splitLocation[1] === "Onboarding"}
              />
            )}
            {isUserManagementVisible && (
              <NavItem
                to="/user-management"
                icon="assets/img/icon-services-management.svg"
                text="User Management"
                isActive={splitLocation[1] === "user-management"}
              />
            )}
            {isServiceManagementVisible && (
              <NavItem
                to="/payment-management"
                icon="assets/img/icon-services-management.svg"
                text="Payment Management"
                isActive={splitLocation[1] === "payment-management"}
              />
            )}
            {isStylistManagementVisible && (
              <NavItem
                to="/ManageExperts"
                icon="assets/img/icon-stylists-management.svg"
                text="Stylists Management"
                isActive={
                  splitLocation[1] === "ManageExperts" ||
                  splitLocation[1] === "EditExpert" ||
                  splitLocation[1] === "updateLocation" ||
                  splitLocation[1] === "updateProfile" ||
                  splitLocation[1] === "amenities" ||
                  splitLocation[1] === "AssignServices" ||
                  splitLocation[1] === "EditAssignServices" ||
                  splitLocation[1] === "UploadUserImage" ||
                  splitLocation[1] === "availability" ||
                  splitLocation[1] === "edit-business-image"
                }
              />
            )}
            {isMenuManagementVisible && (
              <NavItem
                to="/ManageServices"
                icon="assets/img/icon-services-management.svg"
                text="Menu Management"
                isActive={
                  splitLocation[1] === "ManageServices" ||
                  splitLocation[1] === "editServices" ||
                  splitLocation[1] === "editSubServices"
                }
              />
            )}
            <NavItem
              to="/time-management"
              icon="assets/img/icon-services-management.svg"
              text="Time Management"
              isActive={
                splitLocation[1] === "time-management"
              }
            />
          </ul>
          <h6 className="menu-title">Business Management</h6>
          <ul className="left-menu">
            {isOffersVisible && (
              <NavItem
                to="/ManageOffers"
                icon="assets/img/icon-offer.svg"
                text="Offer"
                isActive={splitLocation[1] === "ManageOffers"}
              />
            )}
            {isPackagesVisible && (
              <NavItem
                to="/ManagePackages"
                icon="assets/img/icon-package.svg"
                text="Package"
                isActive={splitLocation[1] === "ManagePackages"}
              />
            )}
            {isCampaignVisible && (
              <NavItem
                to="/ManageCampaigns"
                icon="assets/img/icon-campaign.svg"
                text="Campaign"
                isActive={splitLocation[1] === "ManageCampaigns"}
              />
            )}
            {isBannersVisible && (
              <NavItem
                to="/ManageHomeBanners"
                icon="assets/img/icon-user-jobs.svg"
                text="Customer Banners"
                isActive={splitLocation[1] === "ManageHomeBanners"}
              />
            )}
            {isBannersVisible && (
              <NavItem
                to="/ManageStylistBanners"
                icon="assets/img/icon-user-jobs.svg"
                text="Stylist App Banners"
                isActive={splitLocation[1] === "ManageStylistBanners"}
              />
            )}
          </ul>
          <h6 className="menu-title">Notification Management</h6>
          <ul className="left-menu">
            {isUserNotificationVisible && (
              <NavItem
                to="/StylistBulkNotification"
                icon="assets/img/icon-push-notification.svg"
                text="User Push Notification"
                isActive={splitLocation[1] === "StylistBulkNotification"}
              />
            )}
            {isBulkNotificationVisible && (
              <NavItem
                to="/BulkNotification"
                icon="assets/img/icon-push-notification.svg"
                text="Stylist Push Notification"
                isActive={splitLocation[1] === "BulkNotification"}
              />
            )}
          </ul>
          <h6 className="menu-title">Location Management</h6>
          <ul className="left-menu">
            {/* {isStateVisible && (
              <NavItem
                to="/ManageState"
                icon="assets/img/icon-push-notification.svg"
                text="State"
                isActive={splitLocation[1] === "ManageState"}
              />
            )} */}
            {isCityVisible && (
              <NavItem
                to="/ManageCity"
                icon="assets/img/icon-push-notification.svg"
                text="City"
                isActive={splitLocation[1] === "ManageCity"}
              />
            )}
          </ul>

          <h6 className="menu-title">FAQ's Management</h6>
          <ul className="left-menu">
            {isFaqVisible && (
              <NavItem
                to="/ManageFaqs"
                icon="assets/img/icon-push-notification.svg"
                text="FAQ'S"
                isActive={splitLocation[1] === "ManageFaqs"}
              />
            )}
          </ul>

          <h6 className="menu-title">Content Management </h6>
          <ul className="left-menu">
            {isCmsVisible && (
              <NavItem
                to="/CMS"
                icon="assets/img/icon-push-notification.svg"
                text="CMS"
                isActive={splitLocation[1] === "CMS"}
              />
            )}
          </ul>
        </div>
      </aside>
    );
  }
}

const NavItem = ({ to, icon, text, isActive }) => (
  <li className={`nav-item nav-item-custom ${isActive ? "active" : ""}`}>
    <Link to={to}>
      <img src={process.env.PUBLIC_URL + `/${icon}`} alt="" title="" />
      {text}
    </Link>
  </li>
);

export default SideBar;

import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";
import ViewServices from "./viewServices";
import AddServices from "./addServices";
import "../../App.css";

import { withPermissions } from "../context/PermissionsContext";

class ManageServices extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    var retrievedObjectPermission = getLocalStorageItem(
      "myStylistAdminPermission"
    );

    this.state = {
      id: retrievedObject ? retrievedObject.id : "",
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      emailErrorMsg: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      activeTab: "All",
      search: "",
      sort: "",
      isVisibleView: false,
      isVisibleAdd: false,
      successMsg: "",
      errorMsg: "",
      permissions: retrievedObjectPermission,
      loading: true,
      featured_image_url: "https://mystylist-media.s3.amazonaws.com",
    };
  }

  componentDidMount() {
    // const { id, token } = this.state;
    // if (id) {
    //   const fetchPermissionsData = async () => {
    //     try {
    //       const permissions = await fetchPermissions(token, id);
    //       this.setState({ permissions });
    //     } catch (error) {
    //       console.error("Error fetching permissions:", error);
    //     }
    //   };
    //   fetchPermissionsData();
    // }
    this.getServicesResults();
  }

  getServicesResults = () => {
    this.setState({ loading: true });
    const { sort, search, token, currentPage, itemsPerPage, activeTab } =
      this.state;
    const { AdminServicesList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
      ...(search && { search: search.trim() }),
      ...(sort && { service_for: sort }),
      ...(activeTab !== "All" && { status: activeTab }),
    });

    const url = `${AdminServicesList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, categories, totalItems } = response.data;

        if (status === 200) {
          this.setState({
            results: categories,
            totalItems: totalItems,
            loading: false,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
              loading: false,
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
        this.setState({
          loading: false,
        });
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getServicesResults();
    });
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
      currentPage: 1
    }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.getServicesResults();
      }, 500);
    });
  };

  handleFilterChange = (e) => {
    this.setState({
      sort: e.target.value,
      currentPage: 1
    }, () => {
      this.getServicesResults();
    });
  };

  handleCloseViewContent = () => {
    this.setState({ isVisibleView: false });
  };

  viewCategorytoggle = () => {
    this.setState((prevState) => ({
      isVisibleView: !prevState.isVisibleView,
    }));
  };

  handleCloseAddContent = () => {
    this.setState({ isVisibleAdd: false });
  };

  addCategorytoggle = () => {
    this.setState((prevState) => ({
      isVisibleAdd: !prevState.isVisibleAdd,
    }));
  };

  handleTabClick = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, currentPage: 1 }, () => {
        this.getServicesResults();
      });
    }
  };

  handleToggleCheckBox = async (serviceId, currentStatus) => {
    const { token } = this.state;
    const { AdminUpdateStatusService } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      serviceId,
      status: newStatus,
    };

    try {
      const response = await APICaller(
        AdminUpdateStatusService,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((service) =>
            service._id === serviceId
              ? { ...service, status: newStatus }
              : service
          ),
          successMsg: `Service Status is ${newStatus}`,
          errorMsg: "",
        }));
        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
        this.getServicesResults();
      } else {
        console.error("Failed to update faq status");
        this.setState({
          errorMsg: message,
          successMsg: "", // Clear any previous success messages
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "", // Clear any previous success messages
      });
    }
  };

  handleToggleCheckBoxSub = async (subServiceId, currentStatus) => {
    const { token } = this.state;
    const { AdminUpdateStatusSubService } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      subServiceId,
      status: newStatus,
    };

    try {
      const response = await APICaller(
        AdminUpdateStatusSubService,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((subServices) =>
            subServices._id === subServiceId
              ? { ...subServices, status: newStatus }
              : subServices
          ),
          successMsg: `Sub Service Status is ${newStatus}`,
          errorMsg: "",
        }));
        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
        this.getServicesResults();
      } else {
        console.error("Failed to update faq status");
        this.setState({
          errorMsg: message,
          successMsg: "", // Clear any previous success messages
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "", // Clear any previous success messages
      });
    }
  };

  openImageModal = (imageUrl) => {
    this.setState({
      isImageModalOpen: true,
      selectedImageUrl: imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      isImageModalOpen: false,
      selectedImageUrl: "",
    });
  };

  render() {
    const {
      successMsg,
      errorMsg,
      search,
      results,
      token,
      currentPage,
      itemsPerPage,
      activeTab,
      sort,
      isVisibleView,
      isVisibleAdd,
      totalItems,
      featured_image_url,
      loading,
      permissions,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Menu Management
                      </li>
                    </ol>
                    <h4 className="card-title mb-0">Menu Management</h4>
                  </div>
                  {permissions?.menuManagement?.add && (
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="btn-blue ms-2"
                        onClick={this.addCategorytoggle}
                      >
                        <i className="fa-solid fa-plus me-2"></i>Add New Category
                      </button>
                    </div>
                  )}
                </div>
                <div className="tabs1 d-flex">
                  <Link
                    onClick={() => this.handleTabClick("All")}
                    className={activeTab === "All" ? "active" : ""}
                  >
                    All
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("Active")}
                    className={activeTab === "Active" ? "active" : ""}
                  >
                    Active
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("In-Active")}
                    className={activeTab === "In-Active" ? "active" : ""}
                  >
                    In-Active
                  </Link>
                </div>
              </div>
            </div>
            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {successMsg && (
              <div className="alert alert-success" role="alert">
                {successMsg}
              </div>
            )}

            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}
            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sort}
                          onChange={this.handleFilterChange}
                        >
                          <option value="">All</option>
                          <option value="Male">Boys</option>
                          <option value="Female">Girls</option>
                        </select>
                        <i className="fa-solid fa-filter"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {results.length === 0 ? (
                <div className="col-12 text-center">
                  <p>No services available for the selected categories.</p>
                </div>
              ) : (
                results.map((category, index) => (
                  <div key={category._id} className="col-lg-12 mb-4 card_service ">
                    <div className="card category_card_style bg-white h-100">
                      <div className="card-header bg-light d-flex justify-content-between align-items-center">
                        <h4 className="mb-0">{category.service_name}</h4>
                        <div className="d-flex align-items-center">
                          {permissions?.menuManagement?.status && (
                            <div className="form-check form-switch me-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={category.status === "Active"}
                                onChange={() => this.handleToggleCheckBox(category._id, category.status)}
                              />
                            </div>
                          )}
                          <div className="btn-group">
                            <button className="btn btn-sm btn-link" onClick={() => this.openImageModal(featured_image_url + "/" + category.fileName)}>
                              <img src="assets/img/icon-gallery.svg" alt="View" title="View Service Image" />
                            </button>
                            {permissions?.menuManagement?.edit && (
                              <Link to={`/editServices/${category._id}`} className="btn btn-sm btn-link">
                                <img src="assets/img/icon-edit.svg" alt="Edit" title="Edit Service" />
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mb-2">
                          <small className="text-muted">Category For: {category.service_for}</small>
                          <br />
                          <small className="text-muted">Created: {formatDate(category.createdAt)}</small>
                        </div>
                        
                        <h6 className="mt-3 special_title mb-2">Services:</h6>
                        {category.subServices && category.subServices.length > 0 ? (
                          <div className="list-group">
                            {category.subServices.map(subService => (
                              <div key={subService._id} className="list-group-item sub_category_list_style list-group-item-action d-flex justify-content-between align-items-center">
                                <div>
                                  <h5 className="mb-1 text-capitalize text-black">{subService.sub_service_name}</h5>
                                  <small className="text-muted">Service For: {subService.service_for}</small>
                                </div>
                                <div className="d-flex align-items-center">
                                  {permissions?.serviceManagement?.status && (
                                    <div className="form-check form-switch me-2">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={subService.status === "Active"}
                                        onChange={() => this.handleToggleCheckBoxSub(subService._id, subService.status)}
                                      />
                                    </div>
                                  )}
                                  <div className="btn-group">
                                    {/* <button className="btn btn-sm btn-link" onClick={() => this.openImageModal(featured_image_url + "/" + subService.fileName)}>
                                      <img src="assets/img/icon-gallery.svg" alt="View" title="View Image" />
                                    </button> */}
                                    {permissions?.serviceManagement?.view && (
                                      <Link to={`/editSubServices/${subService._id}`} className="btn btn-sm btn-link">
                                        <img src="assets/img/icon-edit.svg" alt="Edit" title="Edit Sub-Service" />
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="text-muted">No sub-services available</p>
                        )}
                        
                        {permissions?.serviceManagement?.add && (
                          <div className="text-center mt-3">
                            <Link to={`/AddSubServices/${category._id}/${category.service_for}`} className="btn btn-light btn-sm">
                              <i className="fa-solid fa-plus me-2"></i>Add Service
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="d-flex align-items-center justify-content-end p-3">
              {currentPage > 1 && (
                <button
                  className="pagination-prev me-2"
                  onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                >
                  <i className="fa-solid fa-angle-left"></i>
                </button>
              )}

              <ul className="pagination m-0">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={(e) => this.handlePageChange(index + 1, e)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>

              {currentPage < totalPages && (
                <button
                  className="pagination-next"
                  onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                >
                  <i className="fa-solid fa-angle-right"></i>
                </button>
              )}
            </div>
          </div>
        </div>

        <ViewServices
          isVisibleView={isVisibleView}
          handleCloseViewContent={this.handleCloseViewContent}
        />

        <AddServices
          isVisibleAdd={isVisibleAdd}
          handleCloseAddContent={this.handleCloseAddContent}
          reloadListing={this.getServicesResults}
        />
      </div>
    );
  }
}

export default withPermissions(ManageServices);

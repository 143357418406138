import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";
import AddAdminUser from "./addAdminUser";
import {usePermissions} from "../../utils/hooks/PermisionsHook";

const ManageAdminUsers = ({ adminDetails }) => {
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const [token] = useState(retrievedObject ? retrievedObject.token : "");
  const [results, setResults] = useState([]);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [activeTab, setActiveTab] = useState("All");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [isVisibleView, setIsVisibleView] = useState(false);
  const [isVisibleAdd, setIsVisibleAdd] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const { hasPermission } = usePermissions();

  useEffect(() => {
    getAdminUsersResults();
  }, []);

  const getAdminUsersResults = () => {
    setLoading(true);
    const { AdminAdminUserList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    if (activeTab !== "All") {
      queryParams += `&status=${activeTab}`;
    }

    const url = `${AdminAdminUserList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, totalItems, adminusers } = response.data;

        if (status === 200) {
          setResults(adminusers);
          setTotalItems(totalItems);
          setLoading(false);
        } else {
          setEmailErrorMsg("Invalid Request");
          setLoading(false);
          setTimeout(() => {
            setEmailErrorMsg("");
          }, 10000);
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
        setLoading(false);
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAdminUsersResults();
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCloseViewContent = () => {
    setIsVisibleView(false);
  };

  const viewCategorytoggle = () => {
    setIsVisibleView(!isVisibleView);
  };

  const handleCloseAddContent = () => {
    setIsVisibleAdd(false);
  };

  const addAdminUsertoggle = () => {
    setIsVisibleAdd(!isVisibleAdd);
  };

  const handleTabClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setCurrentPage(1);
      getAdminUsersResults();
    }
  };

  const handleToggleCheckBox = async (adminId, currentStatus) => {
    const { AdminUpdateStatusUser } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";
    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      adminId,
      adminStatus: newStatus,
    };

    try {
      const response = await APICaller(
        AdminUpdateStatusUser,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        setResults(prevResults =>
          prevResults.map(banner =>
            banner._id === adminId ? { ...banner, status: newStatus } : banner
          )
        );
        setSuccessMsg(`Admin User Status is ${newStatus}`);
        setErrorMsg("");
        setTimeout(() => {
          setSuccessMsg("");
        }, 2000);
        getAdminUsersResults();
      } else {
        setErrorMsg(message);
        setSuccessMsg("");
      }
    } catch (error) {
      setErrorMsg("An error occurred while updating offer status.");
      setSuccessMsg("");
    }
  };

  const openImageModal = (imageUrl) => {
    setIsImageModalOpen(true);
    setSelectedImageUrl(imageUrl);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setSelectedImageUrl("");
  };

  const handleDelete = async () => {
    const { AdminDeleteUser } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const response = await APICaller(
        `${AdminDeleteUser}`,
        method,
        { adminId: selectedUserId },
        contentType,
        token
      );

      if (response.data.status === 200) {
        setSuccessMsg("Admin user deleted successfully");
        setIsDeleteModalOpen(false);
        setSelectedUserId(null);
        getAdminUsersResults();
      } else {
        setErrorMsg(response.data.message || "Failed to delete admin user");
        setIsDeleteModalOpen(false);
        setSelectedUserId(null);
      }
    } catch (error) {
      setErrorMsg("An error occurred while deleting the admin user");
      setIsDeleteModalOpen(false);
      setSelectedUserId(null);
    }
  };

  const openDeleteModal = (userId) => {
    setIsDeleteModalOpen(true);
    setSelectedUserId(userId);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedUserId(null);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredAndSortedItems = Array.isArray(results)
    ? [...results]
        .filter((item) => {
          const matchesName = item.name
            .toLowerCase()
            .includes(search.toLowerCase());
          const matchesType = item.email
            .toLowerCase()
            .includes(search.toLowerCase());

          return matchesName || matchesType;
        })
        .sort((a, b) => {
          return sort === "asc"
            ? a._id.localeCompare(b._id)
            : b._id.localeCompare(a._id);
        })
    : [];

  const currentItems = filteredAndSortedItems.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Manage Admin Users
                    </li>
                  </ol>
                  <h4 className="card-title mb-0">Manage Admin Users</h4>
                </div>
                <div className="ms-auto">
                  {hasPermission("adminUserManagement", "add") && (
                    <button
                      type="button"
                      className="btn-blue ms-2"
                      onClick={addAdminUsertoggle}
                  >
                    <i className="fa-solid fa-plus me-2"></i>Add New
                  </button>
                  )}
                </div>
              </div>
              <div className="tabs1 d-flex">
                <Link
                  onClick={() => handleTabClick("All")}
                  className={activeTab === "All" ? "active" : ""}
                >
                  All
                </Link>
                <Link
                  onClick={() => handleTabClick("Active")}
                  className={activeTab === "Active" ? "active" : ""}
                >
                  Active
                </Link>
                <Link
                  onClick={() => handleTabClick("In-Active")}
                  className={activeTab === "In-Active" ? "active" : ""}
                >
                  In-Active
                </Link>
              </div>
            </div>
          </div>

          {loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {successMsg && (
            <div className="alert alert-success" role="alert">
              {successMsg}
            </div>
          )}

          {errorMsg && (
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
          )}
          <div className="card mb-4">
            <div className="card-body">
              <div className="row align-items-center mb-3">
                <div className="col-lg-5">
                  <div className="form-control-group-icon search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={search}
                      onChange={handleSearchChange}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="form-control-group-icon ms-3">
                      <select
                        className="form-select w-auto"
                        value={sort}
                        onChange={(e) =>
                          setSort(e.target.value)
                        }
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <i class="fa-solid fa-filter"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Created Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAndSortedItems.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No users available for the selected categories.
                      </td>
                    </tr>
                  ) : (
                    filteredAndSortedItems.map((item, index) => {
                      return (
                        <tr key={`category_${index}`}>
                          <td>{index + 1}.</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                          <td>{formatDate(item.createdAt)}</td>
                          <td className="text-center">
                            {item.status && (
                              <div className="form-check form-switch d-inline-block p-0 m-0">
                                <input
                                  disabled={!hasPermission("adminManagement", "status")}
                                  className="form-check-input m-0"
                                  type="checkbox"
                                  role="switch"
                                  checked={item.status === "Active"}
                                  onChange={() =>
                                    handleToggleCheckBox(
                                      item._id,
                                      item.status
                                    )
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td className="text-center d-flex justify-content-center gap-2">
                          
                              <Link
                                to={ hasPermission("adminManagement", "edit") ? `/EditAdminUser/${item._id}` : ""}
                                className="icon-btn"
                                disabled={!hasPermission("adminManagement", "edit")}
                              >
                              <img
                                src="assets/img/icon-edit.svg"
                                alt="View Admin User"
                                title="View Admin User"
                              />
                            </Link>
                          

                            {/* <Link
                              to={`/ViewPermission/${item._id}`}
                              className="icon-btn"
                            >
                              <img
                                src="assets/img/icon-view.svg"
                                alt=""
                                title=""
                              />
                            </Link> */}

                              <button 
                                disabled={!hasPermission("adminManagement", "delete")}
                                className="icon-btn" 
                                onClick={() => {
                                  if (hasPermission("adminManagement", "delete")) {
                                    openDeleteModal(item._id);
                                  }
                                }}
                              >
                              <i className="fa-solid fa-trash-can text-danger"></i>
                            </button>
                           

                            
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
                {isImageModalOpen && (
                  <div
                    className="modal"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-body">
                          <span
                            className="close"
                            onClick={closeImageModal}
                          >
                            &times;
                          </span>
                          <img
                            src={selectedImageUrl}
                            alt="View Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </table>
              <div className="d-flex align-items-center justify-content-end p-3">
                {currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(currentPage - 1, e)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}

                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={(e) => handlePageChange(index + 1, e)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>

                {currentPage < totalPages && (
                  <button
                    className="pagination-next"
                    onClick={(e) => handlePageChange(currentPage + 1, e)}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddAdminUser
        isVisibleAdd={isVisibleAdd}
        handleCloseAddContent={handleCloseAddContent}
        reloadListing={getAdminUsersResults}
      />
      {isDeleteModalOpen && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button type="button" className="btn-close" onClick={closeDeleteModal}></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this admin user?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeDeleteModal}>
                  Cancel
                </button>
                <button type="button" className="btn btn-danger" onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStatetoProps)(ManageAdminUsers);

import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";
import ViewPackages from "./viewPackages";
import { usePermissions } from "../../utils/hooks/PermisionsHook";
const Packages = () => {
  // Initialize retrievedObject outside of state
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const retrievedObjectPermission = getLocalStorageItem("myStylistAdminPermission");

  // Convert state to useState hooks
  const [id] = useState(retrievedObject ? retrievedObject.id : "");
  const [token] = useState(retrievedObject ? retrievedObject.token : "");
  const [results, setResults] = useState([]);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [isVisibleView, setIsVisibleView] = useState(false);
  const [isVisibleAdd, setIsVisibleAdd] = useState(false);
  const [permissions] = useState(retrievedObjectPermission);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { hasPermission } = usePermissions();
  // Convert componentDidMount to useEffect
  useEffect(() => {
    getAllPackage();
  }, []); // Empty dependency array for componentDidMount behavior

  // Convert class methods to regular functions
  const getAllPackage = () => {
    const { AdminPackageList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
      ...(search && { search: search.trim() }),
      ...(sort && { sort }),
    });

    const url = `${AdminPackageList}${queryParams ? `?${queryParams}` : ""}`;

    setLoading(true);

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, packages, totalItems } = response.data;

        if (status === 200) {
          setResults(packages);
          setTotalItems(totalItems);
          setLoading(false);
        } else {
          setEmailErrorMsg("Invalid Request");
          setLoading(false);
          setTimeout(() => {
            setEmailErrorMsg("");
          }, 10000);
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
      });
  };

  // Convert handlePageChange
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllPackage();
  };

  // Convert handleSearchChange with debounce
  let searchDebounce;
  const handleSearchChange = (e) => {
    let value = e.target.value.replace(/\s+/g, " ");
    setSearch(value);

    if (searchDebounce) clearTimeout(searchDebounce);

    if (value.length >= 3 || value.length === 0) {
      searchDebounce = setTimeout(() => {
        setCurrentPage(1);
        getAllPackage();
      }, 300);
    }
  };

  // Convert toggle functions
  const handleCloseViewContent = () => setIsVisibleView(false);
  const viewCategorytoggle = () => setIsVisibleView(prev => !prev);
  const handleCloseAddContent = () => setIsVisibleAdd(false);
  const addCategorytoggle = () => setIsVisibleAdd(prev => !prev);

  // Convert handleToggleCheckBox
  const handleToggleCheckBox = async (packageId, currentStatus) => {
    const { AdminPackageStatusUpdate } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";
    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      packageId,
      status: newStatus,
    };

    try {
      const response = await APICaller(
        AdminPackageStatusUpdate,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        setResults(prevResults =>
          prevResults.map(offer =>
            offer._id === packageId ? { ...offer, status: newStatus } : offer
          )
        );
        setSuccessMsg(`Offer Status is ${newStatus}`);
        setErrorMsg("");

        setTimeout(() => {
          setSuccessMsg("");
        }, 2000);
      } else {
        setErrorMsg(message);
        setSuccessMsg("");
      }
    } catch (error) {
      console.error("Error making data call:", error);
      setErrorMsg("An error occurred while updating offer status.");
      setSuccessMsg("");
    }
  };

  // Convert handleSort
  const handleSort = (sortField) => {
    setSort(sortField);
    setCurrentPage(1);
    getAllPackage();
  };

  // Convert toggleVisibility
  const toggleVisibility = (packageData) => {
    setIsVisibleView(prev => !prev);
    setSelectedPackage(packageData);
  };

  if (token === "") {
    return <Navigate to="/" />;
  }

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      All Packages List
                    </li>
                  </ol>
                  <h4 className="card-title mb-0">All Packages List</h4>
                </div>
                <div className="ms-auto">
                  {/* <button
                    type="button"
                    className="btn-light1 ms-2"
                    onClick={viewCategorytoggle}
                  >
                    <i className="fa-solid fa-file-export me-2"></i>View
                    Package
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          {loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {successMsg && (
            <div className="alert alert-success" role="alert">
              {successMsg}
            </div>
          )}

          {errorMsg && (
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
          )}
          <div className="card mb-4">
            <div className="card-body">
              <div className="row align-items-center mb-3">
                <div className="col-lg-5">
                  <div className="form-control-group-icon search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={search}
                      onChange={handleSearchChange}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="form-control-group-icon ms-3">
                      <select
                        className="form-select w-auto"
                        value={sort}
                        onChange={(e) => handleSort(e.target.value)} // Passing the string value directly
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Package Name</th>
                    <th>Service Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {results.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No users available for the selected categories.
                      </td>
                    </tr>
                  ) : (
                    results.map((packageData, index) => {
                      const serialNumber =
                        (currentPage - 1) * itemsPerPage + index + 1;
                      return (
                        <tr key={index}>
                          <td>{serialNumber}.</td>
                          <td>{packageData.package_name}</td>
                          <td>
                            {packageData.service_name.map(
                              (service, serviceIndex) => (
                                <div key={serviceIndex}>
                                  <strong>{service.service_name}</strong>
                                  {service.sub_services.length > 0 && (
                                    <ul>
                                      {service.sub_services.map(
                                        (subService, subIndex) => (
                                          <li key={subIndex}>
                                            {subService.sub_service_name}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </div>
                              )
                            )}
                          </td>
                          <td>{formatDate(packageData.start_date)}</td>
                          <td>{formatDate(packageData.end_date)}</td>
                      
                            <td className="text-center">
                              {packageData.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    disabled={!hasPermission("package", "status")}
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={packageData.status === "Active"}
                                    onChange={() =>
                                      handleToggleCheckBox(
                                        packageData._id,
                                        packageData.status
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                        


                            <td className="text-center d-flex  gap-2 ">
                              <a href={hasPermission("package", "edit") ? `/EditPackage/${packageData._id}` : "#"} className="icon-btn">
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt="Edit"
                                  title="Edit"
                                />
                              </a>
                              <Link
                                to="#"
                                className="icon-btn"
                                onClick={() => {
                                  if (hasPermission("package", "view")) {
                                    toggleVisibility(packageData);
                                  }
                                }}
                              >
                                <img
                                  src="assets/img/icon-view.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                            </td>
                         
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="d-flex align-items-center justify-content-end p-3">
                {currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(currentPage - 1, e)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}

                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? "active" : ""
                        }`}
                    >
                      <button
                        className="page-link"
                        onClick={(e) => handlePageChange(index + 1, e)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>

                {currentPage < totalPages && (
                  <button
                    className="pagination-next"
                    onClick={(e) => handlePageChange(currentPage + 1, e)}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`right-side-popup ${isVisibleView ? "" : "right-side-popup-hide"}`}>
        <div className="right-side-popup-close" onClick={handleCloseViewContent}>
          <i className="fa-solid fa-angles-right"></i>
        </div>
        {selectedPackage && (
          <div className="right-side-popup-inner">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">{selectedPackage.package_name}</h4>
                  <p className="mb-0 tc-5">
                    Last Updated: {formatDate(selectedPackage.updatedAt)}
                  </p>
                </div>
                <div className="ms-auto">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="">
                      <select className="form-select">
                        <option selected>{selectedPackage.status}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <h5 className="mb-3 tc-8">Package Information</h5>
              <p className="mb-2 tc-5">
                Number of Packages: <span className="tc-6">{selectedPackage.number_of_package}</span>
              </p>
              <p className="mb-2 tc-5">
                Start Date: <span className="tc-6">{formatDate(selectedPackage.start_date)}</span>
              </p>
              <p className="mb-4 tc-5">
                End Date: <span className="tc-6">{formatDate(selectedPackage.end_date)}</span>
              </p>

              <h5 className="mb-2 tc-8">Services & Sub-services</h5>
              {selectedPackage.service_name.map((service, index) => (
                <div key={index} className="mb-3">
                  <h6 className="tc-6">{service.service_name}</h6>
                  {service.sub_services.map((subService, subIndex) => (
                    <div key={subIndex} className="d-flex align-items-center mb-2">
                      <span className="tc-5">{subService.sub_service_name}</span>
                      <div className="ms-auto">
                        <span className="tc-5 me-3">Original: ₹{subService.originalPrice}</span>
                        <span className="tc-6">After Discount: ₹{subService.afterDiscountPrice}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}

              <h5 className="mb-2 tc-8">Location Details</h5>
              <p className="mb-2 tc-5">
                State: <span className="tc-6">{selectedPackage.state.map(s => s.state_name).join(', ')}</span>
              </p>
              <p className="mb-2 tc-5">
                District: <span className="tc-6">{selectedPackage.district.map(d => d.district_name).join(', ')}</span>
              </p>
              <p className="mb-2 tc-5">
                City: <span className="tc-6">{selectedPackage.city.map(c => c.city_name).join(', ')}</span>
              </p>

              <h5 className="mb-2 tc-8">Package Details</h5>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Total Rate</span>
                <span className="tc-6 fw-medium ms-auto">₹{selectedPackage.rate}</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Discount</span>
                <span className="tc-6 fw-medium ms-auto">{selectedPackage.discount}%</span>
              </p>

              {selectedPackage.additional_information && (
                <>
                  <h5 className="mb-2 tc-8">Additional Information</h5>
                  <p className="tc-5">{selectedPackage.additional_information}</p>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Packages;

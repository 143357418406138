import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
      name: "",
      password: "",
      email: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: null,
    };
  }

  validateForm = () => {
    const { phone, name, email, password } = this.state;
    const newErrors = {};

    if (!name) {
      newErrors.name = "Name is required.";
    }

    if (!phone) {
      newErrors.phone = "Phone is required.";
    } else if (!/^\d{10}$/.test(phone)) {
      newErrors.phone = "Invalid phone number. Must be 10 digits.";
    }

    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email format.";
    }

    if (!password) {
      newErrors.password = "Password is required.";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({
            errors: null,
          });
        }, 5000);
      });
    } else {
      this.setState({ errors: null });
      const { name, email, password, phone } = this.state;
      const { AdminRegister } = adminendPoints;
      const method = "POST";
      const body = {
        name,
        email,
        password,
        phone,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminRegister,
          method,
          body,
          contentType
        );

        const { status, message } = response.data;

        if (status === "200") {
          this.setState(
            {
              successMsg: "Registration successful.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === "201") {
          // Extract error messages and format them
          const formattedErrors = message.join(", ");
          this.setState(
            {
              errorMsg: formattedErrors,
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create registration. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the registration. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      name,
      email,
      phone,
      password,
      errors,
      redirect,
      successMsg,
      errorMsg,
    } = this.state;

    if (redirect) {
      return <Navigate to="/" />;
    }

    return (
      <div className="register-page">
        <div className="register-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <Link to="/" className="h1">
                <b>demo</b>
              </Link>
            </div>
            <div className="card-body">
              <p className="login-box-msg">Register a new membership</p>
              {errorMsg && (
                <div className="alert alert-danger alert-dismissible">
                  {errorMsg}
                </div>
              )}
              {successMsg && (
                <div className="alert alert-success alert-dismissible">
                  {successMsg}
                </div>
              )}

              {errors && (
                <div className="callout callout-danger">
                  <p>
                    {errors.name && <div className="error">{errors.name}</div>}
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                    {errors.phone && (
                      <div className="error">{errors.phone}</div>
                    )}
                    {errors.password && (
                      <div className="error">{errors.password}</div>
                    )}
                  </p>
                </div>
              )}

              <form onSubmit={this.handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    placeholder="Full name"
                    type="text"
                    name="name"
                    className="form-control"
                    value={name}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Retype password"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      Register
                    </button>
                  </div>
                </div>
              </form>
              <Link className="text-center" to="/">
                I already have a membership
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

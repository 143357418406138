import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import ViewCategory from "./viewCategory";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class ManageCategory extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject?.token || "",
      results: [],
      emailErrorMsg: "",
      search: "",
      sort: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      isVisibleView: false,
      activeTab: "All",
    };
  }

  updateCategoryStatusApi = (id, newStatus) => {
    const { token } = this.state;

    const { AdminUpdateCategoryStatus } = adminendPoints;
    const method = "PUT";
    const contentType = "application/json";

    const url = `${AdminUpdateCategoryStatus}/${id}`;
    const body = {
      status: newStatus,
    };

    return APICaller(url, method, body, contentType, token);
  };

  handleToggleCheckBox = (id, currentStatus) => {
    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    this.updateCategoryStatusApi(id, newStatus)
      .then((response) => {
        console.log("Category status updated successfully:", response.data);
        this.getCategories();
      })
      .catch((error) => {
        console.error("Error updating category status:", error);
      });
  };

  handleCloseViewContent = () => {
    this.setState({ isVisibleView: false });
  };

  viewCategorytoggle = () => {
    this.setState((prevState) => ({
      isVisibleView: !prevState.isVisibleView,
    }));
  };

  handleCloseAddContent = () => {
    this.setState({ isVisibleAdd: false });
  };

  addCategorytoggle = () => {
    this.setState((prevState) => ({
      isVisibleAdd: !prevState.isVisibleAdd,
    }));
  };

  componentDidMount() {
    this.getCategories();
  }

  getCategories = () => {
    const { token, currentPage, itemsPerPage, activeTab } = this.state;
    const { AdminCategoryList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    if (activeTab !== "All") {
      queryParams += `&status=${activeTab}`;
    }

    const url = `${AdminCategoryList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, categories, totalItems } = response.data;

        if (status === 200) {
          this.setState({
            results: categories,
            totalItems: totalItems,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, this.getCategories);
  };

  handleTabClick = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, currentPage: 1 }, () => {
        this.getCategories();
      });
    }
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  render() {
    const {
      token,
      isVisibleView,
      results,
      sort,
      search,
      currentPage,
      itemsPerPage,
      activeTab,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredAndSortedItems = Array.isArray(results)
      ? [...results]
          .filter((user) => {
            const matchesSearch = user.category_name
              .toLowerCase()
              .includes(search.toLowerCase());

            return matchesSearch;
          })
          .sort((a, b) => {
            return sort === "asc"
              ? a.category_name.localeCompare(b.category_name)
              : b.category_name.localeCompare(a.category_name);
          })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Service Management</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Service Category
                      </li>
                    </ol>
                    <h4 className="card-title mb-0">Category List</h4>
                  </div>
                  <div className="ms-auto">
                    <Link
                      to="/AddCategory"
                      type="button"
                      className="btn-blue ms-2"
                    >
                      <i className="fa-solid fa-plus me-2"></i>Add New
                    </Link>

                    <button
                      type="button"
                      className="btn-light1 ms-2"
                      onClick={this.viewCategorytoggle}
                    >
                      <i className="fa-solid fa-file-export me-2"></i>View
                      Category
                    </button>
                  </div>
                </div>
                <div className="tabs1 d-flex">
                  <Link
                    onClick={() => this.handleTabClick("All")}
                    className={activeTab === "All" ? "active" : ""}
                  >
                    All
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("Active")}
                    className={activeTab === "Active" ? "active" : ""}
                  >
                    Active
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("In-Active")}
                    className={activeTab === "In-Active" ? "active" : ""}
                  >
                    In-Active
                  </Link>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <h4 className="card-title mb-0">Stylist List</h4>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon search">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here..."
                          value={search}
                          onChange={this.handleSearchChange}
                        />
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sort}
                          onChange={(e) =>
                            this.setState({ sort: e.target.value })
                          }
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <table className="table align-middle mb-4">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Image</th>
                      <th>Category Name</th>
                      <th>Last Updated</th>
                      <th className="text-center">Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No users available for the selected categories.
                        </td>
                      </tr>
                    ) : (
                      currentItems.map((category, index) => (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{category.fileName}</td>
                          <td>{category.category_name}</td>
                          <td>{category.createdAt}</td>

                          <td className="text-center">
                            {category.status && (
                              <div className="form-check form-switch d-inline-block p-0 m-0">
                                <input
                                  className="form-check-input m-0"
                                  type="checkbox"
                                  role="switch"
                                  checked={category.status === "Active"}
                                  onChange={() =>
                                    this.handleToggleCheckBox(
                                      category._id,
                                      category.status
                                    )
                                  }
                                />
                              </div>
                            )}
                          </td>

                          <td className="text-center">
                            <a href="#" className="icon-btn">
                              <img
                                src="assets/img/icon-edit.svg"
                                alt=""
                                title=""
                              />
                            </a>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}

                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={(e) => this.handlePageChange(index + 1, e)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>

                  {currentPage < totalPages && (
                    <button
                      className="pagination-next"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <ViewCategory
              isVisibleView={isVisibleView}
              handleCloseViewContent={this.handleCloseViewContent}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ManageCategory;

import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class ViewPermission extends Component {
  constructor(props) {
    super(props);
    const retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      permissions: {},
      successMsg: "",
      errorMsg: "",
      adminid: "",
      loading: false,
    };
  }

  componentDidMount() {
    const adminid = window.location.pathname.split("/").pop();
    this.fetchPermissions(adminid);
  }

  fetchPermissions = async (adminid) => {
    this.setState({ loading: true });
    const { token } = this.state;
    const { AdminViewAdminPermission } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";
    try {
      const body = { token, id: adminid };
      const response = await APICaller(
        AdminViewAdminPermission,
        method,
        body,
        contentType,
        token
      );

      const { status, data } = response.data;

      if (status === 200) {
        this.setState({
          permissions: data.permissions,
          adminid: data.adminId,
          loading: false,
        });
      } else {
        this.setState({ errorMsg: "Failed to load permissions." });
      }
    } catch (error) {
      this.setState({ errorMsg: "Error fetching permissions." });
    }
  };

  handleCheckboxChange = (category, permission, newState = null) => {
    this.setState((prevState) => {
      const updatedPermissions = { ...prevState.permissions };
      updatedPermissions[category] = { ...updatedPermissions[category] };

      if (newState !== null) {
        updatedPermissions[category][permission] = newState;
      } else {
        updatedPermissions[category][permission] =
          !updatedPermissions[category][permission];
      }

      if (permission === "isMenuVisible") {
        const allPermissions = Object.keys(updatedPermissions[category]);
        allPermissions.forEach((perm) => {
          if (perm !== "isMenuVisible") {
            updatedPermissions[category][perm] = newState;
          }
        });
      }

      return { permissions: updatedPermissions };
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, errors: {} });

    const { permissions, adminid } = this.state;
    const { AdminUpdatePermission } = adminendPoints;
    const method = "POST";
    const body = {
      adminId: adminid,
      permissions,
    };
    const contentType = "application/json";
    const { token } = this.props; // Assuming token is coming from props

    try {
      const response = await APICaller(
        AdminUpdatePermission,
        method,
        body,
        contentType,
        token
      );

      const { status } = response.data;
      if (status === 200) {
        this.setState(
          {
            successMsg: "Admin Permission updated successfully.",
            errorMsg: "",
            loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "" });
              // Refresh the page
              window.location.reload(); // Reloads the page
            }, 3000); // Adjust timeout if needed
          }
        );
      } else {
        this.setState(
          {
            errorMsg: "An error occurred. Please try again.",
            successMsg: "",
            loading: false,
          },
          () => {
            setTimeout(() => this.setState({ errorMsg: "" }), 3000);
          }
        );
      }
    } catch (error) {
      console.error("Error updating admin permissions: ", error);
      this.setState({
        errorMsg: "An unexpected error occurred. Please try again.",
        successMsg: "",
        loading: false,
      });
    }
  };

  render() {
    const { permissions, errorMsg, successMsg, loading } = this.state;

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage Admin Users
                      </li>
                    </ol>
                    <h4 className="card-title mb-0">Manage Admin Users</h4>
                  </div>
                </div>
              </div>
            </div>

            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}

            {successMsg && (
              <div className="alert alert-success" role="alert">
                {successMsg}
              </div>
            )}

            {loading && (
              <div className="d-flex justify-content-center align-items-center loader">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            <div className="card mb-4">
              <div className="card-body p-0">
                <form>
                  <div className="permissions-container">
                    {Object.keys(permissions).map((category) => (
                      <div className="permission-category" key={category}>
                        <div className="category-header d-flex justify-content-between align-items-center">
                          <span className="category-title">
                            {category.replace(/([A-Z])/g, " $1").toUpperCase()}
                          </span>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`${category}-isMenuVisible`}
                              checked={permissions[category]?.isMenuVisible}
                              onChange={() => {
                                const newState =
                                  !permissions[category]?.isMenuVisible;
                                this.handleCheckboxChange(
                                  category,
                                  "isMenuVisible",
                                  newState
                                );
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor={`${category}-isMenuVisible`}
                            >
                              Show Menu
                            </label>
                          </div>
                        </div>
                        <div className="category-body mt-3">
                          <div className="row">
                            {Object.keys(permissions[category]).map(
                              (permission) =>
                                permission !== "isMenuVisible" && (
                                  <div
                                    className="col-lg-6 mb-3"
                                    key={permission}
                                  >
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`${category}-${permission}`}
                                        checked={
                                          permissions[category][permission]
                                        }
                                        onChange={() => {
                                          this.handleCheckboxChange(
                                            category,
                                            permission
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`${category}-${permission}`}
                                      >
                                        {permission.charAt(0).toUpperCase() +
                                          permission.slice(1)}
                                      </label>
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewPermission;

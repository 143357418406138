import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";
import ViewBanner from "./viewBanner";
import AddBanner from "./addBanner";
import { usePermissions } from "../../utils/hooks/PermisionsHook";

const ManageBanner = () => {
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const retrievedObjectPermission = getLocalStorageItem("myStylistAdminPermission");
  const { hasPermission } = usePermissions();
  const [state, setState] = useState({
    id: retrievedObject?.id || "",
    token: retrievedObject?.token || "",
    results: [],
    emailErrorMsg: "",
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    activeTab: "All",
    search: "",
    sort: "",
    banner_type: "",
    isVisibleView: false,
    isVisibleAdd: false,
    successMsg: "",
    errorMsg: "",
    featured_image_url: "https://mystylist-media.s3.amazonaws.com",
    permissions: retrievedObjectPermission,
    loading: true,
    isDeleteModalOpen: false,
    selectedBannerId: null,
    isImageModalOpen: false,
    selectedImageUrl: "",
  });

  const getBannerResults = () => {
    setState(prev => ({ ...prev, loading: true }));
    const { search, sort, token, currentPage, itemsPerPage, activeTab } = state;
    const { AdminBannerList } = adminendPoints;

    let queryParams = new URLSearchParams();
    queryParams.append('page', currentPage);
    queryParams.append('limit', itemsPerPage);
    queryParams.append('banner_for', 1);
    
    if (search?.trim()) {
      queryParams.append('search', search.trim());
    }
    if (sort) {
      queryParams.append('sort', sort);
    }
    if (activeTab !== "All") {
      queryParams.append('status', activeTab);
    }

    const url = `${AdminBannerList}?${queryParams.toString()}`;

    APICaller(url, "GET", "", "application/json", token)
      .then((response) => {
        const { status, banners, totalItems } = response.data;
        if (status === 200) {
          setState(prev => ({
            ...prev,
            results: banners,
            totalItems: totalItems,
            loading: false,
          }));
        } else {
          setState(prev => ({
            ...prev,
            emailErrorMsg: "Invalid Request",
            loading: false,
          }));
          setTimeout(() => {
            setState(prev => ({ ...prev, emailErrorMsg: "" }));
          }, 10000);
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
        setState(prev => ({ ...prev, loading: false }));
      });
  };

  useEffect(() => {
    getBannerResults();
  }, []);

  const handlePageChange = (pageNumber) => {
    setState(prev => ({ ...prev, currentPage: pageNumber }));
    getBannerResults();
  };

  const handleSearchChange = (e) => {
    setState(prev => ({ ...prev, search: e.target.value }));
  };

  const handleCloseViewContent = () => {
    setState(prev => ({ ...prev, isVisibleView: false }));
  };

  const viewCategorytoggle = () => {
    setState(prev => ({
      ...prev,
      isVisibleView: !prev.isVisibleView,
    }));
  };

  const handleCloseAddContent = () => {
    setState(prev => ({ ...prev, isVisibleAdd: false }));
  };

  const addBannertoggle = () => {
    setState(prev => ({
      ...prev,
      isVisibleAdd: !prev.isVisibleAdd,
    }));
  };

  const handleTabClick = (tab) => {
    if (state.activeTab !== tab) {
      setState(prev => ({ ...prev, activeTab: tab, currentPage: 1 }));
      getBannerResults();
    }
  };

  const handleToggleCheckBox = async (bannerId, currentStatus) => {
    const { token } = state;
    const { AdminUpdateStatusBanner } = adminendPoints;
    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    try {
      const response = await APICaller(
        AdminUpdateStatusBanner,
        "POST",
        { bannerId, bannerStatus: newStatus },
        "application/json",
        token
      );

      if (response.data.status === 200) {
        setState(prev => ({
          ...prev,
          results: prev.results.map(banner =>
            banner._id === bannerId ? { ...banner, status: newStatus } : banner
          ),
          successMsg: `Banner Status is ${newStatus}`,
          errorMsg: "",
        }));
        
        setTimeout(() => {
          setState(prev => ({ ...prev, successMsg: "" }));
        }, 2000);
        
        getBannerResults();
      }
    } catch (error) {
      setState(prev => ({
        ...prev,
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "",
      }));
    }
  };

  const openImageModal = (imageUrl) => {
    setState(prev => ({
      ...prev,
      isImageModalOpen: true,
      selectedImageUrl: imageUrl,
    }));
  };

  const closeImageModal = () => {
    setState(prev => ({
      ...prev,
      isImageModalOpen: false,
      selectedImageUrl: "",
    }));
  };

  const handleDelete = (bannerId) => {
    setState(prev => ({
      ...prev,
      isDeleteModalOpen: true,
      selectedBannerId: bannerId
    }));
  };

  const confirmDelete = async () => {
    const { token, selectedBannerId } = state;
    const { AdminDeleteBanner } = adminendPoints;
    
    try {
      const response = await APICaller(
        `${AdminDeleteBanner}/${selectedBannerId}`,
        'GET',
        { },
        'application/json',
        token
      );

      if (response.data.status === 200) {
        setState(prev => ({
          ...prev,
          successMsg: 'Banner deleted successfully',
          isDeleteModalOpen: false,
          selectedBannerId: null
        }));
        getBannerResults();
      } else {
        setState(prev => ({
          ...prev,
          errorMsg: response.data.message,
          isDeleteModalOpen: false,
          selectedBannerId: null
        }));
      }
    } catch (error) {
      setState(prev => ({
        ...prev,
        errorMsg: 'Error deleting banner',
        isDeleteModalOpen: false,
        selectedBannerId: null
      }));
    }
  };

  const closeDeleteModal = () => {
    setState(prev => ({
      ...prev,
      isDeleteModalOpen: false,
      selectedBannerId: null
    }));
  };

  if (state.token === "") {
    return <Navigate to="/" />;
  }

  const totalPages = Math.ceil(state.totalItems / state.itemsPerPage);

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Manage Home Banner
                    </li>
                  </ol>
                  <h4 className="card-title mb-0">Manage Home Banner</h4>
                </div>
                {hasPermission("customerBanners", "add") && (
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-blue ms-2"
                      onClick={addBannertoggle}
                    >
                      <i className="fa-solid fa-plus me-2"></i>Add New
                    </button>
                  </div>
                )}
              </div>
              <div className="tabs1 d-flex">
                <Link
                  onClick={() => handleTabClick("All")}
                  className={state.activeTab === "All" ? "active" : ""}
                >
                  All
                </Link>
                <Link
                  onClick={() => handleTabClick("Active")}
                  className={state.activeTab === "Active" ? "active" : ""}
                >
                  Active
                </Link>
                <Link
                  onClick={() => handleTabClick("In-Active")}
                  className={state.activeTab === "In-Active" ? "active" : ""}
                >
                  In-Active
                </Link>
              </div>
            </div>
          </div>
          {state.loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {state.successMsg && (
            <div className="alert alert-success" role="alert">
              {state.successMsg}
            </div>
          )}

          {state.errorMsg && (
            <div className="alert alert-danger" role="alert">
              {state.errorMsg}
            </div>
          )}
          <div className="card mb-4">
            <div className="card-body">
              <div className="row align-items-center mb-3">
                <div className="col-lg-5">
                  <div className="form-control-group-icon search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={state.search}
                      onChange={handleSearchChange}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="form-control-group-icon ms-3">
                      <select
                        className="form-select w-auto"
                        value={state.sort}
                        onChange={(e) =>
                          setState(prev => ({ ...prev, sort: e.target.value }))
                        }
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <i class="fa-solid fa-filter"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Banner Name</th>
                    <th>Banner Type</th>
                    <th>Position</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.results.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No users available for the selected categories.
                      </td>
                    </tr>
                  ) : (
                    state.results.map((item, index) => {
                      const serialNumber =
                        (state.currentPage - 1) * state.itemsPerPage + index + 1;
                        return (
                          <tr key={`category_${index}`}>
                            <td>{serialNumber}.</td>
                            <td>{item.banner_name}</td>
                           <td>{item.banner_type === "2" ? "Youtube" : item.banner_type === "1" ? "Stylist" : "Show Only"}</td>
                            <td>{item.position}</td>
                            <td>
                              {new Date(item.startDate).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </td>
                            <td>
                              {new Date(item.endDate).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </td>
                           
                           
                              <td className="text-center">
                                {item.status && (
                                  <div className="form-check form-switch d-inline-block p-0 m-0">
                                    <input
                                    disabled={!hasPermission("customerBanners", "status")}
                                      className="form-check-input m-0"
                                      type="checkbox"
                                      role="switch"
                                      checked={item.status === "Active"}
                                      onChange={() =>
                                        handleToggleCheckBox(
                                          item._id,
                                          item.status
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                           
                            <td className="text-center d-flex justify-content-center gap-2">
                            
  
                             
                                <Link
                                  to={hasPermission("customerBanners", "edit") ? `/EditBanner/${item._id}` : "#"}
                                  className="icon-btn"
                                >
                                  <img
                                    src="assets/img/icon-edit.svg"
                                    alt="View Banner"
                                    title="View Banner"
                                  />
                                </Link>
                              
  
                              {/* {state.permissions?.banners?.view && (
                                <Link to="#" className="icon-btn">
                                  <img
                                    src="assets/img/icon-view.svg"
                                    alt=""
                                    title=""
                                  />
                                </Link>
                              )} */}
  
                            
                                <Link to="#" className="icon-btn btn-danger" onClick={() =>{
                                  if(hasPermission("customerBanners", "delete")){
                                    handleDelete(item._id)
                                  }
                                }}>
                                  <img src="assets/img/icon-delete.svg" alt="Delete" title="Delete Banner" />
                                </Link>
                              
                            </td>
                          </tr>
                        );
                    })
                  )}
                </tbody>
                {state.isImageModalOpen && (
                  <div
                    className="modal"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-body">
                          <span
                            className="close"
                            onClick={closeImageModal}
                          >
                            &times;
                          </span>
                          <img
                            src={state.selectedImageUrl}
                            alt="View Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </table>
              <div className="d-flex align-items-center justify-content-end p-3">
                {state.currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(state.currentPage - 1, e)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}

                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        state.currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={(e) => handlePageChange(index + 1, e)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>

                {state.currentPage < totalPages && (
                  <button
                    className="pagination-next"
                    onClick={(e) => handlePageChange(state.currentPage + 1, e)}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ViewBanner
        isVisibleView={state.isVisibleView}
        handleCloseViewContent={handleCloseViewContent}
      />

      <AddBanner
        isVisibleAdd={state.isVisibleAdd}
        handleCloseAddContent={handleCloseAddContent}
        reloadListing={getBannerResults}
      />

      {state.isDeleteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-delete">
            <div className="modal-content">
              <h4>Confirm Delete</h4>
              <p>Are you sure you want to delete this banner?</p>
              <div className="modal-actions">
                <button className="btn btn-secondary" onClick={closeDeleteModal}>No</button>
                <button className="btn btn-danger" onClick={confirmDelete}>Yes</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageBanner;

import React, { useState, useEffect, useRef } from "react";
import Header from "../Header/index";
import { isEqual } from "lodash";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Select from "react-select";
import { formatDate } from "../../utils/commonFunction";
import { RxCross1 } from "react-icons/rx";
import { BsCardImage } from "react-icons/bs";
const ImageUrl = process.env.REACT_APP_PROD_BUCKET_URL;
const EditPackage = (props) => {
  const [packageName, setPackageName] = useState("");
  const [rate, setRate] = useState("");
  const [numberOfPackage, setNumberOfPackage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [discount, setDiscount] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [token, setToken] = useState(getLocalStorageItem("myStylistAdmin")?.token || "");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const packageId = window.location.pathname.split("/").pop();
    getPackageDetails(packageId);
  }, []);

  const getPackageDetails = async (id) => {
    const { AdminGetSinglePackageById } = adminendPoints;
    try {
      const response = await APICaller(
        `${AdminGetSinglePackageById}/${id}`,
        "GET",
        "",
        "application/json",
        token
      );

      const { status, packages } = response.data;
      const data = packages[0];
      if (status === 200) {
        // Populate form with package data
        setPackageName(data.package_name);
        setRate(data.rate);
        setNumberOfPackage(data.number_of_package);
        setStartDate(data.start_date?.substring(0, 10));
        setEndDate(data.end_date?.substring(0, 10));
        setDiscount(data.discount);
        setAdditionalInfo(data.additional_information);
        setSelectedServices(data.service_name);
        setSelectedStates(data.state);
        setSelectedDistricts(data.district);
        setSelectedCities(data.city);
      }
    } catch (error) {
      console.error("Error fetching package:", error);
      setErrorMsg("Failed to load package details");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = {
      package_name: packageName,
      rate,
      number_of_package: numberOfPackage,
      start_date: startDate,
      end_date: endDate,
      discount,
      additional_information: additionalInfo,
      service_name: selectedServices,
      state: selectedStates,
      district: selectedDistricts,
      city: selectedCities
    };

    try {
      const { AdminEditPackage } = adminendPoints;
      const response = await APICaller(
        AdminEditPackage,
        "POST",
        formData,
        "application/json",
        token
      );

      if (response.data.status === 200) {
        setSuccessMsg("Package updated successfully");
        setTimeout(() => setRedirect(true), 2000);
      } else {
        setErrorMsg("Failed to update package");
      }
    } catch (error) {
      console.error("Error updating package:", error);
      setErrorMsg("An error occurred while updating");
    }
  };

  if (redirect) return <Navigate to="/ManagePackages" />;
  if (!token) return <Navigate to="/" />;

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <h4 className="card-title mb-0">Edit Package</h4>
                <Link to="/ManagePackages" className="btn-light1 ms-auto">
                  <i className="fa-solid fa-arrow-left-long me-2"></i>Back
                </Link>
              </div>

              {(errorMsg || successMsg) && (
                <div className={`alert alert-${errorMsg ? 'danger' : 'success'}`}>
                  {errorMsg || successMsg}
                </div>
              )}

              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <label className="form-label">Package Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={packageName}
                      onChange={(e) => setPackageName(e.target.value)}
                      disabled
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">Rate</label>
                    <input
                      type="number"
                      className="form-control"
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                      disabled
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">Number of Packages</label>
                    <input
                      type="number"
                      className="form-control"
                      value={numberOfPackage}
                      onChange={(e) => setNumberOfPackage(e.target.value)}
                      disabled
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">Discount (%)</label>
                    <input
                      type="number"
                      className="form-control"
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                      disabled
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      disabled
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      disabled
                    />
                  </div>

                  <div className="col-12">
                    <label className="form-label">Additional Information</label>
                    <textarea
                      className="form-control"
                      value={additionalInfo}
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                      rows="3"
                      disabled
                    />
                  </div>
                  {/* <div className="col-12">
                    <button type="submit" className="btn-blue">
                      Update Package
                    </button>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(EditPackage);

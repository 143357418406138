import React from "react";

const DeleteConfirmation = ({ isVisible, handleClose, handleConfirm, loading }) => {
  return (
    <>
      <div className={`right-side-popup ${isVisible ? "" : "right-side-popup-hide"}`}>
        <div className="right-side-popup-inner">
          <div className="card-body text-center">
            <div className="mb-4">
              <i className="fa-solid fa-trash-can text-danger" style={{ fontSize: '48px' }}></i>
            </div>
            <h4 className="card-title mb-4">Confirm Delete</h4>
            <p className="mb-4">Are you sure you want to delete this time entry? This action cannot be undone.</p>
            
            <div className="d-flex justify-content-center gap-3">
              <button 
                className="btn btn-secondary" 
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </button>
              <button 
                className="btn btn-danger" 
                onClick={handleConfirm}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Deleting...
                  </>
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`right-side-popup-backdrop ${isVisible ? "" : "d-none"}`} onClick={handleClose}></div>
    </>
  );
};

export default DeleteConfirmation; 
import React from "react";
import moment from "moment";
import { adminendPoints } from "../config";
import APICaller from "../utils/apicaller";

export function formatDate(dateString) {
  return moment.utc(dateString).format("MMM D, YYYY"); // Example: "Mar 6, 2025"
}

export const fetchPermissions = async (token, adminId) => {
  const { AdminViewAdminPermission } = adminendPoints; // Ensure this is defined correctly elsewhere
  const method = "POST";
  const contentType = "application/json";

  try {
    const body = { token, id: adminId };

    const response = await APICaller(
      AdminViewAdminPermission,
      method,
      body,
      contentType,
      token
    );

    if (response?.data?.status === 200) {
      return response.data.data.permissions;
    } else {
      throw new Error("Failed to fetch permissions");
    }
  } catch (error) {
    console.error("Error Fetching Permissions:", error);
    throw error; // Re-throw the error so the caller can handle it
  }
};

export function paginateData(data, currentPage, itemsPerPage) {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return { currentItems, totalPages };
}

const Pagination = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) => {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = totalItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(totalItems.length / itemsPerPage);

  return (
    <div>
      <ul className="pagination m-0">
        {Array.from({ length: totalPages }).map((_, index) => (
          <li
            key={index}
            className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => onPageChange(index + 1)}
            >
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;

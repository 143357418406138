import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../store/localStorage";

export default class Header extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      name: retrievedObject ? retrievedObject.name : "",
      email: retrievedObject ? retrievedObject.email : "",
      notificationVisible: false,
      loggedOut: false,
    };
  }

  handleLinkClick = () => {
    this.setState((prevState) => ({
      notificationVisible: !prevState.notificationVisible,
    }));
  };

  handleLogout = () => {
    // Clear local storage
    removeLocalStorageItem("myStylistAdmin");

    // Update state to trigger the logout process
    this.setState({ loggedOut: true });
  };

  render() {
    const { name, email, loggedOut } = this.state;

    if (loggedOut) {
      return <Navigate to="/BulkNotification" />;
    }

    return (
      <div className="top-navbar">
        <div className="container-fluid">
          <div className="top-navbar-inner">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="top-navbar-left">
                  <p className="mb-0">
                    Welcome Back,
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/assets/img/icon-wave.svg`}
                      alt="Wave icon"
                      title="Wave icon"
                    />
                  </p>
                  <p className="mb-0">
                    <strong>{name}</strong>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 text-end">
                {/* <div className="d-inline-block align-top mx-1 top-navbar-notification">
                  <Link to="#" onClick={this.handleLinkClick}>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/assets/img/icon-notification.svg`}
                      alt="Notification icon"
                      title="Notification icon"
                    />
                  </Link>
                </div> */}
                {/* <div className="d-inline-block align-top mx-1 top-navbar-setting">
                  <Link to="#">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/assets/img/icon-setting.svg`}
                      alt="Settings icon"
                      title="Settings icon"
                    />
                  </Link>
                </div> */}

                <div className="dropdown d-inline-block align-top ms-1 top-navbar-user">
                  <Link
                    className="dropdown-toggle d-flex align-items-center text-start"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <figure className="me-2 mb-0 top-navbar-user-img">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/assets/img/dummy-user.png`}
                        alt="User avatar"
                        title="User avatar"
                      />
                    </figure>
                    <div>
                      <strong className="d-block top-navbar-user-name">
                        {name}
                      </strong>
                      <span className="d-block top-navbar-user-email">
                        {email}
                      </span>
                    </div>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={this.handleLogout}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

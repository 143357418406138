import { useState, useEffect, Component } from "react";

// Hook for function-based components
const usePermissions = () => {
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const storedPermissions = localStorage.getItem("myStylistAdminPermission");
    if (storedPermissions) {
      setPermissions(JSON.parse(storedPermissions));
    }
  }, []);

  const hasPermission = (module, action) => {
    return permissions[module]?.[action] ?? false;
  };

  return { permissions, hasPermission };
};

// HOC for class-based components
const withPermissions = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        permissions: {},
      };
    }

    componentDidMount() {
      const storedPermissions = localStorage.getItem("myStylistAdminPermission");
      if (storedPermissions) {
        this.setState({ permissions: JSON.parse(storedPermissions) });
      }
    }

    hasPermission = (module, action) => {
      return this.state.permissions[module]?.[action] ?? false;
    };

    render() {
      return <WrappedComponent {...this.props} permissions={this.state.permissions} hasPermission={this.hasPermission} />;
    }
  };
};

export { usePermissions, withPermissions };

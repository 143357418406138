import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";
import ViewOffers from "./viewOffers";
import { usePermissions } from "../../utils/hooks/PermisionsHook";

const Offers = () => {
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const retrievedObjectPermission = getLocalStorageItem("myStylistAdminPermission");

  const { hasPermission } = usePermissions();

  const [state, setState] = useState({
    id: retrievedObject ? retrievedObject.id : "",
    token: retrievedObject ? retrievedObject.token : "",
    results: [],
    emailErrorMsg: "",
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    successMsg: "",
    errorMsg: "",
    activeTab: "All",
    search: "",
    sort: "desc",
    isVisibleView: false,
    isVisibleAdd: false,
    status: "",
    offerDetailsresults: "",
    loading: true,
    permissions: retrievedObjectPermission,
    featured_image_url: "https://mystylist-media.s3.amazonaws.com",
    isImageModalOpen: false,
    selectedImageUrl: "",
  });

  useEffect(() => {
    getAllOffers();
  }, []);

  const getAllOffers = () => {
    setState(prev => ({ ...prev, loading: true }));
    const { sort, search, token, currentPage, itemsPerPage } = state;
    const { AdminOfferList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
      ...(search && { search: search.trim() }),
      ...(sort && { sort }),
    });

    const url = `${AdminOfferList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, offers, totalItems } = response.data;
        if (status === 200) {
          setState(prev => ({
            ...prev,
            results: offers,
            totalItems: totalItems,
            loading: false,
          }));
        } else {
          setState(prev => ({
            ...prev,
            emailErrorMsg: "Invalid Request",
            loading: false,
          }));
          window.setTimeout(() => {
            setState(prev => ({
              ...prev,
              emailErrorMsg: "",
            }));
          }, 10000);
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
        setState(prev => ({
          ...prev,
          loading: false,
        }));
      });
  };

  const handlePageChange = (pageNumber) => {
    setState(prev => ({ ...prev, currentPage: pageNumber }), getAllOffers);
  };

  const handleDelete = async (itemId) => {
    const { token } = state;
    const { AdminFaqDelete } = adminendPoints;
    const method = "POST";

    const body = {
      itemId,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminFaqDelete,
        method,
        body,
        contentType,
        token
      );

      const { status } = response.data;
      if (status === 200) {
        setState(prev => ({
          ...prev,
          results: prev.results.filter((item) => item._id !== itemId),
          successMsg: "Faq deleted successfully.",
          errorMsg: "",
        }));
        setTimeout(() => {
          setState(prev => ({ ...prev, successMsg: "" }));
        }, 3000);
      } else {
        setState(prev => ({
          ...prev,
          errorMsg: "Failed to delete FAQ. Please try again.",
          successMsg: "",
        }));
        setTimeout(() => {
          setState(prev => ({ ...prev, errorMsg: "" }));
        }, 3000);
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      setState(prev => ({
        ...prev,
        errorMsg:
          "An error occurred while creating the offers. Please try again.",
        successMsg: "",
      }));
    }
  };

  const handleTabClick = (tab) => {
    if (state.activeTab !== tab) {
      setState(prev => ({ ...prev, activeTab: tab, currentPage: 1 }), getAllOffers);
    }
  };

  const handleSearchChange = (e) => {
    let value = e.target.value.replace(/\s+/g, " ");

    setState(prev => ({ ...prev, search: value }));

    if (this.searchDebounce) clearTimeout(this.searchDebounce);

    if (value.length >= 3 || value.length === 0) {
      this.searchDebounce = setTimeout(() => {
        setState(prev => ({ ...prev, currentPage: 1 }), getAllOffers);
      }, 300);
    }
  };

  const handleCloseViewContent = () => {
    setState(prev => ({ ...prev, isVisibleView: false }));
  };

  const viewCategorytoggle = (offerId) => {
    getOfferDetails(offerId);
    setState(prev => ({ ...prev, isVisibleView: !prev.isVisibleView }));
  };

  const getOfferDetails = (id) => {
    const { token } = state;
    const { AdminOfferDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminOfferDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, offer } = response.data;

        if (status === 200) {
          setState(prev => ({
            ...prev,
            offerDetailsresults: offer,
          }));
        } else {
          setState(prev => ({
            ...prev,
            emailErrorMsg: "Invalid Request",
          }));
          window.setTimeout(() => {
            setState(prev => ({
              ...prev,
              emailErrorMsg: "",
            }));
          }, 10000);
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  const handleCloseAddContent = () => {
    setState(prev => ({ ...prev, isVisibleAdd: false }));
  };

  const addCategorytoggle = () => {
    setState(prev => ({ ...prev, isVisibleAdd: !prev.isVisibleAdd }));
  };

  const handleToggleCheckBox = async (offerId, currentStatus, expert_id) => {
    const { token } = state;
    const { AdminOfferStatusUpdate } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      offerId,
      status: newStatus,
      expert_id: expert_id,
    };

    try {
      const response = await APICaller(
        AdminOfferStatusUpdate,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        setState(prev => ({
          ...prev,
          results: prev.results.map((offer) =>
            offer._id === offerId ? { ...offer, status: newStatus } : offer
          ),
          successMsg: `Offer Status is ${newStatus}`,
          errorMsg: "",
        }));

        setTimeout(() => {
          setState(prev => ({ ...prev, successMsg: "" }));
        }, 2000);
      } else {
        console.error("Failed to update offer status");
        setState(prev => ({
          ...prev,
          errorMsg: message,
          successMsg: "",
        }));
      }
    } catch (error) {
      console.error("Error making data call:", error);
      setState(prev => ({
        ...prev,
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "",
      }));
    }
  };

  const openImageModal = (imageUrl) => {
    setState(prev => ({
      ...prev,
      isImageModalOpen: true,
      selectedImageUrl: imageUrl,
    }));
  };

  const closeImageModal = () => {
    setState(prev => ({
      ...prev,
      isImageModalOpen: false,
      selectedImageUrl: "",
    }));
  };

  const handleSort = (sortField) => {
    setState(prev => ({ ...prev, sort: sortField, currentPage: 1 }), getAllOffers);
  };

  if (state.token === "") {
    return <Navigate to="/" />;
  }

  const totalPages = Math.ceil(state.totalItems / state.itemsPerPage);

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      All Offers List
                    </li>
                  </ol>
                  <h4 className="card-title mb-0">All Offers List</h4>
                </div>
                {state.permissions?.offers?.add && (
                  <div className="ms-auto">
                    <Link to="/AddOffers" className="btn-blue ms-2">
                      <i className="fa-solid fa-plus me-2"></i>Genrate Offer
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>

          {state.loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {state.successMsg && (
            <div className="alert alert-success" role="alert">
              {state.successMsg}
            </div>
          )}

          {state.errorMsg && (
            <div className="alert alert-danger" role="alert">
              {state.errorMsg}
            </div>
          )}
          <div className="card mb-4">
            <div className="card-body">
              <div className="row align-items-center mb-3">
                <div className="col-lg-5">
                  <div className="form-control-group-icon search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={state.search}
                      onChange={handleSearchChange}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="form-control-group-icon ms-3">
                      <select
                        className="form-select w-auto"
                        value={state.sort}
                        onChange={(e) => handleSort(e.target.value)}
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Offer Name</th>
                    <th>Service Name</th>
                    <th>Sub Service Name</th>
                    <th>Price</th>
                    <th>start_date</th>
                    <th>End Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.results.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No users available for the selected categories.
                      </td>
                    </tr>
                  ) : (
                    state.results.map((offers, index) => {
                      const serialNumber =
                        (state.currentPage - 1) * state.itemsPerPage + index + 1;
                      return (
                        <tr key={index}>
                          <td>{serialNumber}.</td>
                          <td>{offers.offer_name}</td>
                          <td>
                            {offers.service?.service_id?.service_name ||
                              "N/A"}
                          </td>
                          <td>
                            {offers.sub_services?.sub_service_id
                              ?.sub_service_name || "N/A"}
                          </td>
                          <td>{offers.sub_services?.price || "N/A"}</td>
                          <td>{formatDate(offers.start_date)}</td>
                          <td>{formatDate(offers.end_date)}</td>

                        
                            <td className="text-center">
                              {offers.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    disabled={!hasPermission("offer", "status")}
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={offers.status === "Active"}
                                    onChange={() =>
                                      handleToggleCheckBox(
                                        offers._id,
                                        offers.status,
                                        offers.expert_id
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                          
                          <td className="text-center">
                           
                          
                              <Link
                                to={hasPermission("offer", "edit") ? `/EditOffers/${offers._id}` : "#"}
                                title="Edit"
                                className="icon-btn"
                              >
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                          
                            {hasPermission("offer", "view") && (
                              <Link
                                onClick={() =>
                                  viewCategorytoggle(offers._id)
                                }
                                title="View"
                                className="icon-btn"
                              >
                                <img
                                  src="assets/img/icon-view.svg"
                                  alt=""
                                  title="View"
                                />
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
                {state.isImageModalOpen && (
                  <div
                    className="modal"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-body">
                          <span
                            className="close"
                            onClick={closeImageModal}
                          >
                            &times;
                          </span>
                          <img
                            src={state.selectedImageUrl}
                            alt="View Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </table>
              <div className="d-flex align-items-center justify-content-end p-3">
                {state.currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(state.currentPage - 1, e)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}

                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        state.currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={(e) => handlePageChange(index + 1, e)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>

                {state.currentPage < totalPages && (
                  <button
                    className="pagination-next"
                    onClick={(e) => handlePageChange(state.currentPage + 1, e)}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.isVisibleView && (
        <ViewOffers
          isVisibleView={state.isVisibleView}
          handleCloseViewContent={handleCloseViewContent}
          offerDetailsresults={state.offerDetailsresults}
          formatDate={formatDate}
        />
      )}
    </div>
  );
};

export default Offers;

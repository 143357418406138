import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";
import { usePermissions } from "../../utils/hooks/PermisionsHook";
const ExpertList = () => {
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const retrievedObjectPermission = getLocalStorageItem("myStylistAdminPermission");

  const [state, setState] = useState({
    id: retrievedObject ? retrievedObject.id : "",
    token: retrievedObject ? retrievedObject.token : "",
    results: [],
    emailErrorMsg: "",
    successMsg: "",
    errorMsg: "",
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    search: "",
    sort: "desc",
    activeTab: "All",
    loading: true,
    showPopup: false,
    expertPhone: "",
    errors: {},
    successMsgModel: "",
    errorMsgModel: "",
    permissions: retrievedObjectPermission,
    showDeletePopup: false,
    selectedExpertId: null,
    selectedExpertName: '',
  });

  const { hasPermission } = usePermissions();

  const getExpertResults = () => {
    const { search, sort, token, currentPage, itemsPerPage, activeTab } = state;
    const { AdminExpertList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
      ...(search && { search: search.trim() }),
      ...(sort && { sort }),
      ...(activeTab && { status: activeTab }),
    });

    const url = `${AdminExpertList}${queryParams ? `?${queryParams}` : ""}`;

    setState(prev => ({ ...prev, loading: true }));

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, expertusers, totalItems } = response.data;

        if (status === 200) {
          setState(prev => ({
            ...prev,
            results: expertusers,
            totalItems: totalItems,
            loading: false,
          }));
        } else {
          setState(prev => ({
            ...prev,
            emailErrorMsg: "Invalid Request",
            loading: false,
          }));
          setTimeout(() => {
            setState(prev => ({ ...prev, emailErrorMsg: "" }));
          }, 10000);
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
        setState(prev => ({ ...prev, loading: false }));
      });
  };

  useEffect(() => {
    getExpertResults();
  }, []);

  const deleteExpert = async (expertId) => {
    const { AdminDeleteExpert } = adminendPoints;
    const method = "POST";
    const body = { expertId };
    const contentType = "application/json";
    const token = state.token;
    await APICaller(AdminDeleteExpert, method, body, contentType, token);
  };

  const handleToggleCheckBox = async (expertId, currentStatus) => {
    const { token } = state;
    const { AdminUpdateStatusExpert } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      expertId,
      status: newStatus,
    };
    
    setState(prev => ({ ...prev, loading: true }));

    try {
      const response = await APICaller(
        AdminUpdateStatusExpert,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        setState(prev => ({
          ...prev,
          results: prev.results.map((expert) =>
            expert._id === expertId ? { ...expert, status: newStatus } : expert
          ),
          successMsg: `Expert Status is ${newStatus}`,
          errorMsg: "",
          loading: false,
        }));
        
        setTimeout(() => {
          setState(prev => ({ ...prev, successMsg: "" }));
        }, 2000);
        getExpertResults();
      } else {
        setState(prev => ({
          ...prev,
          errorMsg: message,
          successMsg: "",
          loading: false,
        }));
      }
    } catch (error) {
      setState(prev => ({
        ...prev,
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "",
        loading: false,
      }));
    }
  };

  const handlePageChange = (pageNumber) => {
    setState(prev => ({ ...prev, currentPage: pageNumber }));
    getExpertResults();
  };

  const handleTabClick = (tab) => {
    if (state.activeTab !== tab) {
      setState(prev => ({ ...prev, activeTab: tab, currentPage: 1, loading: true }));
      getExpertResults();
    }
  };

  const handleSearchChange = (e) => {
    let value = e.target.value.replace(/\s+/g, " ");

    setState(prev => ({ ...prev, search: value }));

    if (value.length >= 3 || value.length === 0) {
      setTimeout(() => {
        setState(prev => ({ ...prev, currentPage: 1 }));
        getExpertResults();
      }, 300);
    }
  };

  const validateForm = () => {
    const errors = {};
    const { expertPhone } = state;

    if (!expertPhone) {
      errors.expertPhone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(expertPhone)) {
      errors.expertPhone = "Phone number must be 10 digits.";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setState(prev => ({ ...prev, errors: newErrors }));
      setTimeout(() => {
        setState(prev => ({ ...prev, errors: {} }));
      }, 5000);
    } else {
      setState(prev => ({ ...prev, errors: {} }));

      const { expertPhone } = state;

      const { AdminExpertRegister } = adminendPoints;
      const method = "POST";
      const body = { phone: expertPhone };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminExpertRegister,
          method,
          body,
          contentType,
          ""
        );

        const { status } = response.data;

        if (status === 200) {
          setState(prev => ({
            ...prev,
            successMsgModel: "Stylist created successfully.",
            errorMsgModel: "",
          }));
          setTimeout(() => {
            setState(prev => ({ ...prev, successMsgModel: "", redirect: true }));
            togglePopup();
          }, 5000);
        } else if (status === 409) {
          setState(prev => ({
            ...prev,
            errorMsgModel: "This phone number is already registered.",
            successMsgModel: "",
          }));
          setTimeout(() => {
            setState(prev => ({ ...prev, errorMsgModel: "", redirect: false }));
          }, 3000);
        } else {
          setState(prev => ({
            ...prev,
            errorMsgModel: "Failed to create the expert. Please try again.",
            successMsgModel: "",
          }));
          setTimeout(() => {
            setState(prev => ({ ...prev, errorMsgModel: "", redirect: false }));
          }, 3000);
        }
      } catch (error) {
        console.log("Error making data call --> ", error);
        setState(prev => ({
          ...prev,
          errorMsgModel: "An error occurred while creating the expert. Please try again.",
          successMsgModel: "",
        }));
      }
    }
  };

  const togglePopup = () => {
    setState(prev => ({
      ...prev,
      showPopup: !prev.showPopup,
      expertPhone: "",
      errors: {},
    }));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState(prev => ({ ...prev, [id]: value }));
  };

  const handleSort = (sortField) => {
    setState(prev => ({ ...prev, sort: sortField, currentPage: 1 }));
    getExpertResults();
  };

  const toggleDeletePopup = (expertId = null, expertName = '') => {
    setState(prev => ({
      ...prev,
      showDeletePopup: !prev.showDeletePopup,
      selectedExpertId: expertId,
      selectedExpertName: expertName,
    }));
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteExpert(state.selectedExpertId);
      setState(prev => ({
        ...prev,
        successMsg: "Stylist deleted successfully",
        showDeletePopup: false,
      }));
      getExpertResults();
    } catch (error) {
      setState(prev => ({
        ...prev,
        errorMsg: "Failed to delete stylist",
        showDeletePopup: false,
      }));
    }
  };

  if (state.token === "") {
    return <Navigate to="/" />;
  }

  const totalPages = Math.ceil(state.totalItems / state.itemsPerPage);

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Manage Stylist's
                    </li>
                  </ol>
                  <h4 className="card-title mb-0">Manage Stylist's</h4>
                </div>
                <div className="ms-auto">
                  <button
                    type="button"
                    className="btn-blue ms-2"
                    onClick={togglePopup}
                  >
                    <i className="fa-solid fa-plus me-2"></i>Add New
                  </button>
                  <button type="button" className="btn-light1 ms-2">
                    <i className="fa-solid fa-file-export me-2"></i>View
                    Stylist
                  </button>
                </div>
              </div>
              <div className="tabs1 d-flex">
                <Link
                  onClick={() => handleTabClick("All")}
                  className={state.activeTab === "All" ? "active" : ""}
                >
                  All
                </Link>
                <Link
                  onClick={() => handleTabClick("Active")}
                  className={state.activeTab === "Active" ? "active" : ""}
                >
                  Active
                </Link>
                <Link
                  onClick={() => handleTabClick("In-Active")}
                  className={state.activeTab === "In-Active" ? "active" : ""}
                >
                  In-Active
                </Link>
              </div>
            </div>
          </div>

          {state.loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {state.successMsg && (
            <div className="alert alert-success" role="alert">
              {state.successMsg}
            </div>
          )}

          {state.errorMsg && (
            <div className="alert alert-danger" role="alert">
              {state.errorMsg}
            </div>
          )}

          <div className="card mb-4">
            <div className="card-body">
              <div className="row align-items-center mb-3">
                <div className="col-lg-5">
                  <div className="form-control-group-icon search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={state.search}
                      onChange={handleSearchChange}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="form-control-group-icon ms-3">
                      <select
                        className="form-select w-auto"
                        value={state.sort}
                        onChange={(e) => handleSort(e.target.value)}
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>

                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Gender</th>
                    <th>Joining Date</th>
                    <th>Profile Status (%) </th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.results.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No users available for the selected categories.
                      </td>
                    </tr>
                  ) : (
                    state.results.map((category, index) => {
                      const serialNumber =
                        (state.currentPage - 1) * state.itemsPerPage + index + 1;
                      return (
                        <tr key={index}>
                          <td>{serialNumber}.</td>
                          <td>{category.name}</td>
                          <td>{category.phone}</td>
                          <td>{category.gender}</td>
                          <td>{formatDate(category.createdAt)}</td>
                          <td>
                            <div className="progress" style={{ width: "100px", height: "20px", borderRadius: "10px", backgroundColor: "#f0f0f0" }}>

                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${category?.dataFilledOf}%`,
                                  background: "linear-gradient(to right, #89e3dc, #8BC34A)",
                                  borderRadius: "10px",
                                  transition: "width 1s ease-in-out",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "#fff",
                                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)"
                                }}
                                aria-valuenow={category?.dataFilledOf}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {category?.dataFilledOf}%
                              </div>
                            </div>
                          </td>
                            <td className="text-center">
                              {category.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={category.status === "Active"}
                                    disabled={category.dataFilledOf < 100 && category.status === "In-Active" || !hasPermission("stylistsManagement", "status") }
                                    onChange={() =>
                                      handleToggleCheckBox(
                                        category._id,
                                        category.status
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>

                            <td className="text-center">
                              <Link
                                to={hasPermission("stylistsManagement", "edit") ? `/EditExpert/${category._id}` : "#"}
                                title="Edit"
                                className="icon-btn me-2"
                              >
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                            
                                <button
                                  disabled={!hasPermission("stylistsManagement", "delete")}
                                  className="icon-btn text-danger border-0 bg-transparent"
                                  onClick={() => toggleDeletePopup(category._id, category.name)}
                                  title="Delete"
                                >
                                  <i className="fa-solid fa-trash-can"></i>
                                </button>
                              
                            </td>
                        
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>

              <div className="d-flex align-items-center justify-content-end p-3">
                {state.currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(state.currentPage - 1, e)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}
                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${state.currentPage === index + 1 ? "active" : ""
                        }`}
                    >
                      <button
                        className="page-link"
                        onClick={(e) => handlePageChange(index + 1, e)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
                {state.currentPage < totalPages && (
                  <button
                    className="pagination-next"
                    onClick={(e) => handlePageChange(state.currentPage + 1, e)}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {state.showPopup && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add New Expert</h5>
                <button
                  type="button"
                  className="close"
                  onClick={togglePopup}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {state.successMsgModel && (
                  <div className="alert alert-success" role="alert">
                    {state.successMsgModel}
                  </div>
                )}
                {state.errorMsgModel && (
                  <div className="alert alert-danger" role="alert">
                    {state.errorMsgModel}
                  </div>
                )}
                <form>
                  <div className="form-group">
                    <label htmlFor="expertPhone">Phone</label>
                    <input
                      type="text"
                      className={`form-control ${state.errors.expertPhone ? "is-invalid" : ""
                        }`}
                      id="expertPhone"
                      value={state.expertPhone}
                      onChange={handleChange}
                    />
                    {state.errors.expertPhone && (
                      <div className="invalid-feedback">
                        {state.errors.expertPhone}
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={togglePopup}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.showDeletePopup && (
        <>
          <div className="modal-backdrop show" style={{ opacity: 0.5 }}></div>
          <div className="modal show d-block" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-bottom-0">
                  <h5 className="modal-title">Confirm Delete</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => toggleDeletePopup()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center py-4">
                  <div className="mb-4">
                    <i className="fa-solid fa-triangle-exclamation text-warning" style={{ fontSize: '3rem' }}></i>
                  </div>
                  <h5>Are you sure you want to delete this stylist?</h5>
                  <p className="text-muted mb-0">
                    {state.selectedExpertName}
                  </p>
                </div>
                <div className="modal-footer border-top-0">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => toggleDeletePopup()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeleteConfirm}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(null, null)(ExpertList);

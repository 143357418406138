import React, { createContext, Component } from "react";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../store/localStorage";

// Create context for permissions
const PermissionsContext = createContext();

// PermissionsProvider component to manage permissions state
class PermissionsProvider extends Component {
  constructor(props) {
    super(props);

    // Retrieve the admin data from localStorage
    const retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      id: retrievedObject ? retrievedObject.id : "",
      token: retrievedObject ? retrievedObject.token : "",
      permissions: [],
      loading: false,
      errorMsg: "",
    };
  }

  componentDidMount() {
    const { id } = this.state;
    if (id) {
      this.fetchPermissions(id); // Fetch permissions if the ID exists
    }
  }

  // Reset the permissions when the user logs in with a new user
  static getDerivedStateFromProps(nextProps, nextState) {
    const newUserId = getLocalStorageItem("myStylistAdmin")?.id;

    if (nextState.id !== newUserId) {
      return {
        id: newUserId,
        token: getLocalStorageItem("myStylistAdmin")?.token,
        permissions: [], // Reset permissions for new user
      };
    }

    return null; // No change in state
  }

  // Fetch permissions from the API
  fetchPermissions = async (adminId) => {
    console.log(adminId, "adminId");

    this.setState({ loading: true });

    const { token } = this.state;
    const { AdminViewAdminPermission } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, id: adminId };
      const response = await APICaller(
        AdminViewAdminPermission,
        method,
        body,
        contentType,
        token
      );

      const { status, data } = response.data;

      console.log(response.data, "adminId");

      if (status === 200) {
        this.setState({
          permissions: data.permissions, // Store permissions data
          loading: false,
        });
      } else {
        this.setState({
          errorMsg: "Failed to load permissions.",
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        errorMsg: "Error fetching permissions.",
        loading: false,
      });
    }
  };

  // Handle logout to clear localStorage and permissions
  handleLogout = () => {
    removeLocalStorageItem("myStylistAdmin");
    this.setState({
      permissions: [],
      id: "",
      token: "",
    });
  };

  render() {
    const { permissions, loading, errorMsg } = this.state;

    console.log(permissions, "permissions");

    return (
      <PermissionsContext.Provider
        value={{
          permissions,
          loading,
          errorMsg,
          handleLogout: this.handleLogout,
        }}
      >
        {this.props.children}
      </PermissionsContext.Provider>
    );
  }
}

// Higher-order component to provide permissions to wrapped component
const withPermissions = (WrappedComponent) => {
  return function (props) {
    return (
      <PermissionsContext.Consumer>
        {(context) => <WrappedComponent {...props} {...context} />}
      </PermissionsContext.Consumer>
    );
  };
};

export { PermissionsProvider, withPermissions };

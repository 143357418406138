import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";
import { Link } from "react-router-dom";

class UserManagement extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    var retrievedObjectPermission = getLocalStorageItem(
      "myStylistAdminPermission"
    );

    this.state = {
      id: retrievedObject ? retrievedObject.id : "",
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      emailErrorMsg: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      sort: "desc",
      loading: true,
      search: "",
      role: "",
      successMsg: "",
      errorMsg: "",
      permissions: retrievedObjectPermission,
      isVisible: false,
      selectedPayment: null,
      startDate: '',
      endDate: '',
    };
  }

  componentDidMount() {
    // const { id, token } = this.state;
    // if (id) {
    //   const fetchPermissionsData = async () => {
    //     try {
    //       const permissions = await fetchPermissions(token, id);
    //       this.setState({ permissions });
    //     } catch (error) {
    //       console.error("Error fetching permissions:", error);
    //     }
    //   };
    //   fetchPermissionsData();
    // }
    this.getPaymentResults();
  }

  fetchPermissions = async (adminId) => {
    this.setState({ loading: true });

    const { token } = this.state;
    const { AdminViewAdminPermission } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, id: adminId };

      const response = await APICaller(
        AdminViewAdminPermission,
        method,
        body,
        contentType,
        token
      );

      if (response?.data?.status === 200) {
        this.setState({
          permissions: response.data.data.permissions,
          loading: false,
        });
      } else {
        console.error("Failed to load permissions. API Response:", response);
        this.setState({
          errorMsg: "Failed to load permissions.",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error Fetching Permissions:", error);
      this.setState({
        errorMsg: "Error fetching permissions.",
        loading: false,
      });
    }
  };

  getPaymentResults = async () => {
    const { search, token, currentPage, itemsPerPage, sort, startDate, endDate } = this.state;
    const { AdminGetPayments } = adminendPoints;

    // Construct query parameters
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
      ...(search && { search: search.trim() }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
    });

    const url = `${AdminGetPayments}?${queryParams}`;

    this.setState({ loading: true });

    try {
      const response = await APICaller(
        url,
        "GET",
        "",
        "application/json",
        token
      );
      console.log(response.status, " paymentspaymentspayments");
      if (response.status === 200) {
        const { payments, pagination } = response.data.data ;
       
        this.setState({ 
          results: payments, 
          totalItems: pagination.totalItems, 
          loading: false 
        });
      } else {
        this.setState({
          emailErrorMsg: "Invalid Request",
          loading: false,
        });
        setTimeout(() => this.setState({ emailErrorMsg: "" }), 10000);
      }
    } catch (error) {
      console.error("Error fetching results:", error);
      this.setState({ loading: false });
    }
  };

  handleSearchChange = (e) => {
    let value = e.target.value.replace(/\s+/g, " "); // Replace multiple spaces with a single space

    this.setState({ search: value });

    if (this.searchDebounce) clearTimeout(this.searchDebounce);

    if (value.length >= 3 || value.length === 0) {
      this.searchDebounce = setTimeout(() => {
        this.setState({ currentPage: 1 }, this.getPaymentResults);
      }, 300);
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getPaymentResults();
    });
  };

  handleSort = (sortField) => {
    this.setState({ sort: sortField, currentPage: 1 }, () => {
      this.getPaymentResults();
    });
  };

  handleToggleCheckBox = async (userId, currentRole) => {
    const { token } = this.state;
    const { OnboardingStatusUpdate } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newRole = currentRole === "onboarding" ? "expert" : "onboarding";

    const body = {
      userId,
      role: newRole,
    };

    try {
      const response = await APICaller(
        OnboardingStatusUpdate,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;

      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((onBoardingUser) =>
            onBoardingUser._id === userId
              ? { ...onBoardingUser, role: newRole }
              : onBoardingUser
          ),
          successMsg: `User's role has been successfully updated to ${newRole}`,
          errorMsg: "",
        }));

        setTimeout(() => {
          this.setState({ successMsg: "" });
          this.getPaymentResults();
        }, 2000);
      } else {
        console.error("Failed to update offer status:", message);
        this.setState({
          errorMsg: message,
          successMsg: "",
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating the user's role.",
        successMsg: "",
      });
    }
  };

  toggleVisibility = (payment) => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
      selectedPayment: payment
    }));
  };

  handleCloseContent = () => {
    this.setState({ isVisible: false });
  };

  handleDateChange = () => {
    this.setState({ currentPage: 1 }, this.getPaymentResults);
  };

  render() {
    const {
      results,
      totalItems,
      currentPage,
      itemsPerPage,
      loading,
      emailErrorMsg,
      search,
      sort,
      successMsg,
      errorMsg,
      permissions,
      isVisible,
      selectedPayment,
      startDate,
      endDate,
    } = this.state;

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Payment Management
                      </li>
                    </ol>
                    <h4 className="card-title mb-0">Payment Management</h4>
                  </div>
                </div>
              </div>
            </div>
            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {successMsg && (
              <div className="alert alert-success" role="alert">
                {successMsg}
              </div>
            )}

            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}

            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon me-3">
                        <input
                          type="date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => {
                            this.setState({ startDate: e.target.value }, this.handleDateChange);
                          }}
                        />
                      </div>
                      <div className="form-control-group-icon me-3">
                        <input
                          type="date"
                          className="form-control"
                          value={endDate}
                          min={startDate}
                          onChange={(e) => {
                            this.setState({ endDate: e.target.value }, this.handleDateChange);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <table className="table align-middle mb-4">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Transaction ID</th>
                      <th>User</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Payment Mode</th>
                      <th>Created Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.length === 0 ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No payments available.
                        </td>
                      </tr>
                    ) : (
                      results.map((item, index) => {
                        const serialNumber = (currentPage - 1) * itemsPerPage + (index + 1);
                        return (
                          <tr key={index}>
                            <td>{serialNumber}.</td>
                            <td>{item.txnid}</td>
                            <td>
                              {item.userId ? (
                                <>
                                  {item.userId.name || 'N/A'}<br />
                                  <small>{item.userId.email}</small>
                                </>
                              ) : (
                                'Guest User'
                              )}
                            </td>
                            <td>₹{item.amount}</td>
                            <td>
                              <span style={{ color:item.status === 'success' ? 'white' : 'white' }} className={`badge  ${item.status === 'success' ? 'bg-success' : 'bg-danger'}`}>
                                {item.status}
                              </span>
                            </td>
                            <td>{item.mode}</td>
                            <td>{formatDate(item.createdAt)}</td>
                            <td className="text-center">
                              <Link
                                to="#"
                                className="icon-btn"
                                onClick={() => this.toggleVisibility(item)}
                              >
                                <img
                                  src="assets/img/icon-view.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}

                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => this.handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>

                  {currentPage < totalPages && (
                    <button
                      className="pagination-next ms-2"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`right-side-popup ${isVisible ? "" : "right-side-popup-hide"}`}>
          <div className="right-side-popup-close" onClick={this.handleCloseContent}>
            <i className="fa-solid fa-angles-right"></i>
          </div>
          {selectedPayment && (
            <div className="right-side-popup-inner">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Transaction ID: #{selectedPayment.txnid}</h4>
                    <p className="mb-0 tc-5">
                      Created: {formatDate(selectedPayment.createdAt)}
                    </p>
                  </div>
                  <div className="ms-auto">
                    <span className={`badge ${selectedPayment.status === 'success' ? 'bg-success' : 'bg-danger'}`}>
                      {selectedPayment.status}
                    </span>
                  </div>
                </div>

                <h5 className="mb-3 tc-8">Payment Information</h5>
                <p className="mb-2 tc-5">
                  Amount: <span className="tc-6">₹{selectedPayment.amount}</span>
                </p>
                <p className="mb-2 tc-5">
                  Payment Mode: <span className="tc-6">{selectedPayment.mode}</span>
                </p>
                <p className="mb-2 tc-5">
                  Transaction Fee: <span className="tc-6">₹{selectedPayment.transaction_fee}</span>
                </p>

                <h5 className="mb-3 tc-8 mt-4">User Details</h5>
                <p className="mb-2 tc-5">
                  Name: <span className="tc-6">{selectedPayment.firstname}</span>
                </p>
                <p className="mb-2 tc-5">
                  Email: <span className="tc-6">{selectedPayment.email}</span>
                </p>
                <p className="mb-2 tc-5">
                  Phone: <span className="tc-6">{selectedPayment.phone}</span>
                </p>

                <h5 className="mb-3 tc-8 mt-4">Payment Details</h5>
                {selectedPayment.card_no && (
                  <p className="mb-2 tc-5">
                    Card Number: <span className="tc-6">{selectedPayment.card_no}</span>
                  </p>
                )}
                <p className="mb-2 tc-5">
                  Bank Reference: <span className="tc-6">{selectedPayment.bank_ref_no}</span>
                </p>
                <p className="mb-2 tc-5">
                  Payment Source: <span className="tc-6">{selectedPayment.payment_source}</span>
                </p>
                <p className="mb-2 tc-5">
                  PG Type: <span className="tc-6">{selectedPayment.PG_TYPE}</span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.permissions, // Update according to your Redux state structure
});

export default connect(mapStateToProps)(UserManagement);

import React, { Component } from "react";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AddBanner extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      position: "",
      description: "",
      banner_name: "",
      fileName: "",
      selectedFile: null,
      previewURL: null,
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      districtResults: [],
      token: retrievedObject ? retrievedObject.token : "",
      status: "In-Active",
      loading: false,
      banner_type: "",
      cityResults: [],
      selectedCity: "",
      stylistResults: [],
      loadingStylist: false,
      selectedStylist: "",
      startDateTime: null,
      endDateTime: null,
      selectedStylistHours: null,
      youtube_link: "",
    };
  }

  componentDidMount = async () => {
    const { AdminCityActiveList } = adminendPoints;
    const response = await APICaller(AdminCityActiveList, "GET", {}, {}, this.state.token);
    // console.log("response", response);
    this.setState({
      cityResults: response.data.citys,
    });
  }

  loadStylistByCity = async (city_id) => {
    const { AdminGetAllExpertsByCity } = adminendPoints;
    const response = await APICaller(AdminGetAllExpertsByCity + `?id=${city_id}`, "GET", {}, {}, this.state.token);
    console.log("response", response);
    this.setState({
      stylistResults: response.data.users,
    });
  }

  validateForm = () => {
    const {
      banner_name,
      position,
      selectedFile,
      banner_type,
      description,
      startDateTime,
      endDateTime,
      selectedCity,
      selectedStylist,
      youtube_link
    } = this.state;

    const newErrors = {};

    // Basic required fields
    if (!banner_name?.trim()) {
      newErrors.banner_name = "Banner Name is required.";
    }

    if (!position) {
      newErrors.position = "Position is required.";
    }

    // if (!banner_type) {
    //   newErrors.banner_type = "Banner type is required.";
    // }

    if (!selectedFile) {
      newErrors.fileName = "Banner image is required.";
    }

    if (!description?.trim()) {
      newErrors.description = "Description is required.";
    }

    if (!startDateTime) {
      newErrors.startDateTime = "Start date and time is required.";
    }

    if (!endDateTime) {
      newErrors.endDateTime = "End date and time is required.";
    }

    // Validate that end date is after start date
    if (startDateTime && endDateTime && endDateTime <= startDateTime) {
      newErrors.endDateTime = "End date must be after start date.";
    }

    // Additional validation for "Refer to Stylist" type (banner_type === "1")
    if (banner_type === "1") {
      if (!selectedCity) {
        newErrors.city = "City selection is required for stylist referral.";
      }

      if (!selectedStylist) {
        newErrors.stylist = "Stylist selection is required for stylist referral.";
      }
    }

    // Add YouTube link validation
    if (banner_type === "2") {
      if (!youtube_link?.trim()) {
        newErrors.youtube_link = "YouTube video link is required.";
      } else {
        // Basic YouTube URL validation
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
        if (!youtubeRegex.test(youtube_link)) {
          newErrors.youtube_link = "Please enter a valid YouTube video link.";
        }
      }
    }

    console.log("newErrors", newErrors);

    return newErrors;
  };

  handleSubmit = async (e, reloadListing) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    this.setState({ loading: true });
    if (Object.keys(newErrors).length > 0) {
      this.setState(
        { errorMsg: "", successMsg: "", errors: newErrors, loading: false },
        () => {
          window.setTimeout(() => {
            this.setState({ errors: {} });
          }, 5000);
        }
      );
    } else {
      this.setState({ errors: {} });
      const {
        banner_name,
        selectedFile,
        token,
        description,
        startDateTime,
        endDateTime,
        position,
        banner_type,
        selectedCity,
        selectedStylist,
        youtube_link
      } = this.state;

      const { AdminAddBanner } = adminendPoints;
      const formData = new FormData();
      formData.append("banner_type", banner_type);
      formData.append("banner_for", "0");
      formData.append("banner_name", banner_name);
      formData.append("fileName", selectedFile);
      formData.append("description", description);
      formData.append("startDateTime", startDateTime);
      formData.append("endDateTime", endDateTime);
      formData.append("position", position);
      //   formData.append("city", selectedCity);
      //   formData.append("stylist", selectedStylist);

      if (this.state.startDateTime) {
        formData.append("startDateTime", this.state.startDateTime.toISOString());
      }
      if (this.state.endDateTime) {
        formData.append("endDateTime", this.state.endDateTime.toISOString());
      }

      if (banner_type === "2") {
        formData.append("youtube_link", youtube_link);
      }

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminAddBanner,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Services created successfully.",
              errorMsg: "",
            },
            () => {
              this.setState({
                banner_type: "",
                banner_name: "",
                selectedFile: null,
                previewURL: null,
                fileName: "",
                description: "",
                loading: false,
                startDate: "",
                endDate: "",
                position: "",
              });
              reloadListing();
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
                if (this.props.onCategoryInsert) {
                  this.props.onCategoryInsert();
                }
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This service name is already exist",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create service. Please try again.",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the service. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handlePositionChange = (e) => {
    this.setState({ position: e.target.value });
  };

  handleBannerTypeChange = (e) => {
    this.setState({ banner_type: e.target.value });
  };

  handleCityChange = (e) => {
    const cityId = e.target.value;
    this.setState({ selectedCity: cityId }, () => {
      if (cityId) {
        this.loadStylistByCity(cityId);
      } else {
        this.setState({ stylistResults: [] });
      }
    });
  };

  handleStylistChange = (e) => {
    const stylistId = e.target.value;
    const selectedStylist = this.state.stylistResults.find(
      (stylist) => stylist._id === stylistId
    );

    this.setState({
      selectedStylist: stylistId,
      selectedStylistHours: selectedStylist?.working_hours[0],
      startDateTime: null,
      endDateTime: null
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          selectedFile: file,
          previewURL: reader.result,
          fileName: file.name,
          errors: {
            ...this.state.errors,
            fileName: null // Clear the error when file is uploaded
          }
        });
      };
      reader.readAsDataURL(file);
    }
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
      errors: {
        ...this.state.errors,
        fileName: "Banner image is required." // Show error when file is removed
      }
    });
  };

  // Helper function to convert time string to Date object
  parseTime = (timeStr, date) => {
    if (!timeStr) return null;
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':');
    let hour = parseInt(hours);

    if (period === 'PM' && hour !== 12) hour += 12;
    if (period === 'AM' && hour === 12) hour = 0;

    const newDate = new Date(date);
    newDate.setHours(hour, parseInt(minutes), 0);
    return newDate;
  };

  // Get available time range for selected day
  getTimeRangeForDate = (date) => {
    if (!this.state.selectedStylistHours) return null;

    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayName = days[date.getDay()].toLowerCase();
    const daySchedule = this.state.selectedStylistHours[dayName];

    if (daySchedule.open !== 'yes') return null;

    return {
      start: this.parseTime(daySchedule.from, date),
      end: this.parseTime(daySchedule.to, date)
    };
  };

  render() {
    const {
      previewURL,
      banner_name,
      position,
      errors,
      successMsg,
      errorMsg,
      token,
      description,
      status,
      loading,
      banner_type,
      cityResults,
      youtube_link,
    } = this.state;

    const { isVisibleAdd, handleCloseAddContent, reloadListing } = this.props;

    return (
      <div>
        <div
          className={`right-side-popup right-side-popup-w75 ${isVisibleAdd ? "" : "right-side-popup-hide"
            }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseAddContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Add Stylist App Banner</h4>
                    <p className="mb-0 tc-5">Add Banner</p>
                  </div>
                  <div className="ms-auto"> </div>
                </div>

                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={(e) => this.handleSubmit(e, reloadListing)}
                >
                  {errorMsg && (
                    <div className="alert alert-danger" role="alert">
                      {errorMsg}
                    </div>
                  )}

                  {successMsg && (
                    <div className="alert alert-success" role="alert">
                      {successMsg}
                    </div>
                  )}

                  {loading && (
                    <div className="d-flex justify-content-center align-items-center loader">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col">
                      <div className="upload-img-box">
                        <i className="fa-solid fa-file-arrow-up"></i>
                        <p className="mb-0">Upload Image *</p>
                        <input
                          type="file"
                          className={`form-control ${errors.fileName ? 'is-invalid' : ''}`}
                          id="fileName"
                          placeholder="Absence through"
                          name="fileName"
                          accept="image/*"
                          onChange={this.handleFileSelect}
                        />
                        {previewURL ? (
                          <div className="mt-2">
                            <img
                              src={previewURL}
                              alt="Banner Preview"
                              style={{ width: "100px", height: "100px", objectFit: "cover" }}
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn-sm ms-2"
                              onClick={this.handleFileRemove}
                            >
                              Remove
                            </button>
                          </div>
                        ) : (
                          errors.fileName && (
                            <div className="callout callout-danger mt-2">
                              <p className="error">{errors.fileName}</p>
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    <div className="col-lg-7">
                      <label htmlFor="banner_name" className="form-label">
                        Banner Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="banner_name"
                        placeholder="Banner Name"
                        name="banner_name"
                        value={banner_name}
                        onChange={this.handleInputChange}
                      />
                      {errors && (
                        <div className="callout callout-danger">
                          {errors.banner_name && (
                            <p className="error">{errors.banner_name}</p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col">
                      <label htmlFor="Position" className="form-label">
                        Position
                      </label>
                      <select
                        className="form-select"
                        id="position"
                        aria-label="Default select example"
                        value={position}
                        onChange={this.handlePositionChange}
                      >
                        <option value="">Select Position</option>
                        <option value="Top">Top</option>
                        <option value="Middle">Middle</option>
                        <option value="Bottom">Bottom</option>
                      </select>
                      {errors.position && (
                        <div className="callout callout-danger">
                          <p className="error">{errors.position}</p>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12 d-flex gap-2">
                      <div className="col">
                        <label htmlFor="banner_type" className="form-label">
                          Banner Type
                        </label>
                        <select
                          className="form-select"
                          id="banner_type"
                          aria-label="Default select example"
                          value={banner_type}
                          onChange={this.handleBannerTypeChange}
                        >
                          <option value="">Select Banner Type</option>
                          <option value="0">Show Only</option>
                          <option value="2">Youtube</option>
                        </select>
                        {errors.banner_type && (
                          <div className="callout callout-danger">
                            <p className="error">{errors.banner_type}</p>
                          </div>
                        )}
                      </div>


                      <div className="col">
                        {
                          banner_type === "2" && (
                            <div>
                              <label htmlFor="youtube_link" className="form-label">
                                Youtube Link
                              </label>
                              <input type="text" className="form-control" id="youtube_link" placeholder="Youtube Link" name="youtube_link" value={youtube_link} onChange={this.handleInputChange} />
                              {errors.youtube_link && (
                                <div className="callout callout-danger">
                                  <p className="error">{errors.youtube_link}</p>
                                </div>
                              )}
                            </div>
                          )
                        }
                      </div>


                    </div>





                    <div className="col-lg-6 d-flex flex-column gap-2">
                      <label htmlFor="startDateTime" className="form-label">
                        Start Date & Time *
                      </label>
                      <DatePicker
                        selected={this.state.startDateTime}
                        onChange={(date) => this.setState({ startDateTime: date })}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="form-control"
                        minDate={new Date()}
                        placeholderText="Select start date and time"
                      />
                      {errors.startDateTime && (
                        <div className="callout callout-danger">
                          <p className="error">{errors.startDateTime}</p>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6 d-flex flex-column gap-2">
                      <label htmlFor="endDateTime" className="form-label">
                        End Date & Time *
                      </label>
                      <DatePicker
                        selected={this.state.endDateTime}
                        onChange={(date) => this.setState({ endDateTime: date })}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="form-control"
                        minDate={this.state.startDateTime ? this.state.startDateTime : new Date()}
                        placeholderText="Select end date and time"
                      />
                      {errors.endDateTime && (
                        <div className="callout callout-danger">
                          <p className="error">{errors.endDateTime}</p>
                        </div>
                      )}
                    </div>



                    <div className="col-lg-12">
                      <label htmlFor="description" className="form-label">
                        Description *
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        placeholder="Service Description"
                        name="description"
                        value={description}
                        onChange={this.handleInputChange}
                      ></textarea>
                      {errors.description && (
                        <div className="callout callout-danger">
                          <p className="error">{errors.description}</p>
                        </div>
                      )}
                    </div>


                    <div className="col-lg-12">
                      <button type="submit" className="btn-blue">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`right-side-popup-backdrop ${isVisibleAdd ? "" : "d-none"
            }`}
        ></div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(AddBanner);

import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";

const AddBulkNotification = ({ adminDetails, onAddSuccess, isVisibleAdd, handleCloseAddContent }) => {
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const token = retrievedObject ? retrievedObject.token : "";

  // State declarations
  const [formData, setFormData] = useState({
    title: "",
    message: "",
    selectedCities: [],
    deliveryMethod: "",
    status: "Active",
  });

  const [cities, setCities] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Fetch cities on component mount
  useEffect(() => {
    fetchAllCities();
  }, []);

  const fetchAllCities = async () => {
    const method = "GET";
    const contentType = "application/json";

    try {
      const cityResponse = await APICaller(
        adminendPoints.AdminCityList,
        method,
        "",
        contentType,
        token
      );

      const { citys, status } = cityResponse.data;
      if (status === 200) {
        const citiesData = citys.map((city) => ({
          value: city._id,
          label: city.city_name,
        }));
        setCities(citiesData);
      }
    } catch (error) {
      console.error("Failed to fetch cities:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const { title, message, selectedCities, deliveryMethod } = formData;

    if (!title) newErrors.title = "Title is required.";
    if (!message) newErrors.message = "Message is required.";
    if (!selectedCities || selectedCities.length === 0) newErrors.selectedCities = "At least one city is required.";
    if (!deliveryMethod) newErrors.deliveryMethod = "Delivery method is required.";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    setErrors({});

    const body = {
      title: formData.title,
      message: formData.message,
      cities: formData.selectedCities.map(city => city.value),
      deliveryMethod: formData.deliveryMethod,
      status: formData.status,
      adminId: "64d25bf44a62e3dbdddc6f74",
      userRole: "expert",
      userStatus: "Active"
    };
    const { AdminSendStylistBulkNotifications } = adminendPoints; // Update with your endpoint
    try {
      const response = await APICaller(
        AdminSendStylistBulkNotifications,
        "POST",
        body,
        "application/json",
        token
      );

      if (response.data.status === 200) {
        setSuccessMsg("Bulk notification created successfully.");
        setErrorMsg("");

        setTimeout(() => {
          setSuccessMsg("");
          onAddSuccess();
          handleCloseAddContent();
        }, 3000);
      } else {
        setErrorMsg("Failed to create bulk notification. Please try again.");
      }
    } catch (error) {
      console.error("Error making data call --> ", error);
      setErrorMsg("An error occurred while creating the notification. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleEditorChange = (e) => {
    setFormData(prev => ({ ...prev, message: e.target.value }));
  };

  const handleCityChange = (selectedOptions) => {
    setFormData(prev => ({
      ...prev,
      selectedCities: selectedOptions || []
    }));
  };

  return (
    <div>
      <div className={`right-side-popup right-side-popup-w75 ${isVisibleAdd ? "" : "right-side-popup-hide"}`}>
        <div className="right-side-popup-close" onClick={handleCloseAddContent}>
          <i className="fa-solid fa-angles-right"></i>
        </div>
        <div className="">
          <div className="card mb-4">
            <div style={{ height: "100vh" }} className="card-body">
              <div className="d-flex align-items-center mb-4">
                <h4 className="card-title mb-0">Add Bulk Notification</h4>
              </div>

              <form className="form-area row align-items-center mb-5" onSubmit={handleSubmit}>
                {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
                {successMsg && <div className="alert alert-success">{successMsg}</div>}
                {loading && (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <div className="form-area1 row g-4">
                  <div className="col-lg-12">
                    <label htmlFor="title" className="form-label">Title</label>
                    <input
                      type="text"
                      className={`form-control ${errors.title ? "is-invalid" : ""}`}
                      id="title"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      placeholder="Enter the title here..."
                    />
                    {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                  </div>

                  <div className="col-lg-12">
                    <label htmlFor="message" className="form-label">Message</label>
                    <div className={`quill-editor ${errors.message ? "is-invalid" : ""}`}>
                     <textarea
                     className="form-control"
                      value={formData.message}
                      onChange={handleEditorChange}
                      placeholder="Write your message here..."
                     />
                    </div>
                    {errors.message && <p className="invalid-feedback">{errors.message}</p>}
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="city" className="form-label">Select Cities:</label>
                      <Select
                        id="city"
                        value={formData.selectedCities}
                        onChange={handleCityChange}
                        options={cities}
                        isMulti={true}
                        className={errors.selectedCities ? "is-invalid" : ""}
                        placeholder="Select cities..."
                      />
                      {errors.selectedCities && (
                        <div className="invalid-feedback">{errors.selectedCities}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="deliveryMethod" className="form-label">Delivery Method</label>
                    <select
                      className={`form-control ${errors.deliveryMethod ? "is-invalid" : ""}`}
                      id="deliveryMethod"
                      name="deliveryMethod"
                      value={formData.deliveryMethod}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Value</option>
                      <option value="Promotional">Promotional</option>
                    </select>
                    {errors.deliveryMethod && (
                      <div className="invalid-feedback">{errors.deliveryMethod}</div>
                    )}
                  </div>
                </div>

                <div className="mt-4 ">
                  <button type="submit" className="btn-blue">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`right-side-popup-backdrop ${isVisibleAdd ? "" : "d-none"}`}></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(AddBulkNotification);

import React, { Component } from "react";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class AddService extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      serviceFor: "Both",
      description: "",
      service_name: "",
      fileName: "",
      selectedFile: null,
      previewURL: null,
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      districtResults: [],
      token: retrievedObject ? retrievedObject.token : "",
      status: "In-Active",
      loading: false,
    };
  }

  validateForm = () => {
    const { service_name } = this.state;
    const newErrors = {};

    if (!service_name) {
      newErrors.service_name = "Service Name is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e, reloadListing) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    this.setState({ loading: true });
    if (Object.keys(newErrors).length > 0) {
      this.setState(
        { errorMsg: "", successMsg: "", errors: newErrors, loading: false },
        () => {
          window.setTimeout(() => {
            this.setState({ errors: {} });
          }, 5000);
        }
      );
    } else {
      this.setState({ errors: {} });
      const {
        serviceFor,
        service_name,
        selectedFile,
        token,
        description,
        status,
      } = this.state;

      const { AdminAddService } = adminendPoints;
      const formData = new FormData();
      formData.append("service_name", service_name);
      formData.append("fileName", selectedFile);
      formData.append("description", description);
      formData.append("status", status);
      formData.append("serviceFor", serviceFor);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminAddService,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Services created successfully.",
              errorMsg: "",
            },
            () => {
              this.setState({
                service_name: "",
                selectedFile: null,
                previewURL: null,
                fileName: "",
                description: "",
                loading: false,
              });
              reloadListing();
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
                if (this.props.onCategoryInsert) {
                  this.props.onCategoryInsert();
                }
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This service name is already exist",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create service. Please try again.",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the service. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handleStatusChange = (e) => {
    this.setState({ status: e.target.value });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name, // Set the fileName state to the actual file name
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  render() {
    const {
      previewURL,
      service_name,
      errors,
      successMsg,
      errorMsg,
      token,
      description,
      status,
      loading,
      serviceFor,
    } = this.state;

    const { isVisibleAdd, handleCloseAddContent, reloadListing } = this.props;

    return (
      <div>
        <div
          className={`right-side-popup right-side-popup-w75 ${
            isVisibleAdd ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseAddContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Add Category Information</h4>
                  </div>
                  <div className="ms-auto"> </div>
                </div>
                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={(e) => this.handleSubmit(e, reloadListing)}
                >
                  {errorMsg && (
                    <div className="alert alert-danger" role="alert">
                      {errorMsg}
                    </div>
                  )}
                  {successMsg && (
                    <div className="alert alert-success" role="alert">
                      {successMsg}
                    </div>
                  )}
                  {loading && (
                    <div className="d-flex justify-content-center align-items-center loader">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col">
                      <div className="upload-img-box">
                        <i className="fa-solid fa-file-arrow-up"></i>
                        <p className="mb-0">Upload Image</p>
                        <input
                          type="file"
                          className="form-control"
                          id="fileName"
                          placeholder="Absence through"
                          name="fileName"
                          accept="image/*"
                          onChange={this.handleFileSelect}
                        />
                        {previewURL && (
                          <div>
                            <img
                              src={previewURL}
                              alt="Category Preview"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <button
                              type="button"
                              onClick={this.handleFileRemove}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <label htmlFor="service_name" className="form-label">
                        Category Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="service_name"
                        placeholder="Enter Category Name"
                        name="service_name"
                        value={service_name}
                        onChange={this.handleInputChange}
                      />
                      {errors && (
                        <div className="callout callout-danger">
                          {errors.service_name && (
                            <p className="error">{errors.service_name}</p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col d-none">
                      <label htmlFor="status" className="form-label">
                        Status
                      </label>
                      <select
                        className="form-select"
                        id="status"
                        aria-label="Default select example"
                        value={status}
                        onChange={this.handleStatusChange}
                      >
                        <option value="Active">Active</option>
                        <option value="In-Active">In-Active</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="serviceFor">Category For:</label>
                      <select
                        id="serviceFor"
                        name="serviceFor"
                        className="form-control"
                        value={serviceFor}
                        onChange={this.handleInputChange}
                      >
                        <option value="Male">Boys</option>
                        <option value="Female">Girls</option>
                        {/* <option value="Both">Both</option> */}
                      </select>
                    </div>  

                    <div className="col-lg-12">
                      <label htmlFor="description" className="form-label">
                        Category Description
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        placeholder="Category Description"
                        name="description"
                        value={description}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>
                    <div className="col-lg-12">
                      <button type="submit" className="btn-blue">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`right-side-popup-backdrop ${
            isVisibleAdd ? "" : "d-none"
          }`}
        ></div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(AddService);

import React, { Component } from "react";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class AddTimeEntry extends Component {
  constructor(props) {
    super(props);
    const retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      name: "",
      hours: 0,
      minutes: 0,
      description: "",
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      loading: false
    };
  }

  validateForm = () => {
    const { name, hours, minutes, description } = this.state;
    const errors = {};

    if (!name.trim()) errors.name = "Name is required";
    if (hours < 0) errors.hours = "Hours must be positive";
    if (minutes < 0 || minutes > 59) errors.minutes = "Minutes must be between 0 and 59";
    if (!description.trim()) errors.description = "Description is required";

    return errors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validateForm();

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ loading: true });

    try {
      const { name, hours, minutes, description, token } = this.state;
      const response = await APICaller(
        adminendPoints.AdminTimeManageCreate,
        "POST",
        { name, hours, minutes, description },
        "application/json",
        token
      );

      if (response.data.success) {
        this.props.onSuccess();
      }
    } catch (error) {
      console.error("Error creating time entry:", error);
      this.setState({
        errors: { submit: "Failed to create time entry" }
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { isVisible, handleClose } = this.props;
    const { name, hours, minutes, description, errors, loading } = this.state;

    return (
      <div>
        <div className={`right-side-popup ${isVisible ? "" : "right-side-popup-hide"}`}>
          <div className="right-side-popup-close" onClick={handleClose}>
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card-body">
              <h4 className="card-title mb-4">Add Time Entry</h4>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={this.handleInputChange}
                  />
                  {errors.name && <div className="text-danger">{errors.name}</div>}
                </div>

                <div className="row mb-3">
                  <div className="col-6">
                    <label>Hours</label>
                    <input
                      type="number"
                      className="form-control"
                      name="hours"
                      value={hours}
                      onChange={this.handleInputChange}
                      min="0"
                    />
                    {errors.hours && <div className="text-danger">{errors.hours}</div>}
                  </div>
                  <div className="col-6">
                    <label>Minutes</label>
                    <input
                      type="number"
                      className="form-control"
                      name="minutes"
                      value={minutes}
                      onChange={this.handleInputChange}
                      min="0"
                      max="59"
                    />
                    {errors.minutes && <div className="text-danger">{errors.minutes}</div>}
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    name="description"
                    value={description}
                    onChange={this.handleInputChange}
                    rows="3"
                  />
                  {errors.description && <div className="text-danger">{errors.description}</div>}
                </div>

                {errors.submit && <div className="alert alert-danger">{errors.submit}</div>}

                <button
                  type="submit"
                  className="btn-blue"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className={`right-side-popup-backdrop ${isVisible ? "" : "d-none"}`}></div>
      </div>
    );
  }
}

export default AddTimeEntry; 
import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import Select from "react-select";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";
import { RxCross1 } from "react-icons/rx";
import { BsCardImage } from "react-icons/bs";
const CreateCampaign = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [campaignType, setCampaignType] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [campaignCode, setCampaignCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [acceptanceDate, setAcceptanceDate] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [subServicesWithUsersNumber, setSubServicesWithUsersNumber] = useState([]);
  const [campaign, setCampaign] = useState({
    service_id: '',
    sub_service_id: '',
  });
  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [usersResults, setUsersResults] = useState([]);
  const [campaignPrice, setCampaignPrice] = useState("");
  const [token, setToken] = useState(getLocalStorageItem("myStylistAdmin") ? getLocalStorageItem("myStylistAdmin").token : "");
  const [Gender, setGender] = useState("");
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };
  useEffect(() => {
    fetchCity();
  }, []);
  console.log(selectedCity, "cities");
  const fetchCity = async () => {
    const { token } = props;
    const { AdminCityListNew } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token };
      const response = await APICaller(
        AdminCityListNew,
        method,
        JSON.stringify(body),
        contentType,
        token
      );

      const { data, status } = response.data;

      if (status === 200) {
        const citiesData = data.map((city) => ({
          value: city.city_id,
          label: city.city_name,
        }));

        const selectedCities = citiesData || [];
        setCities(selectedCities);
      } else {
        console.error("Failed to fetch cities");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const getAllServices = async (Gender) => {
    const { token } = props;
    const { AdminServicesListMobile } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const stateUrl = `${AdminServicesListMobile}?Gender=${Gender}`;

    try {
      const servicesResponse = await APICaller(
        stateUrl,
        method,
        "",
        contentType,
        token
      );
      const { services, status } = servicesResponse.data;
      if (status === 200) {
        setServices(services);
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  const fetchSubServices = async (serviceIds, Gender) => {
    const { token } = props;
    const { AdminSubServicesListMobile } = adminendPoints;
    const method = "POST";
    const serviceIdsString = serviceIds.join(",");

    const body = {
      serviceIds: serviceIdsString,
      Gender
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminSubServicesListMobile,
        method,
        body,
        contentType,
        token
      );
      const { status, subServices } = response.data;
      if (status === 200) {
        const subServicesWithUsersNumber = subServices.map((subService) => ({
          ...subService,
          selected: false,
          usersNumber: 0,
        }));

        setSubServicesWithUsersNumber(subServicesWithUsersNumber);
      } else {
        setErrorMsg("Failed to fetch sub-services. Please try again.");
        setSuccessMsg("");
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      setErrorMsg(
        "An error occurred while creating the category. Please try again.");
      setSuccessMsg("");
    }
  };
  const handleSelectChange = (name, selectedOptions, Gender) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    const commaSeparatedValues = selectedValues.join(",");
    setCampaign((prevState) => ({
      ...prevState,
      [name]: commaSeparatedValues,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
      setErrorMsg("");
    }

    if (name === "service_id" && selectedValues.length > 0) {
      fetchSubServices(selectedValues, Gender);
    }
  };

  const toggleSubService = (subServiceId) => {
    const updatedSubServicesWithUsersNumber = subServicesWithUsersNumber.map(
      (subService) => ({
        ...subService,
        selected: subService._id === subServiceId,
        usersNumber: subService._id === subServiceId ? subService.usersNumber : 0
      })
    );
    setSubServicesWithUsersNumber(updatedSubServicesWithUsersNumber);

    if (errors.sub_service_id) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        sub_service_id: null,
      }));
      setErrorMsg("");
    }
  };

  const handleUsersNumberChange = (subServiceId, usersNumber) => {
    const updatedSubServicesWithUsersNumber = subServicesWithUsersNumber.map(
      (subService) => ({
        ...subService,
        usersNumber:
          subService._id === subServiceId
            ? parseFloat(usersNumber)
            : subService.usersNumber,
      })
    );

    setSubServicesWithUsersNumber(updatedSubServicesWithUsersNumber);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!title) {
      newErrors.title = "Campaign Title is required.";
    }
    if (!selectedFile) {
      newErrors.selectedFile = "Banner Image is required.";
    }

    if (!description) {
      newErrors.description = "Description is required.";
    }

    if (!campaignCode) {
      newErrors.campaignCode = "Campaign Code is required.";
    }

    if (!startDate) {
      newErrors.startDate = "Start Date is required.";
    }

    if (!endDate) {
      newErrors.endDate = "End Date is required.";
    }

    if (!campaign.service_id) {
      newErrors.service_id = "Service Name is required.";
    }

    const selectedSubServices = subServicesWithUsersNumber.filter(
      (subService) => subService.selected
    );
    if (selectedSubServices.length === 0) {
      newErrors.sub_service_id = "At least one Sub-Service with price is required.";
    }

    if (!selectedCity || selectedCity.length === 0) {
      newErrors.selectedCity = "Select City is required.";
    }

    if (!campaignType) {
      newErrors.campaignType = "Campaign Type is required.";
    }

    if (!campaignPrice) {
      newErrors.campaignPrice = "Campaign Price is required.";
    }

    return newErrors;
  };

  const handleCityChange = (selectedOptions) => {
    setSelectedCity(selectedOptions);

    if (errors.selectedCity) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedCity: null,
      }));
      setErrorMsg("");
    }

    if (selectedOptions && selectedOptions.length > 0) {
      const selectedServiceIds = campaign.service_id;
      const selectedSubServiceIds = subServicesWithUsersNumber
        .filter((subService) => subService.selected)
        .map((subService) => subService._id);

      const cityIds = selectedOptions.map(option => option.value);
      getUserList(
        selectedServiceIds,
        selectedSubServiceIds,
        cityIds
      );
    }
  };

  const getUserList = async (
    selectedServiceIds,
    selectedSubServiceIds,
    selectedCityIds
  ) => {
    const { token } = props;
    const { AdminGetUsersByServiceAndSubService } = adminendPoints;
    const method = "POST";

    const body = {
      serviceId: Array.isArray(selectedServiceIds)
        ? selectedServiceIds
        : [selectedServiceIds],
      subServiceId: selectedSubServiceIds,
      city: selectedCityIds,
    };
    const contentType = "application/json";

    try {
      const userResponse = await APICaller(
        AdminGetUsersByServiceAndSubService,
        method,
        body,
        contentType,
        token
      );

      const { users, status } = userResponse.data;
      if (status === 200) {
        setUsersResults(users.map((user) => ({ ...user, selected: true })));
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedFile(file);
      setPreviewURL(reader.result);
      setFileName(file.name);
    };
    reader.readAsDataURL(file);

    if (file) {
      errors.selectedFile = "";
    }
  };

  const handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    setSelectedFile(null);
    setPreviewURL(null);
    setFileName("");
  };

  const handleUserClick = (userId) => {
    const updatedUsers = usersResults.map((user) =>
      user._id === userId ? { ...user, selected: !user.selected } : user
    );

    setUsersResults(updatedUsers);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCampaign((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
      setErrorMsg("");
    }

    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'campaignCode':
        setCampaignCode(value);
        break;
      case 'startDate':
        setStartDate(value);
        break;
      case 'endDate':
        setEndDate(value);
        break;
      case 'acceptanceDate':
        setAcceptanceDate(value);
        break;
      case 'termsAndConditions':
        setTermsAndConditions(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setErrorMsg("Please fill in all required fields.");
      setSuccessMsg("");
      return;
    }

    setLoading(true);
    const selectedUserIds = usersResults
      .filter((user) => user.selected)
      .map((user) => user._id);
    const formData = new FormData();
    const { AdminAddCampaign } = adminendPoints;

    // Add selected sub-services and their prices
    const selectedSubServices = subServicesWithUsersNumber.filter(
      (subService) => subService.selected
    );

    selectedSubServices.forEach((subService, index) => {
      formData.append(`sub_services[${index}]`, subService._id);
      formData.append(`evailCapacity`, subService.usersNumber);
    });

    // Add selected users
    selectedUserIds.forEach((userId, index) => {
      formData.append(`userIds[${index}]`, userId);
    });

    // Add all other form fields
    const CityIds = []
    const cityIds = selectedCity.map(city => {
      CityIds.push(city.value)
    });
    console.log(CityIds, "cityIdscityIdscityIds");
    formData.append("cities", CityIds);
    formData.append("services[]", campaign.service_id);
    formData.append("fileName", selectedFile);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("campaignCode", campaignCode);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("acceptanceDate", acceptanceDate);
    formData.append("campaignType", campaignType);
    formData.append("gender", Gender);
    formData.append("termsAndConditions", termsAndConditions);

    // Add campaign price to formData
    formData.append("campaignPrice", campaignPrice);

    try {
      const response = await APICaller(
        AdminAddCampaign,
        "POST",
        formData,
        {},
        token
      );

      const { status } = response.data;

      if (status === 200) {
        setSuccessMsg("Campaign created successfully.");
        setErrorMsg("");
        setRedirect(true);
      } else if (status === 409) {
        setErrorMsg("This Campaign Code already exists");
        setSuccessMsg("");
      } else {
        setErrorMsg("Failed to create campaign. Please try again.");
        setSuccessMsg("");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMsg("Failed to submit form. Please try again.");
      setSuccessMsg("");
    }

    setLoading(false);
  };

  if (redirect) {
    return <Navigate to="/ManageCampaigns" />;
  }

  const serviceOptions = services.map((service) => ({
    label: service.service_name,
    value: service._id,
  }));

  const handleCampaignTypeChange = (e) => {
    setCampaignType(e.target.value);

    if (errors.campaignType) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        campaignType: null,
      }));
      setErrorMsg("");
    }
  };

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="row">
            <div className="col-lg-12">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <h4 className="card-title mb-0">Generate Campaign</h4>
                    <div className="ms-auto">
                      <Link to="/ManageCampaigns" className="btn-light1 ms-2">
                        <i className="fa-solid fa-arrow-left-long me-2"></i>
                        <span>Back</span>
                      </Link>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="">
                      {errorMsg && (
                        <div className="alert alert-danger" role="alert">
                          {errorMsg}
                        </div>
                      )}
                      {successMsg && (
                        <div className="alert alert-success" role="alert">
                          {successMsg}
                        </div>
                      )}
                      {loading && (
                        <div className="d-flex justify-content-center align-items-center loader">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                      <div className="form-area1 row g-4">
                        <div className="col-lg-6">
                          <label htmlFor="service_id" className="form-label">
                            Select Gender
                          </label>
                          <Select
                            id="Gender"
                            name="Gender"
                            options={[{ label: "Boys", value: "Male" }, { label: "Girls", value: "Female" }]}
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setGender(selectedOption.value);
                              getAllServices(selectedOption.value);
                              setCampaign((prevState) => ({
                                sub_service_id: '',
                                service_id: '',
                              }));
                            }}
                          />
                          {errors.service_id && (
                            <p className="error">{errors.service_id}</p>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="service_id" className="form-label">
                            Category Name
                          </label>
                          <Select
                            id="service_id"
                            name="service_id"
                            options={serviceOptions}
                            isMulti={false}
                            value={serviceOptions.find(option =>
                              option.value === campaign.service_id
                            )}
                            onChange={(selectedOption) => {
                              setSelectedService(selectedOption.value);
                              handleSelectChange("service_id", [selectedOption]);
                              setSubServicesWithUsersNumber([]);
                            }}
                          />
                          {errors.service_id && (
                            <p className="error">{errors.service_id}</p>
                          )}
                        </div>
                        <div className="col-lg-12">
                          {subServicesWithUsersNumber.length > 0 ? (
                            <>
                              <label
                                htmlFor="sub_service_id"
                                className="form-label"
                              >
                                Service Name
                              </label>
                              <div className="sub-services-container">
                                <div className="row g-3">
                                  {subServicesWithUsersNumber?.map((subService) => (
                                    <div
                                      className="col-md-4 col-lg-3"
                                      key={subService._id}
                                    >
                                      <div className={`sub-service-card ${subService.selected ? 'selected' : ''}`}>
                                        <div onClick={() => toggleSubService(subService._id)} className="d-flex align-items-center mb-2">
                                          <input
                                            type="radio"
                                            className="form-check-input me-2"
                                            checked={subService.selected}
                                            onChange={() => toggleSubService(subService._id)}
                                            id={`service-${subService._id}`}
                                          />
                                          <label className="form-check-label mt-1" htmlFor={`service-${subService._id}`}>
                                            {subService.sub_service_name}
                                          </label>
                                        </div>
                                        <div className="price-input-wrapper">
                                          {/* <div className="currency-symbol" style={{ marginRight: '5px' }}>₹</div> */}
                                          <input
                                            type="number"
                                            className="form-control "
                                            value={subService.usersNumber}
                                            onChange={(e) => handleUsersNumberChange(subService._id, e.target.value)}
                                            placeholder="Enter users number"
                                            disabled={!subService.selected}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {
                                selectedService && (
                                  <p className="sub-service-card">No sub-services found</p>
                                )
                              }
                            </>
                          )}

                          {selectedService && errors.sub_service_id && (
                            <p className="error">{errors.sub_service_id}</p>
                          )}
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="title">Campaign Title</label>
                            <input
                              placeholder="Campaign Title"
                              type="text"
                              className="form-control"
                              id="title"
                              name="title"
                              value={title}
                              onChange={handleInputChange}
                            />
                            {errors.title && (
                              <p className="error">{errors.title}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label htmlFor="title">Campaign Code</label>
                            <input
                              placeholder="Campaign Code"
                              type="text"
                              className="form-control"
                              id="campaignCode"
                              name="campaignCode"
                              value={campaignCode}
                              onChange={handleInputChange}
                            />
                            {errors.campaignCode && (
                              <p className="error">{errors.campaignCode}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label htmlFor="title">Campaign Discount Price (₹)</label>
                            <input
                              placeholder="Enter Price"
                              type="number"
                              className="form-control"
                              id="campaignDiscountPrice"
                              name="campaignDiscountPrice"
                              value={campaignPrice}
                              onChange={(e) => {
                                setCampaignPrice(e.target.value);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  campaignPrice: null,
                                }));
                              }}
                            />
                            {errors.campaignPrice && (
                              <p className="error">{errors.campaignPrice}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              htmlFor="city"
                              className="form-label"
                            >
                              Select Cities
                            </label>
                            <Select
                              id="city"
                              isMulti
                              value={selectedCity}
                              onChange={handleCityChange}
                              options={cities}
                              className={errors.selectedCity ? "is-invalid" : ""}
                            />
                            {errors.selectedCity && (
                              <div className="invalid-feedback">
                                {errors.selectedCity}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="startDate">Start Date</label>
                            <input
                              placeholder="Start Date"
                              type="date"
                              className="form-control"
                              id="startDate"
                              name="startDate"
                              value={startDate}
                              min={getCurrentDate()}
                              onChange={handleInputChange}
                            />
                            {errors.startDate && (
                              <p className="error">{errors.startDate}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="endDate">End Date</label>
                            <input
                              placeholder="End Date"
                              type="date"
                              className="form-control"
                              id="endDate"
                              name="endDate"
                              value={endDate}
                              min={startDate || getCurrentDate()}
                              onChange={handleInputChange}
                            />
                            {errors.endDate && (
                              <p className="error">{errors.endDate}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="acceptanceDate">Acceptance Period</label>
                            <input
                              placeholder="Acceptance Date"
                              type="date"
                              className="form-control"
                              id="acceptanceDate"
                              name="acceptanceDate"
                              value={acceptanceDate}
                              min={getCurrentDate()}
                              max={endDate}
                              onChange={(e) => setAcceptanceDate(e.target.value)}
                            />
                            {errors.acceptanceDate && (
                              <p className="error">{errors.acceptanceDate}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <p> Upload Banner Image</p>
                          <div style={{ cursor: "pointer", height: "200px" }} className="upload-img-box ">
                            <i className="fa-solid fa-file-arrow-up"></i>
                            <p className="mb-0">Upload Image</p>
                            <input
                              type="file"
                              className="form-control"
                              id="fileName"
                              placeholder="Absence through"
                              name="fileName"
                              accept="image/*"
                              onChange={handleFileSelect}
                            />

                          </div>
                          {errors.selectedFile && (
                            <p className="error">{errors.selectedFile}</p>
                          )}
                        </div>
                        <div className=" col-lg-6">
                          <p> Banner Image Preview</p>
                          <div className=" image-show-box">
                            {previewURL ? (
                              <div>
                                <img
                                  src={previewURL}
                                  alt="Category Preview"
                                />
                                <button
                                  type="button"
                                  onClick={handleFileRemove}
                                >
                                  <RxCross1 />

                                </button>
                              </div>
                            ) : (
                              <div>
                                <BsCardImage style={{ fontSize: "100px" }} />
                              </div>
                            )}

                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea
                              placeholder="Description"
                              className="form-control"
                              id="description"
                              name="description"
                              value={description}
                              onChange={handleInputChange}
                            />
                            {errors.description && (
                              <p className="error">{errors.description}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="termsAndConditions">Terms and Conditions</label>
                            <textarea
                              placeholder="Terms and Conditions"
                              className="form-control"
                              id="termsAndConditions"
                              name="termsAndConditions"
                              value={termsAndConditions}
                              onChange={handleInputChange}
                            />
                            {errors.termsAndConditions && (
                              <p className="error">{errors.termsAndConditions}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="description">Campaign Type</label>
                            <select
                              className="form-control"
                              id="campaignType"
                              name="campaignType"
                              value={campaignType}
                              onChange={handleCampaignTypeChange}
                            >
                              <option value="">Select Campaign Type</option>
                              <option value="Marketing">Marketing</option>
                              <option value="Promotion">Promotion</option>
                              <option value="Event">Event</option>
                              <option value="Discount">Discount</option>
                              <option value="Other">Other</option>
                            </select>
                            {errors.campaignType && (
                              <p className="error">{errors.campaignType}</p>
                            )}
                          </div>
                        </div>
                        {usersResults.length > 0 && (
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label htmlFor="description">User List</label>
                              <div className="user-grid-outer">
                                <div className="row">
                                  {usersResults.map((user) => (
                                    <div
                                      className="col-3"
                                      key={user._id}
                                      onClick={() =>
                                        handleUserClick(user._id)
                                      }
                                    >
                                      <div
                                        className={`user-grid-item text-center mb-3 ${user.selected ? "active" : ""
                                          }`}
                                      >
                                        <figure className="mx-auto mb-2 user-img user-img-100 online">
                                          <img
                                            className="w-100"
                                            src="https://admin.mystylist.in/assets/img/dummy-user.png"
                                            alt=""
                                            title=""
                                          />
                                        </figure>
                                        <p className="fs-18 mb-0">
                                          <strong className="tc-6">
                                            {user.name}
                                          </strong>
                                        </p>
                                        <p className="fs-14 tc-7 mb-0">
                                          Customer Since:{" "}
                                          {formatDate(user.createdAt)}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-12">
                          <button type="submit" className="btn-blue">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStatetoProps)(CreateCampaign);

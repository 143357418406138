import React from "react";

const ViewTimeEntry = ({ isVisible, handleClose, entry }) => {
  if (!isVisible || !entry) return null;

  return (
    <div>
      <div className={`right-side-popup ${isVisible ? "" : "right-side-popup-hide"}`}>
        <div className="right-side-popup-close" onClick={handleClose}>
          <i className="fa-solid fa-angles-right"></i>
        </div>
        <div className="right-side-popup-inner">
          <div className="card-body">
            <div className="d-flex align-items-center mb-4">
              <div className="bs-r-6 ps-3">
                <h4 className="card-title mb-0">{entry.name}</h4>
                <p className="mb-0 tc-5">Time Entry Details</p>
              </div>
            </div>

            <div className="time-entry-details">
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Duration</span>
                <span className="tc-6 fw-medium ms-auto">
                  {entry.hours}h {entry.minutes}m
                </span>
              </p>
              
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Total Minutes</span>
                <span className="tc-6 fw-medium ms-auto">
                  {entry.totalMinutes}
                </span>
              </p>

              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Description</span>
                <span className="tc-6 fw-medium ms-auto">
                  {entry.description}
                </span>
              </p>

              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Created At</span>
                <span className="tc-6 fw-medium ms-auto">
                  {new Date(entry.createdAt).toLocaleDateString()}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`right-side-popup-backdrop ${isVisible ? "" : "d-none"}`}></div>
    </div>
  );
};

export default ViewTimeEntry; 
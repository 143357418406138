import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";
import ViewCMSPage from "./viewCMSPage";
import AddCMSPage from "./addCMSPage";
import { usePermissions } from "../../utils/hooks/PermisionsHook";

const CMSPageListing = () => {
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const retrievedObjectPermission = getLocalStorageItem("myStylistAdminPermission");
  const { hasPermission } = usePermissions();

  const [state, setState] = useState({
    id: retrievedObject ? retrievedObject.id : "",
    token: retrievedObject ? retrievedObject.token : "",
    results: [],
    emailErrorMsg: "",
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    activeTab: "All",
    search: "",
    sort: "",
    isVisibleView: false,
    isVisibleAdd: false,
    permissions: retrievedObjectPermission,
    loading: true,
    successMsg: "",
    errorMsg: "",
  });

  const getCMSResults = () => {
    setState(prev => ({ ...prev, loading: true }));
    const { search, sort, token, currentPage, itemsPerPage } = state;
    const { AdminCMSList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
      ...(search && { search: search.trim() }),
      ...(sort && { sort }),
    });

    const url = `${AdminCMSList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, Cmss, totalItems } = response.data;

        if (status === 200) {
          setState(prev => ({
            ...prev,
            results: Cmss,
            totalItems: totalItems,
            loading: false,
          }));
        } else {
          setState(prev => ({
            ...prev,
            emailErrorMsg: "Invalid Request",
            loading: false,
          }));

          setTimeout(() => {
            setState(prev => ({
              ...prev,
              emailErrorMsg: "",
            }));
          }, 10000);
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
        setState(prev => ({
          ...prev,
          loading: false,
        }));
      });
  };

  const handleToggleCheckBox = async (id, status, token) => {
    try {
      const response = await APICaller(
        `${adminendPoints.AdminUpdateCMSPageStatus}?id=${id}&status=${status}`,
        "GET",
        {},
        {},
        token
      );

      const { status: responseStatus } = response.data;

      if (responseStatus === 200) {
        setState(prev => ({
          ...prev,
          results: prev.results.map(item => 
            item._id === id ? { ...item, status: status } : item
          ),
          successMsg: "Status updated successfully",
          errorMsg: "",
        }));

        setTimeout(() => {
          setState(prev => ({
            ...prev,
            successMsg: "",
            errorMsg: "",
          }));
        }, 5000);
      } else {
        setState(prev => ({
          ...prev,
          errorMsg: "Failed to update status",
          successMsg: "",
        }));
      }
    } catch (error) {
      console.error("Error updating status:", error);
      setState(prev => ({
        ...prev,
        errorMsg: "Failed to update status",
        successMsg: "",
      }));
    }
  };

  const handlePageChange = (pageNumber) => {
    setState(prev => ({ ...prev, currentPage: pageNumber }), getCMSResults);
  };

  const handleSearchChange = (e) => {
    setState(prev => ({ ...prev, search: e.target.value }));
  };

  const handleCloseViewContent = () => {
    setState(prev => ({ ...prev, isVisibleView: false }));
  };

  const viewCategorytoggle = () => {
    setState(prev => ({ ...prev, isVisibleView: !prev.isVisibleView }));
  };

  const handleCloseAddContent = () => {
    setState(prev => ({ ...prev, isVisibleAdd: false }));
  };

  const addCategorytoggle = () => {
    setState(prev => ({ ...prev, isVisibleAdd: !prev.isVisibleAdd }));
  };

  const handleTabClick = (tab) => {
    if (state.activeTab !== tab) {
      setState(prev => ({ 
        ...prev,
        activeTab: tab,
        currentPage: 1 
      }));
    }
  };

  const getFilteredResults = () => {
    const { results, activeTab } = state;
    if (activeTab === 'All') return results;
    return results.filter(item => item.status === activeTab);
  };

  useEffect(() => {
    getCMSResults();
  }, []);

  if (state.token === "") {
    return <Navigate to="/" />;
  }

  const filteredResults = getFilteredResults();
  const totalItems = filteredResults.length;
  const totalPages = Math.ceil(totalItems / state.itemsPerPage);

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Manage CMS</li>
                  </ol>
                  <h4 className="card-title mb-0">Manage CMS</h4>
                </div>
                {hasPermission("cms", "add") && (
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-blue ms-2"
                      onClick={addCategorytoggle}
                    >
                      <i className="fa-solid fa-plus me-2"></i>Add New
                    </button>
                  </div>
                )}
              </div>
              <div className="tabs1 d-flex">
                <Link
                  onClick={() => handleTabClick("All")}
                  className={state.activeTab === "All" ? "active" : ""}
                >
                  All
                </Link>
                <Link
                  onClick={() => handleTabClick("Active")}
                  className={state.activeTab === "Active" ? "active" : ""}
                >
                  Active
                </Link>
                <Link
                  onClick={() => handleTabClick("In-Active")}
                  className={state.activeTab === "In-Active" ? "active" : ""}
                >
                  In-Active
                </Link>
              </div>
            </div>
          </div>
          {state.loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {state.successMsg && (
            <div className="alert alert-success" role="alert">
              {state.successMsg}
            </div>
          )}

          {state.errorMsg && (
            <div className="alert alert-danger" role="alert">
              {state.errorMsg}
            </div>
          )}
          <div className="card mb-4">
            <div className="card-body">
              <div className="row align-items-center mb-3">
                <div className="col-lg-5">
                  <div className="form-control-group-icon search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={state.search}
                      onChange={handleSearchChange}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="form-control-group-icon ms-3">
                      <select
                        className="form-select w-auto"
                        value={state.sort}
                        onChange={(e) =>
                          setState(prev => ({ ...prev, sort: e.target.value }))
                        }
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Page Title</th>
                    <th>Slug</th>
                    <th>Created Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredResults.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No users available for the selected categories.
                      </td>
                    </tr>
                  ) : (
                    filteredResults.map((item, index) => {
                      const serialNumber =
                        (state.currentPage - 1) * state.itemsPerPage + index + 1;
                      return (
                        <tr key={index}>
                          <td>{serialNumber}.</td>
                          <td>{item.title}</td>
                          <td>{item.slug}</td>
                          <td>{formatDate(item.createdAt)}</td>
                          
                            <td className="text-center">
                              {item.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    disabled={!hasPermission("cms", "status")}
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={item.status === "Active"}
                                    onChange={() =>
                                      handleToggleCheckBox(
                                        item._id,
                                        item.status === "Active" ? "In-Active" : "Active",
                                        state.token
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                        
                         
                            <td className="text-center">
                              <a href={hasPermission("cms", "edit") ? `/EditCMSPage/${item._id}` : "#"} className="icon-btn">
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt="Edit"
                                  title="Edit"
                                />
                              </a>
                            </td>
                          
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              
              <div className="d-flex align-items-center justify-content-end p-3">
                {state.currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(state.currentPage - 1, e)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}

                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        state.currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={(e) => handlePageChange(index + 1, e)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
                {state.currentPage < totalPages && (
                  <button
                    className="pagination-next"
                    onClick={(e) => handlePageChange(state.currentPage + 1, e)}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewCMSPage
        isVisibleView={state.isVisibleView}
        handleCloseViewContent={handleCloseViewContent}
      />
      <AddCMSPage
        isVisibleAdd={state.isVisibleAdd}
        handleCloseAddContent={handleCloseAddContent}
      />
    </div>
  );
};

export default CMSPageListing;

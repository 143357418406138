import React, { Component } from "react";
import Select from "react-select";
import APICaller from "../../utils/apicaller";
import { adminendPoints } from "../../config";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { formatDate } from "../../utils/commonFunction";

class AssignServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      token: getLocalStorageItem("myStylistAdmin")?.token || "",
      price: "",
      service_id: "",
      sub_service_id: "",
      best_service: "",
      fileName: "",
      selectedFile: null,
      previewURL: null,
      status: "In-Active",
      services: [],
      sub_services: [],
      successMsg: "",
      errorMsg: "",
      errors: "",
      redirect: false,
      best_service: "",
      errors: "",
      loading: false,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      activeTab: "All",
      search: "",
      sort: "",
      results: [],
      featured_image_url: process.env.REACT_APP_AWS_URL,
      time_taken: {
        hours: "0",
        minutes: "30",
      },
      selectedGender: "",
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.fetchService(userid);
    this.getUserviceAssignResults(userid);
  }

  handlePageChange(pageNumber, event) {
    event.preventDefault();
    console.log("Page changed to:", pageNumber);
    this.setState({ currentPage: pageNumber });
  }

  getUserviceAssignResults = (id) => {
    const { token } = this.state;

    const { AdminAssignServicesList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminAssignServicesList}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          this.setState({
            results: data.service,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  fetchService = async (id) => {
    const { token, selectedGender } = this.state;
    const { AdminServicesListMobile } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    
    const stateUrl = `${AdminServicesListMobile}?Gender=${selectedGender}`;

    const servicesResponse = await APICaller(
      stateUrl,
      method,
      "",
      contentType,
      token
    );

    const { services, status } = servicesResponse.data;

    if (status === 200) {
      this.setState({ services, id: id });
    }
  };

  fetchSubServices = async (serviceId) => {
    const { token } = this.state;
    const { AdminSubServicesListMobile } = adminendPoints;
    const method = "POST";

    const body = {
      serviceIds: serviceId,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminSubServicesListMobile,
        method,
        body,
        contentType,
        token
      );

      const { status, subServices } = response.data;
      if (status === 200) {
        this.setState({ sub_services: subServices });
      } else {
        this.setState(
          {
            errorMsg: "Failed to delete FAQ. Please try again.",
            successMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "" });
            }, 3000);
          }
        );
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while creating the category. Please try again.",
        successMsg: "",
      });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "price") {
      if (value === "" || parseFloat(value) >= 0) {
        this.setState({ [name]: value });
      }
    } else if (name === "hours" || name === "minutes") {
      if (value === "" || parseFloat(value) >= 0) {
        if (name === "minutes" && parseFloat(value) >= 60) {
          return;
        }
        this.setState(prevState => ({
          time_taken: {
            ...prevState.time_taken,
            [name]: value
          }
        }));
      }
    } else {
      this.setState({ [name]: value });

      if (name === "service_id") {
        this.fetchSubServices(value);
      }
    }
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    this.setState({ loading: true });

    if (Object.keys(newErrors).length > 0) {
      this.setState(
        { errorMsg: "", successMsg: "", errors: newErrors, loading: false },
        () => {
          window.setTimeout(() => {
            this.setState({ errors: {} });
          }, 5000);
        }
      );
    } else {
      this.setState({ errors: {} });
      const {
        id,
        selectedFile,
        token,
        service_id,
        sub_service_id,
        best_service,
        price,
        time_taken,
      } = this.state;
      const { AdminAssignServices } = adminendPoints;
      const formData = new FormData();

      formData.append("user_id", id);
      formData.append("fileName", selectedFile);
      formData.append("service_id", service_id);
      formData.append("sub_service_id", sub_service_id);
      formData.append("best_service", best_service);
      formData.append("price", price);
      formData.append("time_taken", JSON.stringify(time_taken)); // Include time_taken

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminAssignServices,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Service Assign successfully.",
              errorMsg: "",
            },
            () => {
              this.setState({
                service_id: "",
                sub_service_id: "",
                best_service: "",
                price: "",
                selectedFile: null,
                previewURL: null,
                fileName: "",
                description: "",
                loading: false,
              });
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
                if (this.props.onCategoryInsert) {
                  this.props.onCategoryInsert();
                }
              }, 5000);

              this.getUserviceAssignResults(this.state.id);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This service name is already assign",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  errorMsg: "",
                  redirect: false,
                  loading: false,
                });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create category. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  errorMsg: "",
                  redirect: false,
                  loading: false,
                });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  validateForm = () => {
    const {
      service_id,
      sub_service_id,
      best_service,
      fileName,
      price,
      time_taken,
    } = this.state;

    const validationErrors = {};

    if (!service_id) {
      validationErrors.service_id = "Service Name is required";
    }

    if (!sub_service_id) {
      validationErrors.sub_service_id = "Sub Service Name is required";
    }

    if (!best_service) {
      validationErrors.best_service = "Best Service is required";
    }

    // Price validation
    if (!price) {
      validationErrors.price = "Price is required";
    } else if (isNaN(price) || parseFloat(price) <= 0) {
      validationErrors.price = "Price must be a positive number";
    }

    // Time validation
    const hours = parseInt(time_taken.hours);
    const minutes = parseInt(time_taken.minutes);

    if (isNaN(hours) || isNaN(minutes)) {
      validationErrors.time_taken = "Hours and minutes must be valid numbers";
    } else if (hours < 0 || minutes < 0) {
      validationErrors.time_taken = "Time cannot be negative";
    } else if (hours === 0 && minutes === 0) {
      validationErrors.time_taken = "Total time cannot be zero";
    } else if (minutes >= 60) {
      validationErrors.time_taken = "Minutes should be less than 60";
    }

    return validationErrors;
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleToggleCheckBox = async (assign_service_id, currentStatus) => {
    const { token, id } = this.state;
    const { AdminUpdateStatusAssign } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      assign_service_id,
      status: newStatus,
    };

    try {
      const response = await APICaller(
        AdminUpdateStatusAssign,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((expert) =>
            expert._id === assign_service_id
              ? { ...expert, status: newStatus }
              : expert
          ),
          successMsg: `Expert Assign Status is ${newStatus}`,
          errorMsg: "",
        }));
        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
        this.getUserviceAssignResults(id);
      } else {
        console.error("Failed to update faq status");
        this.setState({
          errorMsg: message,
          successMsg: "",
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "",
      });
    }
  };

  openImageModal = (imageUrl) => {
    this.setState({
      isImageModalOpen: true,
      selectedImageUrl: imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      isImageModalOpen: false,
      selectedImageUrl: "",
    });
  };

  handleGenderChange = (e) => {
    const gender = e.target.value;
    this.setState({ 
      selectedGender: gender,
      service_id: "",
      services: []
    }, () => {
      if (gender) {
        this.fetchService(this.state.id);
      }
    });
  };

  render() {
    const {
      id,
      errors,
      service_id,
      sub_service_id,
      services,
      sub_services,
      successMsg,
      errorMsg,
      token,
      previewURL,
      status,
      best_service,
      price,
      loading,
      results,
      currentPage,
      itemsPerPage,
      activeTab,
      sort,
      totalItems,
      featured_image_url,
      search,
      selectedGender,
    } = this.state;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredAndSortedItems = Array.isArray(results)
      ? [...results].filter((user) => {
          const matchesSearch =
            user.service_name.toLowerCase().includes(search.toLowerCase()) ||
            (user.sub_service_name &&
              user.sub_service_name
                .toLowerCase()
                .includes(search.toLowerCase())) ||
            user.price.toString().includes(search.toLowerCase());

          return matchesSearch;
        })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);

    if (token === "") {
      return <Navigate to="/" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Assign Service </h4>
                  </div>
                  <div className="ms-auto">
                    <Link to={`/EditExpert/${id}`} className="btn-light1 ms-2">
                      <i class="fa-solid fa-arrow-left-long me-2"></i>
                      <span>Back</span>
                    </Link>
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="">
                    {errorMsg && (
                      <div className="alert alert-danger" role="alert">
                        {errorMsg}
                      </div>
                    )}
                    {successMsg && (
                      <div className="alert alert-success" role="alert">
                        {successMsg}
                      </div>
                    )}

                    {loading && (
                      <div className="d-flex justify-content-center align-items-center loader">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}

                    <div className="form-area1 row g-4">
                      <div className="col-lg-4">
                        <label htmlFor="gender" className="form-label">
                          Select Gender
                        </label>
                        <select
                          className="form-select"
                          id="gender"
                          name="gender"
                          aria-label="Select Gender"
                          value={selectedGender}
                          onChange={this.handleGenderChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="service_id" className="form-label">
                         Category Name  
                        </label>
                        <select
                          className="form-select"
                          id="service_id"
                          name="service_id"
                          aria-label="Select Service"
                          value={service_id}
                          onChange={this.handleInputChange}
                          disabled={!selectedGender}
                        >
                          <option value="">Select Service</option>
                          {services.map((service) => (
                            <option key={service._id} value={service._id}>
                              {service.service_name} - {service.service_for}
                            </option>
                          ))}
                        </select>
                        {errors.service_id && (
                          <p className="error">{errors.service_id}</p>
                        )}
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="sub_service_id" className="form-label">
                        Service Name
                        </label>
                        <select
                          className="form-select"
                          id="sub_service_id"
                          name="sub_service_id"
                          aria-label="Select Sub Service"
                          value={sub_service_id}
                          onChange={this.handleInputChange}
                        >
                          <option value="">Select Sub Service</option>
                          {sub_services.map((subService) => (
                            <option key={subService._id} value={subService._id}>
                              {subService.sub_service_name}
                            </option>
                          ))}
                        </select>
                        {errors.sub_service_id && (
                          <p className="error">{errors.sub_service_id}</p>
                        )}
                      </div>

                      <div className="col-lg-6">
                        <label htmlFor="price" className="form-label">
                          Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="price"
                          placeholder="Price"
                          name="price"
                          value={price}
                          min="0"
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                          onChange={this.handleInputChange}
                        />
                        {errors.price && (
                          <p className="error">{errors.price}</p>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="best_service" className="form-label">
                          Best Service
                        </label>
                        <select
                          className="form-select"
                          id="best_service"
                          name="best_service"
                          aria-label="Select Best Service"
                          value={best_service}
                          onChange={this.handleInputChange}
                        >
                          <option value="">Select Best Service</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        {errors.best_service && (
                          <p className="error">{errors.best_service}</p>
                        )}
                      </div>

                      <div className="col-lg-6">
                        <label htmlFor="hours" className="form-label">
                          Time Taken (Hours)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="hours"
                          name="hours"
                          placeholder="Hours"
                          min="0"
                          value={this.state.time_taken.hours}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="minutes" className="form-label">
                          Time Taken (Minutes)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="minutes"
                          name="minutes"
                          placeholder="Minutes"
                          min="0"
                          max="59"
                          value={this.state.time_taken.minutes}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                          onChange={this.handleInputChange}
                        />
                        {errors.time_taken && (
                          <p className="error">{errors.time_taken}</p>
                        )}
                      </div>

                      <div className="col-12">
                        <button type="submit" className="btn-blue">
                          Update Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sort}
                          onChange={(e) =>
                            this.setState({ sort: e.target.value })
                          }
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i class="fa-solid fa-filter"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <table className="table align-middle mb-4">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Service Name</th>
                      <th>Sub Service Name</th>
                      <th>Best Service</th>
                      <th>Price</th>
                      <th>Time Taken</th>
                      <th>Created At</th>
                      <th>Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No users available for the selected categories.
                        </td>
                      </tr>
                    ) : (
                      currentItems.map((item, index) => {
                        console.log(item, "item"); // Properly placed console log
                        return (
                          <tr key={index}>
                            <td>
                              {(currentPage - 1) * itemsPerPage + index + 1}.
                            </td>
                            <td>{item.service_name}</td>
                            <td>{item.sub_service_name}</td>
                            <td>{item.best_service}</td>
                            <td>{item.price}</td>
                            <td>
                              {item.time_taken
                                ? `${item.time_taken.hours || 0}h ${
                                    item.time_taken.minutes || 0
                                  }m`
                                : "N/A"}
                            </td>
                            <td>{formatDate(item.createdAt)}</td>
                            <td className="text-center">
                              {item.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={item.status === "Active"}
                                    onChange={() =>
                                      this.handleToggleCheckBox(
                                        item._id,
                                        item.status
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              {item.fileName && (
                                <Link
                                  to="#"
                                  className="icon-btn"
                                  onClick={() =>
                                    this.openImageModal(
                                      featured_image_url + "/" + item.fileName
                                    )
                                  }
                                >
                                  <img
                                    src="../assets/img/icon-gallery.svg"
                                    alt="View Image"
                                    title="View Service Image"
                                  />
                                </Link>
                              )}
                              <Link
                                to={`/EditAssignServices/${item._id}?userid=${id}`}
                                className="icon-btn"
                                key={item._id}
                              >
                                <img
                                  src="../assets/img/icon-edit.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                  {this.state.isImageModalOpen && (
                    <div
                      className="modal"
                      tabIndex="-1"
                      role="dialog"
                      style={{ display: "block" }}
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-body">
                            <span
                              className="close"
                              onClick={this.closeImageModal}
                            >
                              &times;
                            </span>
                            <img
                              src={this.state.selectedImageUrl}
                              alt="View Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </table>
                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}

                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={(e) => this.handlePageChange(index + 1, e)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>

                  {currentPage < totalPages && (
                    <button
                      className="pagination-next"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(AssignServices);
